import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";
import { ThirteenPageTypes } from "gita";

import _ from "lodash";

interface ThirteenPageComponentProps {
  thirteenPageData: ThirteenPageTypes;
  setThirteenPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  isBlock: boolean | number;
}

const ThirteenPageComponent = ({
  thirteenPageData,
  setThirteenPageData,
  setisUpdate,
  isBlock,
}: ThirteenPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "347mm" }}>
        <div
          className='hpN'
          style={{
            left: "98.24mm",
            top: "336.33mm",
            width: "13.52mm",
            height: "35mm",
          }}
        >
          <span className='hrt cs0'>- 13 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "1.25mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>Ⅵ. Impact (기여도) &nbsp;</span>
            </div>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "10.11mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>1. 본&nbsp;</span>
              <span className='hrt cs152'>
                융자사업의 각 항목에 대한 기여도
              </span>
              <span className='hrt cs1'>에 대해 의견을 표시하여 주십시오.</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "16.32mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>■&nbsp;</span>
              <span className='hrt cs183'>기술적 기여도</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "32.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "23.11mm",
                height: "32.1mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "148.59mm",
                  top: "0.5mm",
                  height: "32.1mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.59 37.10'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.59mm",
                    height: "37.1mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_120'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_120)'
                    d='M0,0L81.53,0L81.53,11.56L0,11.56L0,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M81.53,0L92.35,0L92.35,11.56L81.53,11.56L81.53,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M92.35,0L106.16,0L106.16,11.56L92.35,11.56L92.35,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M106.16,0L119.97,0L119.97,11.56L106.16,11.56L106.16,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M119.97,0L133.78,0L133.78,11.56L119.97,11.56L119.97,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M133.78,0L147.59,0L147.59,11.56L133.78,11.56L133.78,0Z '
                  />
                  <path
                    d='M0,0 L0,31.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M81.53,0 L81.53,31.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,31.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,11.56 L147.80,11.56'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,17.15 L147.80,17.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,24.13 L147.80,24.13'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,31.11 L147.80,31.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,31.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,31.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,31.11 L147.80,31.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "81.53mm",
                    height: "11.56mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.52mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs129'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "11.56mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.11mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.35mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "11.56mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.7mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "106.16mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "11.56mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.7mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.97mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "11.56mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.7mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "133.78mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "11.56mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.11mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "11.56mm",
                    width: "81.53mm",
                    height: "5.59mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.54mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          1) 융자사업이 기업의 기술개발에 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "11.56mm",
                    width: "66.06mm",
                    height: "5.59mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.71mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={(e) => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    one: "1",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.one === "1"}
                            name='page13_1_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={(e) => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    one: "2",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.one === "2"}
                            name='page13_1_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={(e) => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    one: "3",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.one === "3"}
                            name='page13_1_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={(e) => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    one: "4",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.one === "4"}
                            name='page13_1_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={(e) => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    one: "5",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.one === "5"}
                            name='page13_1_1'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "17.15mm",
                    width: "81.53mm",
                    height: "6.98mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.23mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>2</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 기업의 기술진 능력 개선
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "17.15mm",
                    width: "66.06mm",
                    height: "6.98mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.41mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    two: "1",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.two === "1"}
                            name='page13_1_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    two: "2",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.two === "2"}
                            name='page13_1_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    two: "3",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.two === "3"}
                            name='page13_1_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    two: "4",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.two === "4"}
                            name='page13_1_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    two: "5",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.two === "5"}
                            name='page13_1_2'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "24.13mm",
                    width: "81.53mm",
                    height: "6.98mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.23mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>3</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 국가 과학기술 발전에 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "24.13mm",
                    width: "66.06mm",
                    height: "6.98mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.41mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    three: "1",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.three === "1"}
                            name='page13_1_3'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    three: "2",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.three === "2"}
                            name='page13_1_3'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    three: "3",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.three === "3"}
                            name='page13_1_3'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    three: "4",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.three === "4"}
                            name='page13_1_3'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  one: {
                                    ...thirteenPageData.one.one,
                                    three: "5",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.one.three === "5"}
                            name='page13_1_3'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "57.75mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>■&nbsp;</span>
              <span className='hrt cs183'>경제적 기여도</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "42.37mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "64.54mm",
                height: "42.37mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "148.59mm",
                  top: "0.5mm",
                  height: "42.37mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.59 47.37'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.59mm",
                    height: "47.37mm",
                  }}
                >
                  <path
                    fill='url(#w_120)'
                    d='M0,0L81.53,0L81.53,13.20L0,13.20L0,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M81.53,0L92.35,0L92.35,13.20L81.53,13.20L81.53,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M92.35,0L106.16,0L106.16,13.20L92.35,13.20L92.35,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M106.16,0L119.97,0L119.97,13.20L106.16,13.20L106.16,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M119.97,0L133.78,0L133.78,13.20L119.97,13.20L119.97,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M133.78,0L147.59,0L147.59,13.20L133.78,13.20L133.78,0Z '
                  />
                  <path
                    d='M0,0 L0,41.38'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M81.53,0 L81.53,41.38'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,41.38'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,13.20 L147.80,13.20'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,20.25 L147.80,20.25'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,27.29 L147.80,27.29'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,34.34 L147.80,34.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,41.37 L147.80,41.37'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,41.38'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,41.38'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,41.37 L147.80,41.37'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "81.53mm",
                    height: "13.2mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "4.34mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs129'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "13.2mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.93mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.35mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "13.2mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "4.52mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "106.16mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "13.2mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "4.52mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.97mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "13.2mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "4.52mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "133.78mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "13.2mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.93mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "13.2mm",
                    width: "81.53mm",
                    height: "7.04mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.26mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span
                          style={{ fontSize: "0.8rem" }}
                          className='hrt cs158'
                        >
                          1
                        </span>
                        <span
                          style={{ fontSize: "0.8rem" }}
                          className='hrt cs158'
                        >
                          )&nbsp;
                        </span>
                        <span
                          style={{ fontSize: "0.8rem" }}
                          className='hrt cs30'
                        >
                          융자사업이 기업의 제품 및 서비스 경쟁력 강화에 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "13.2mm",
                    width: "66.06mm",
                    height: "7.04mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.44mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    one: "1",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.one === "1"}
                            name='page13_2_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    one: "2",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.one === "2"}
                            name='page13_2_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    one: "3",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.one === "3"}
                            name='page13_2_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    one: "4",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.one === "4"}
                            name='page13_2_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    one: "5",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.one === "5"}
                            name='page13_2_1'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "20.25mm",
                    width: "81.53mm",
                    height: "7.04mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.26mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>2</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 산업발전에 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "20.25mm",
                    width: "66.06mm",
                    height: "7.04mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.44mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    two: "1",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.two === "1"}
                            name='page13_2_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    two: "2",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.two === "2"}
                            name='page13_2_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    two: "3",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.two === "3"}
                            name='page13_2_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    two: "4",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.two === "4"}
                            name='page13_2_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    two: "5",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.two === "5"}
                            name='page13_2_2'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "27.29mm",
                    width: "81.53mm",
                    height: "7.04mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.26mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>3</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 새로운 수요를 창출하는데 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "27.29mm",
                    width: "66.06mm",
                    height: "7.04mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.44mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    three: "1",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.three === "1"}
                            name='page13_2_3'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    three: "2",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.three === "2"}
                            name='page13_2_3'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    three: "3",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.three === "3"}
                            name='page13_2_3'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    three: "4",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.three === "4"}
                            name='page13_2_3'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    three: "5",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.three === "5"}
                            name='page13_2_3'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "34.34mm",
                    width: "81.53mm",
                    height: "7.04mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.26mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>4</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 국가 경쟁력을 강화하는데 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "34.34mm",
                    width: "66.06mm",
                    height: "7.04mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.44mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    four: "1",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.four === "1"}
                            name='page13_2_4'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    four: "2",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.four === "2"}
                            name='page13_2_4'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    four: "3",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.four === "3"}
                            name='page13_2_4'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    four: "4",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.four === "4"}
                            name='page13_2_4'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  two: {
                                    ...thirteenPageData.one.two,
                                    four: "5",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.two.four === "5"}
                            name='page13_2_4'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "109.44mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>■&nbsp;</span>
              <span className='hrt cs183'>사회적 기여</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "28.88mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "116.24mm",
                height: "28.88mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "148.59mm",
                  top: "0.5mm",
                  height: "28.88mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.59 33.88'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.59mm",
                    height: "33.88mm",
                  }}
                >
                  <path
                    fill='url(#w_120)'
                    d='M0,0L81.53,0L81.53,11.89L0,11.89L0,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M81.53,0L92.35,0L92.35,11.89L81.53,11.89L81.53,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M92.35,0L106.16,0L106.16,11.89L92.35,11.89L92.35,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M106.16,0L119.97,0L119.97,11.89L106.16,11.89L106.16,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M119.97,0L133.78,0L133.78,11.89L119.97,11.89L119.97,0Z '
                  />
                  <path
                    fill='url(#w_120)'
                    d='M133.78,0L147.59,0L147.59,11.89L133.78,11.89L133.78,0Z '
                  />
                  <path
                    d='M0,0 L0,27.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M81.53,0 L81.53,27.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,27.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,11.89 L147.80,11.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,17.64 L147.80,17.64'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,27.88 L147.80,27.88'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.59,0 L147.59,27.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,27.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,27.88 L147.80,27.88'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.80,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "81.53mm",
                    height: "11.89mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.69mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs129'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "0mm",
                    width: "10.82mm",
                    height: "11.89mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.28mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "9.82mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.35mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "11.89mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.86mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>낮음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "106.16mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "11.89mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.86mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>보통</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.97mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "11.89mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.86mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "133.78mm",
                    top: "0mm",
                    width: "13.81mm",
                    height: "11.89mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.28mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>매우&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.02mm",
                          height: "3.17mm",
                          width: "12.81mm",
                        }}
                      >
                        <span className='hrt cs60'>높음</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "11.89mm",
                    width: "81.53mm",
                    height: "5.75mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.62mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>1</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 기업의 고용창출에 기여
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "11.89mm",
                    width: "66.06mm",
                    height: "5.75mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.79mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  three: {
                                    ...thirteenPageData.one.three,
                                    one: "1",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.three.one === "1"}
                            name='page13_3_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  three: {
                                    ...thirteenPageData.one.three,
                                    one: "2",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.three.one === "2"}
                            name='page13_3_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  three: {
                                    ...thirteenPageData.one.three,
                                    one: "3",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.three.one === "3"}
                            name='page13_3_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  three: {
                                    ...thirteenPageData.one.three,
                                    one: "4",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.three.one === "4"}
                            name='page13_3_1'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  three: {
                                    ...thirteenPageData.one.three,
                                    one: "5",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.three.one === "5"}
                            name='page13_3_1'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "17.64mm",
                    width: "81.53mm",
                    height: "10.24mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.1mm" }}>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>2</span>
                        <span className='hrt cs158'>
                          ) 융자사업이 공공의 이익에 부합
                        </span>
                      </div>
                      <div
                        className='hls ps101'
                        style={{
                          paddingLeft: "2.23mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "80.55mm",
                        }}
                      >
                        <span className='hrt cs158'>
                          &nbsp;&nbsp;&nbsp;(사회발전에 기여)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "81.53mm",
                    top: "17.64mm",
                    width: "66.06mm",
                    height: "10.24mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.04mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "65.07mm",
                        }}
                      >
                        <span className='hrt cs135'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  three: {
                                    ...thirteenPageData.one.three,
                                    two: "1",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.three.two === "1"}
                            name='page13_3_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  three: {
                                    ...thirteenPageData.one.three,
                                    two: "2",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.three.two === "2"}
                            name='page13_3_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  three: {
                                    ...thirteenPageData.one.three,
                                    two: "3",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.three.two === "3"}
                            name='page13_3_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  three: {
                                    ...thirteenPageData.one.three,
                                    two: "4",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.three.two === "4"}
                            name='page13_3_2'
                          />
                          ………
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                one: {
                                  ...thirteenPageData.one,
                                  three: {
                                    ...thirteenPageData.one.three,
                                    two: "5",
                                  },
                                },
                              });
                            }}
                            checked={thirteenPageData.one.three.two === "5"}
                            name='page13_3_2'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps98'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "147.65mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps114'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "151.53mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                2. 가장 최근 신청한 융자사업을 통해 개발되는&nbsp;
              </span>
              <span className='hrt cs9'>
                제품 및 서비스의 사업화 성숙도 단계
              </span>
              <span className='hrt cs1'>를</span>
            </div>
            <div
              className='hls ps114'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "156.58mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                판단하여 기재해 주십시오. (※사업화 성숙도 단계는 아래 참고표
                참조)&nbsp;
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "21.31mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "161.83mm",
                height: "21.31mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "162.06mm",
                  top: "0.5mm",
                  height: "21.31mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 167.06 26.31'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "167.06mm",
                    height: "26.31mm",
                  }}
                >
                  <path
                    d='M0,0 L0,20.32'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M36.23,0 L36.23,20.32'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M79.25,0 L79.25,20.32'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M121.25,0 L121.25,20.32'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M161.07,0 L161.07,20.32'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L161.27,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,10.26 L161.27,10.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,20.32 L161.27,20.32'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M161.07,0 L161.07,20.32'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,20.32'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,20.32 L161.27,20.32'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L161.27,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "36.23mm",
                    height: "10.26mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.87mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "32.62mm",
                        }}
                      >
                        <span className='hrt cs158'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "36.23mm",
                    top: "0mm",
                    width: "43.03mm",
                    height: "10.26mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.87mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "39.43mm",
                        }}
                      >
                        <span className='hrt cs158'>과제 신청연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.25mm",
                    top: "0mm",
                    width: "42mm",
                    height: "10.26mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.87mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "38.4mm",
                        }}
                      >
                        <span className='hrt cs158'>과제 종료연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "121.25mm",
                    top: "0mm",
                    width: "39.81mm",
                    height: "10.26mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.87mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "36.21mm",
                        }}
                      >
                        <span className='hrt cs158'>현재(2022년)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "10.26mm",
                    width: "36.23mm",
                    height: "10.06mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.59mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "32.62mm",
                        }}
                      >
                        <span className='hrt cs43'>사업화성숙도 단계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "36.23mm",
                    top: "10.26mm",
                    width: "43.03mm",
                    height: "10.06mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.59mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "39.43mm",
                        }}
                      >
                        <span className='hrt cs43'>
                          <select
                            onChange={(e) => {
                              setisUpdate(true);

                              setThirteenPageData({
                                ...thirteenPageData,
                                two: {
                                  ...thirteenPageData.two,
                                  one: {
                                    ...thirteenPageData.two.one,
                                    step: e.target.value,
                                  },
                                  two: {
                                    ...thirteenPageData.two.two,
                                    step: false,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof thirteenPageData.two.one.step === "boolean"
                                ? ""
                                : thirteenPageData.two.one.step
                            }
                          >
                            <option value={""}>선택</option>
                            <option value={"1"}>1</option>
                            <option value={"2"}>2</option>
                            <option value={"3"}>3</option>
                            <option value={"4"}>4</option>
                            <option value={"5"}>5</option>
                            <option value={"6"}>6</option>
                            <option value={"7"}>7</option>
                            <option value={"8"}>8</option>
                            <option value={"9"}>9</option>
                          </select>
                          단계
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "79.25mm",
                    top: "10.26mm",
                    width: "42mm",
                    height: "10.06mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.59mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "38.4mm",
                        }}
                      >
                        <span className='hrt cs43'>
                          <select
                            disabled={isBlock === 2022}
                            onChange={(e) => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                two: {
                                  ...thirteenPageData.two,
                                  two: {
                                    ...thirteenPageData.two.two,
                                    step: e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof thirteenPageData.two.two.step === "boolean"
                                ? ""
                                : thirteenPageData.two.two.step
                            }
                          >
                            <option value={""}>선택</option>
                            <option
                              disabled={
                                Number(thirteenPageData.two.one.step) > 1
                              }
                              value={"1"}
                            >
                              1
                            </option>
                            <option
                              disabled={
                                Number(thirteenPageData.two.one.step) > 2
                              }
                              value={"2"}
                            >
                              2
                            </option>
                            <option
                              disabled={
                                Number(thirteenPageData.two.one.step) > 3
                              }
                              value={"3"}
                            >
                              3
                            </option>
                            <option
                              disabled={
                                Number(thirteenPageData.two.one.step) > 4
                              }
                              value={"4"}
                            >
                              4
                            </option>
                            <option
                              disabled={
                                Number(thirteenPageData.two.one.step) > 5
                              }
                              value={"5"}
                            >
                              5
                            </option>
                            <option
                              disabled={
                                Number(thirteenPageData.two.one.step) > 6
                              }
                              value={"6"}
                            >
                              6
                            </option>
                            <option
                              disabled={
                                Number(thirteenPageData.two.one.step) > 7
                              }
                              value={"7"}
                            >
                              7
                            </option>
                            <option
                              disabled={
                                Number(thirteenPageData.two.one.step) > 8
                              }
                              value={"8"}
                            >
                              8
                            </option>
                            <option
                              disabled={
                                Number(thirteenPageData.two.one.step) > 9
                              }
                              value={"9"}
                            >
                              9
                            </option>
                          </select>
                          단계
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "121.25mm",
                    top: "10.26mm",
                    width: "39.81mm",
                    height: "10.06mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.59mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "36.21mm",
                        }}
                      >
                        <span className='hrt cs43'>
                          <select
                            onChange={(e) => {
                              setisUpdate(true);
                              setThirteenPageData({
                                ...thirteenPageData,
                                two: {
                                  ...thirteenPageData.two,
                                  three: {
                                    ...thirteenPageData.two.three,
                                    step: e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof thirteenPageData.two.three.step ===
                              "boolean"
                                ? ""
                                : thirteenPageData.two.three.step
                            }
                          >
                            <option value={""}>선택</option>
                            <option value={"1"}>1</option>
                            <option value={"2"}>2</option>
                            <option value={"3"}>3</option>
                            <option value={"4"}>4</option>
                            <option value={"5"}>5</option>
                            <option value={"6"}>6</option>
                            <option value={"7"}>7</option>
                            <option value={"8"}>8</option>
                            <option value={"9"}>9</option>
                          </select>
                          단계
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "185.67mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs176'>
                ※ 참고 : 사업화 성숙도(CRL : Commercial Readiness Level)
              </span>
            </div>
            <div
              className='hls ps109'
              style={{
                lineHeight: "69.15mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "192.47mm",
                height: "69.15mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "162.01mm",
                  top: "0.5mm",
                  height: "69.15mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 167.01 74.15'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "167.01mm",
                    height: "74.15mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_121'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_121)'
                    d='M0,0L18.87,0L18.87,4.52L0,4.52L0,0Z '
                  />
                  <path
                    fill='url(#w_121)'
                    d='M18.87,0L161.02,0L161.02,4.52L18.87,4.52L18.87,0Z '
                  />
                  <path
                    d='M0,0 L0,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M18.87,0 L18.87,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M161.02,0 L161.02,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L161.22,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,4.52 L161.22,4.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,9.05 L161.22,9.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,13.57 L161.22,13.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,22.68 L161.22,22.68'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,31.79 L161.22,31.79'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,40.89 L161.22,40.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,50 L161.22,50'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,59.11 L161.22,59.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,63.63 L161.22,63.63'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,68.16 L161.22,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M161.02,0 L161.02,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,68.16 L161.22,68.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L161.22,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "18.87mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "16.88mm",
                        }}
                      >
                        <span className='hrt cs163'>CRL 단계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.87mm",
                    top: "0mm",
                    width: "142.14mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs163'>판단 기준</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "4.52mm",
                    width: "18.87mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "16.88mm",
                        }}
                      >
                        <span className='hrt cs144'>1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.87mm",
                    top: "4.52mm",
                    width: "142.14mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          대상기술을 활용한 제품정보나 사례, 시장분석 자료가
                          알려진 바 없거나 일부만 존재
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "9.05mm",
                    width: "18.87mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "16.88mm",
                        }}
                      >
                        <span className='hrt cs144'>2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.87mm",
                    top: "9.05mm",
                    width: "142.14mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          새로운 기술을 기반으로 제품 아이디어는 존재하지만
                          검증되지 않은 상태
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "13.57mm",
                    width: "18.87mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "16.88mm",
                        }}
                      >
                        <span className='hrt cs144'>3</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.87mm",
                    top: "13.57mm",
                    width: "142.14mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          경쟁기술·제품 분석으로 신제품의 시장진입을 위한
                          고려사항이 문헌 및 인터뷰 조사를 통해 도
                        </span>
                      </div>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>출</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "22.68mm",
                    width: "18.87mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "16.88mm",
                        }}
                      >
                        <span className='hrt cs144'>4</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.87mm",
                    top: "22.68mm",
                    width: "142.14mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          제품에 대한 가설검증으로 Value-position, Value-chain,
                          잠재적파트너, 고객 및 관련인증·규제 확
                        </span>
                      </div>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>인</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "31.79mm",
                    width: "18.87mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "16.88mm",
                        }}
                      >
                        <span className='hrt cs144'>5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.87mm",
                    top: "31.79mm",
                    width: "142.14mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          목표시장 및 제품에 대한 정의 완료, 단기에서 중기까지의
                          판매 및 이윤창출 방안을 포함한 자금&nbsp;
                        </span>
                      </div>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>운영 계획 수립</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "40.89mm",
                    width: "18.87mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "16.88mm",
                        }}
                      >
                        <span className='hrt cs144'>6</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.87mm",
                    top: "40.89mm",
                    width: "142.14mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          제품화를 위한 필요사항이 문서화 되고, 설계 최적화 및
                          파트너십 체결, 관련 인증 및 규제 준수
                        </span>
                      </div>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>를 위한 절차 진행</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "50mm",
                    width: "18.87mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "16.88mm",
                        }}
                      >
                        <span className='hrt cs144'>7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.87mm",
                    top: "50mm",
                    width: "142.14mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          상품설계, 생산을 위한 인증 규제 대응방안 및 종합적
                          자금운영계획이 완료되어 제품 생산을 위
                        </span>
                      </div>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>한 집행이 승인</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "59.11mm",
                    width: "18.87mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "16.88mm",
                        }}
                      >
                        <span className='hrt cs144'>8</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.87mm",
                    top: "59.11mm",
                    width: "142.14mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          초기 제품들이 제조되고 판매되는 상태, 대규모 생산 및
                          판매를 위한 상용화 준비 진행
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "63.63mm",
                    width: "18.87mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "16.88mm",
                        }}
                      >
                        <span className='hrt cs144'>9</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "18.87mm",
                    top: "63.63mm",
                    width: "142.14mm",
                    height: "4.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps24'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "140.14mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          제품의 대량생산 및 보급이 완료
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirteenPageComponent;
