import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";

import { NinePageTypes } from "gita";
import _ from "lodash";

interface NinePageComponentProps {
  ninePageData: NinePageTypes;
  setNinePageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  targetYear: number;
}

const NinePageComponent = ({
  ninePageData,
  setNinePageData,
  setisUpdate,
  targetYear,
}: NinePageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "347mm" }}>
        <div
          className='hpN'
          style={{
            left: "99.27mm",
            top: "332mm",
            width: "11.46mm",
            height: "35mm",
          }}
        >
          <span className='hrt cs0'>- 9 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "1.25mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>
                Ⅴ-1. Outcome (결과/제품개발)&nbsp;
              </span>
            </div>
            <div
              className='hls ps7'
              style={{
                lineHeight: "8.93mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "10.3mm",
                height: "9.35mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.98mm",
                  top: "1mm",
                  height: "9.35mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.98 14.35'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.98mm",
                    height: "14.35mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_80'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(255,255,0)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_80)'
                    d='M0,0L148.98,0L148.98,7.36L0,7.36L0,0Z '
                  />
                  <path
                    d='M0,0 L0,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.98,0 L148.98,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L149.04,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,7.36 L149.04,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.98,0 L148.98,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,7.36 L149.04,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L149.04,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.98mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.5mm" }}>
                      <div
                        className='hls ps49'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "1.29mm",
                          height: "3.88mm",
                          width: "145.37mm",
                        }}
                      >
                        <span className='hrt cs65'>
                          A.「제품개발」에 해당하는 경우, 응답
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "21.79mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                1. 본 융자사업과제(제품개발)로 발생한 매출
              </span>
              <span className='hrt cs152'>(</span>
              <span className='hrt cs9'>사업화 매출액</span>
              <span className='hrt cs152'>)</span>
              <span className='hrt cs1'>에 대한 질문입니다.&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "28.39mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;귀사는 사업화매출액이 정확하게 파악되십니까?
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "35mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={(e) => {
                      setisUpdate(true);
                      setNinePageData({
                        ...ninePageData,
                        one: "1",
                      });
                    }}
                    checked={ninePageData.one === "1"}
                    name='one'
                  />
                  &nbsp; 사업화매출액이 정확하게 파악된다. (1-1문항으로 이동)
                </label>
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "41.6mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={(e) => {
                      setisUpdate(true);
                      setNinePageData({
                        ...ninePageData,
                        one: "2",
                      });
                    }}
                    checked={ninePageData.one === "2"}
                    name='one'
                  />
                  &nbsp; 사업화매출액이 모호하다. (1-2문항으로 이동)
                </label>
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "48.21mm",
                height: "3.7mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs8'>
                1-1. 「제품개발」에 해당하는 경우, 귀사의 총매출액과&nbsp;
              </span>
              <span className='hrt cs12'>본 융자사업과제로 발생한 매출액</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "54.5mm",
                height: "3.7mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs12'>(사업화 매출액)</span>
              <span className='hrt cs8'>
                에 대하여 응답하여 주십시오.&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "12.52mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "60.97mm",
                height: "12.52mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "12.52mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.01 17.52'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.01mm",
                    height: "17.52mm",
                  }}
                >
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "10.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※예: 2017년 선정기업은 2018년부터 기입, 2018년
                          선정기업은 2019년부터 기입
                        </span>
                      </div>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※2022년 선정기업은 2022년만 기입
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps36'
              style={{
                lineHeight: "59.15mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "76.21mm",
                height: "59.15mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "149.78mm",
                  top: "1mm",
                  height: "59.15mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.77 64.15'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.77mm",
                    height: "64.15mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_81'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_81)'
                    d='M0,0L23.65,0L23.65,17.58L0,17.58L0,0Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M23.65,0L82.77,0L82.77,10.23L23.65,10.23L23.65,0Z '
                  />
                  <defs>
                    <pattern
                      id='w_82'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_82)'
                    d='M82.77,0L147.78,0L147.78,10.23L82.77,10.23L82.77,0Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M23.65,10.23L55.18,10.23L55.18,17.58L23.65,17.58L23.65,10.23Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M55.18,10.23L82.77,10.23L82.77,17.58L55.18,17.58L55.18,10.23Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M82.77,10.23L119.22,10.23L119.22,17.58L82.77,17.58L82.77,10.23Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M119.22,10.23L147.78,10.23L147.78,17.58L119.22,17.58L119.22,10.23Z '
                  />
                  <path
                    d='M0,0 L0,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M23.65,0 L23.65,26.85'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,17.58 L23.65,26.85'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,26.84 L23.65,35.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,26.84 L23.65,35.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,35.15 L23.65,42.51'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,35.15 L23.65,42.51'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,42.51 L23.65,49.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,42.51 L23.65,49.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,49.87 L23.65,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,49.87 L23.65,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.18,10.23 L55.18,17.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.18,17.58 L55.18,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M82.77,0 L82.77,17.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M82.77,17.58 L82.77,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M119.22,10.23 L119.22,17.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M119.22,17.58 L119.22,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M147.78,0 L147.78,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M23.59,10.23 L148.03,10.23'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.25,17.58 L55.24,17.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,17.58 L55.26,17.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.12,17.58 L82.84,17.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.10,17.58 L148.03,17.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,26.84 L23.71,26.84'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,26.84 L148.03,26.84'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,35.15 L23.71,35.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,35.15 L148.03,35.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,42.51 L23.71,42.51'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,42.51 L148.03,42.51'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,49.87 L23.71,49.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,49.87 L148.03,49.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,57.15 L148.03,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M147.78,0 L147.78,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M0,0 L0,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,57.15 L148.03,57.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "23.65mm",
                    height: "17.58mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "6.53mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs129'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "0mm",
                    width: "59.13mm",
                    height: "10.23mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.85mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "58.12mm",
                        }}
                      >
                        <span className='hrt cs129'>기업 총 매출액</span>
                        <span className='hrt cs130'>1)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "0mm",
                    width: "65.01mm",
                    height: "10.23mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.09mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "64.01mm",
                        }}
                      >
                        <span className='hrt cs132'>
                          융자사업과제로 인한 매출액
                        </span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "64.01mm",
                        }}
                      >
                        <span className='hrt cs132'>(사</span>
                        <span className='hrt cs129'>업화 매출액</span>
                        <span className='hrt cs130'>2)</span>
                        <span className='hrt cs132'>)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "10.23mm",
                    width: "31.53mm",
                    height: "7.34mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.41mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <span className='hrt cs129'>국내(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "10.23mm",
                    width: "27.59mm",
                    height: "7.34mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.41mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <span className='hrt cs129'>수출(백만원)</span>
                        <span className='hrt cs130'>3)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "10.23mm",
                    width: "36.45mm",
                    height: "7.34mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.41mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <span className='hrt cs129'>국내(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "10.23mm",
                    width: "28.55mm",
                    height: "7.34mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.41mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <span className='hrt cs129'>수출(백만원)</span>
                        <span className='hrt cs130'>3)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "17.58mm",
                    width: "23.65mm",
                    height: "9.27mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "17.58mm",
                    width: "31.53mm",
                    height: "9.27mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2018
                          }
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          type='number'
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                one: {
                                  ...ninePageData.two.one,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.one.one === "string"
                              ? ninePageData.two.one.one
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "17.58mm",
                    width: "27.59mm",
                    height: "9.27mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                one: {
                                  ...ninePageData.two.one,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.one.two === "string"
                              ? ninePageData.two.one.two
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "17.58mm",
                    width: "36.45mm",
                    height: "9.27mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                one: {
                                  ...ninePageData.two.one,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.one.three === "string"
                              ? ninePageData.two.one.three
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "17.58mm",
                    width: "28.55mm",
                    height: "9.27mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                one: {
                                  ...ninePageData.two.one,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.one.four === "string"
                              ? ninePageData.two.one.four
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "26.84mm",
                    width: "23.65mm",
                    height: "8.3mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "26.84mm",
                    width: "31.53mm",
                    height: "8.3mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                two: {
                                  ...ninePageData.two.two,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.two.one === "string"
                              ? ninePageData.two.two.one
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "26.84mm",
                    width: "27.59mm",
                    height: "8.3mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                two: {
                                  ...ninePageData.two.two,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.two.two === "string"
                              ? ninePageData.two.two.two
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "26.84mm",
                    width: "36.45mm",
                    height: "8.3mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                two: {
                                  ...ninePageData.two.two,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.two.three === "string"
                              ? ninePageData.two.two.three
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "26.84mm",
                    width: "28.55mm",
                    height: "8.3mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                two: {
                                  ...ninePageData.two.two,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.two.four === "string"
                              ? ninePageData.two.two.four
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "35.15mm",
                    width: "23.65mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "35.15mm",
                    width: "31.53mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                three: {
                                  ...ninePageData.two.three,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.three.one === "string"
                              ? ninePageData.two.three.one
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "35.15mm",
                    width: "27.59mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                three: {
                                  ...ninePageData.two.three,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.three.two === "string"
                              ? ninePageData.two.three.two
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "35.15mm",
                    width: "36.45mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                three: {
                                  ...ninePageData.two.three,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.three.three === "string"
                              ? ninePageData.two.three.three
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "35.15mm",
                    width: "28.55mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                three: {
                                  ...ninePageData.two.three,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.three.four === "string"
                              ? ninePageData.two.three.four
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "42.51mm",
                    width: "23.65mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "42.51mm",
                    width: "31.53mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2021
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                four: {
                                  ...ninePageData.two.four,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.four.one === "string"
                              ? ninePageData.two.four.one
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "42.51mm",
                    width: "27.59mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2021
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                four: {
                                  ...ninePageData.two.four,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.four.two === "string"
                              ? ninePageData.two.four.two
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "42.51mm",
                    width: "36.45mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2021
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                four: {
                                  ...ninePageData.two.four,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.four.three === "string"
                              ? ninePageData.two.four.three
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "42.51mm",
                    width: "28.55mm",
                    height: "7.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "1" || targetYear >= 2021
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                four: {
                                  ...ninePageData.two.four,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.four.four === "string"
                              ? ninePageData.two.four.four
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "49.87mm",
                    width: "23.65mm",
                    height: "7.28mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "49.87mm",
                    width: "31.53mm",
                    height: "7.28mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={ninePageData.one !== "1"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                five: {
                                  ...ninePageData.two.five,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.five.one === "string"
                              ? ninePageData.two.five.one
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "49.87mm",
                    width: "27.59mm",
                    height: "7.28mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={ninePageData.one !== "1"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                five: {
                                  ...ninePageData.two.five,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.five.two === "string"
                              ? ninePageData.two.five.two
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "49.87mm",
                    width: "36.45mm",
                    height: "7.28mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <Input
                          disabled={ninePageData.one !== "1"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                five: {
                                  ...ninePageData.two.five,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.five.three === "string"
                              ? ninePageData.two.five.three
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "49.87mm",
                    width: "28.55mm",
                    height: "7.28mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <Input
                          disabled={ninePageData.one !== "1"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              two: {
                                ...ninePageData.two,
                                five: {
                                  ...ninePageData.two.five,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.two.five.four === "string"
                              ? ninePageData.two.five.four
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "137.93mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                1) 기업 총 매출액: 당해년도 발생한 총 매출액을 의미.
                융자사업과제 매출을 포함한 총 매출액을 기입.
              </span>
            </div>
            <div
              className='hls ps89'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "141.83mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                2) 사업화 매출액: 기술과제를 통해 개발한 새로운 제품의 판매로
                인해 유발된 매출액 또는 기술과제를 통해 개발한 기술을&nbsp;
              </span>
            </div>
            <div
              className='hls ps89'
              style={{
                paddingLeft: "3.54mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "145.73mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                활용하여 기존에 판매하던 제품의 기능 강화 또는 가격 인하로 인해
                발생한 매출증가액(객관적인 계산이 어려운 경우에는&nbsp;
              </span>
            </div>
            <div
              className='hls ps89'
              style={{
                paddingLeft: "3.54mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "149.63mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>가급적 정확하게 추정하여 기입)</span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "153.54mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                3) 외화표시(US$, ￥ 등)로 되어 있을 경우, 발생시점 기준의 환율을
                추정하여 원화로 환산한 값을 기재 요망.&nbsp;
              </span>
            </div>
            <div
              className='hls ps67'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "157.4mm",
                height: "3.7mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "163.69mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>1-2. 위 1-1문항에서&nbsp;</span>
              <span className='hrt cs12'>
                사업화 매출액을 정확하게 파악할 수 없는 경우에 기입
              </span>
              <span className='hrt cs8'>해 주시기 바</span>
            </div>
            <div
              className='hls ps5'
              style={{
                paddingLeft: "7.06mm",
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "169.98mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>랍니다.</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "12.52mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "176.45mm",
                height: "12.52mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "12.52mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.01 17.52'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.01mm",
                    height: "17.52mm",
                  }}
                >
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "10.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※예: 2017년 선정기업은 2018년부터 기입, 2018년
                          선정기업은 2019년부터 기입
                        </span>
                      </div>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※2022년 선정기업은 2022년만 기입
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps111'
              style={{
                lineHeight: "53.04mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "191.69mm",
                height: "53.04mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.77mm",
                  top: "1mm",
                  height: "53.04mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.77 58.04'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.77mm",
                    height: "58.04mm",
                  }}
                >
                  <path
                    fill='url(#w_81)'
                    d='M0,0L24.65,0L24.65,14.43L0,14.43L0,0Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M24.65,0L83.77,0L83.77,7.24L24.65,7.24L24.65,0Z '
                  />
                  <path
                    fill='url(#w_82)'
                    d='M83.77,0L148.78,0L148.78,7.24L83.77,7.24L83.77,0Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M24.65,7.24L56.18,7.24L56.18,14.43L24.65,14.43L24.65,7.24Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M56.18,7.24L83.77,7.24L83.77,14.43L56.18,14.43L56.18,7.24Z '
                  />
                  <path
                    fill='url(#w_82)'
                    d='M83.77,7.24L116.27,7.24L116.27,14.43L83.77,14.43L83.77,7.24Z '
                  />
                  <path
                    fill='url(#w_82)'
                    d='M116.27,7.24L148.78,7.24L148.78,14.43L116.27,14.43L116.27,7.24Z '
                  />
                  <path
                    d='M0,0 L0,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M24.65,0 L24.65,22.59'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,14.43 L24.65,22.59'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,22.59 L24.65,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,22.59 L24.65,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,29.71 L24.65,36.83'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,29.71 L24.65,36.83'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,36.83 L24.65,43.95'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,36.83 L24.65,43.95'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,43.95 L24.65,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,43.95 L24.65,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M56.18,7.24 L56.18,14.44'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M56.18,14.43 L56.18,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M83.77,0 L83.77,14.44'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M83.77,14.43 L83.77,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M116.27,7.24 L116.27,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.1",
                    }}
                  />
                  <path
                    d='M148.78,0 L148.78,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L149.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M24.59,7.24 L149.03,7.24'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.25,14.43 L56.24,14.43'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,14.43 L56.25,14.43'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M56.12,14.43 L83.83,14.43'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M56.10,14.43 L149.03,14.43'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,22.59 L24.71,22.59'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,22.59 L149.03,22.59'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,29.71 L24.71,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,29.71 L149.03,29.71'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,36.83 L24.71,36.83'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,36.83 L149.03,36.83'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,43.95 L24.71,43.95'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,43.95 L149.03,43.95'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,51.04 L149.03,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M148.78,0 L148.78,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M0,0 L0,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,51.04 L149.03,51.04'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L149.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "24.65mm",
                    height: "14.43mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.95mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs129'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "0mm",
                    width: "59.13mm",
                    height: "7.24mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.36mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "58.12mm",
                        }}
                      >
                        <span className='hrt cs129'>기업 총 매출액</span>
                        <span className='hrt cs130'>1)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "0mm",
                    width: "65.01mm",
                    height: "7.24mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.36mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "64.01mm",
                        }}
                      >
                        <span className='hrt cs129'>
                          융자사업과제의 매출액 기여율
                        </span>
                        <span className='hrt cs130'>3)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "7.24mm",
                    width: "31.53mm",
                    height: "7.19mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.33mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <span className='hrt cs129'>국내(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "7.24mm",
                    width: "27.59mm",
                    height: "7.19mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.33mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <span className='hrt cs129'>수출(백만원)</span>
                        <span className='hrt cs130'>2)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "7.24mm",
                    width: "32.5mm",
                    height: "7.19mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.33mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <span className='hrt cs129'>국내 매출액 기여율</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "7.24mm",
                    width: "32.5mm",
                    height: "7.19mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.33mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <span className='hrt cs129'>수출액 기여율</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "14.43mm",
                    width: "24.65mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.84mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "14.43mm",
                    width: "31.53mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                one: {
                                  ...ninePageData.three.one,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.one.one === "string"
                              ? ninePageData.three.one.one
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "14.43mm",
                    width: "27.59mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                one: {
                                  ...ninePageData.three.one,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.one.two === "string"
                              ? ninePageData.three.one.two
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "14.43mm",
                    width: "32.5mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                one: {
                                  ...ninePageData.three.one,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.one.three === "string"
                              ? ninePageData.three.one.three
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "14.43mm",
                    width: "32.5mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                one: {
                                  ...ninePageData.three.one,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.one.four === "string"
                              ? ninePageData.three.one.four
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "22.59mm",
                    width: "24.65mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "22.59mm",
                    width: "31.53mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                two: {
                                  ...ninePageData.three.two,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.two.one === "string"
                              ? ninePageData.three.two.one
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "22.59mm",
                    width: "27.59mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                two: {
                                  ...ninePageData.three.two,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.two.two === "string"
                              ? ninePageData.three.two.two
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "22.59mm",
                    width: "32.5mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                two: {
                                  ...ninePageData.three.two,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.two.three === "string"
                              ? ninePageData.three.two.three
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "22.59mm",
                    width: "32.5mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                two: {
                                  ...ninePageData.three.two,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.two.four === "string"
                              ? ninePageData.three.two.four
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "29.71mm",
                    width: "24.65mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.3mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "29.71mm",
                    width: "31.53mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                three: {
                                  ...ninePageData.three.three,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.three.one === "string"
                              ? ninePageData.three.three.one
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "29.71mm",
                    width: "27.59mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                three: {
                                  ...ninePageData.three.three,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.three.two === "string"
                              ? ninePageData.three.three.two
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "29.71mm",
                    width: "32.5mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                three: {
                                  ...ninePageData.three.three,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.three.three === "string"
                              ? ninePageData.three.three.three
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "29.71mm",
                    width: "32.5mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                three: {
                                  ...ninePageData.three.three,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.three.four === "string"
                              ? ninePageData.three.three.four
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "36.83mm",
                    width: "24.65mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.7mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "36.83mm",
                    width: "31.53mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                four: {
                                  ...ninePageData.three.four,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.four.one === "string"
                              ? ninePageData.three.four.one
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "36.83mm",
                    width: "27.59mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                four: {
                                  ...ninePageData.three.four,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.four.two === "string"
                              ? ninePageData.three.four.two
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "36.83mm",
                    width: "32.5mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                four: {
                                  ...ninePageData.three.four,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.four.three === "string"
                              ? ninePageData.three.four.three
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "36.83mm",
                    width: "32.5mm",
                    height: "7.12mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <Input
                          disabled={
                            ninePageData.one !== "2" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                four: {
                                  ...ninePageData.three.four,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.four.four === "string"
                              ? ninePageData.three.four.four
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "43.95mm",
                    width: "24.65mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "43.95mm",
                    width: "31.53mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.7mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={ninePageData.one !== "2"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                five: {
                                  ...ninePageData.three.five,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.five.one === "string"
                              ? ninePageData.three.five.one
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "43.95mm",
                    width: "27.59mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.7mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={ninePageData.one !== "2"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                five: {
                                  ...ninePageData.three.five,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.five.two === "string"
                              ? ninePageData.three.five.two
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "43.95mm",
                    width: "32.5mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.7mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <Input
                          disabled={ninePageData.one !== "2"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                five: {
                                  ...ninePageData.three.five,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.five.three === "string"
                              ? ninePageData.three.five.three
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "43.95mm",
                    width: "32.5mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.7mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <Input
                          disabled={ninePageData.one !== "2"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setNinePageData({
                              ...ninePageData,
                              three: {
                                ...ninePageData.three,
                                five: {
                                  ...ninePageData.three.five,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof ninePageData.three.five.four === "string"
                              ? ninePageData.three.five.four
                              : ""
                          }
                          style={{
                            width: "70%",
                            height: "80%",
                            textAlign: "right",
                          }}
                          className='money-form-input'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "247.16mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                1) 기업 총 매출액: 당해년도 발생한 총 매출액을 의미.
                융자사업과제 매출을 포함한 총 매출액을 기입.
              </span>
            </div>
            <div
              className='hls ps88'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "251.06mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs13'>
                2) 융자사업과제의 매출액 기여율: 매출액에 대해 융자사업과제가
                기여한 정도를 의미(0~100%).
              </span>
            </div>
            <div
              className='hls ps88'
              style={{
                lineHeight: "2.48mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "254.95mm",
                height: "3.17mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs179'>※&nbsp;</span>
              <span className='hrt cs180'>
                융자사업과제를 통해 개발된 기술, 구축된 시스템, 기술장비 등이
                제품 생산(제공)에&nbsp;
              </span>
              <span className='hrt cs181'>필수요소</span>
              <span className='hrt cs180'>인 경우, 100%로 기입.</span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "259.09mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                3) 외화표시(US$, ￥ 등)로 되어 있을 경우, 발생시점 기준의 환율을
                추정하여 원화로 환산한 값을 기재 요망.&nbsp;
              </span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "262.96mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            />
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "262.95mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NinePageComponent;
