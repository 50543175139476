import React from "react";
import { SeventeenPageDataTypes } from "gita";
import Input from "antd/es/input/Input";

interface SeventeenPageComponentProps {
  seventeenPageData: SeventeenPageDataTypes;
  setSeventeenPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<any>>;
  isBlock: boolean | number;
}

const SeventeenPageComponent = ({
  seventeenPageData,
  setSeventeenPageData,
  setisUpdate,
  isBlock,
}: SeventeenPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          className='hpN'
          style={{
            left: "98.24mm",
            top: "282mm",
            width: "13.52mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 17 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps124'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs204'>
                &nbsp;&nbsp;6-1. 위 6번 질문에서 “매우 불충분” 또는 “불충분”으로
                답하신 경우, 융자 한도는&nbsp;
              </span>
            </div>
            <div
              className='hls ps124'
              style={{
                paddingLeft: "12.34mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "6.41mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs204'>
                어느 정도 규모가 되어야 한다고 생각하십니까?
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "13.01mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs204'>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='six_one'
                    disabled={isBlock > 2 ? true : false}
                    checked={seventeenPageData.sixOne === "1"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        sixOne: "1",
                      });
                    }}
                    value='1'
                  />{" "}
                  1억원 미만
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='six_one'
                    disabled={isBlock > 2 ? true : false}
                    checked={seventeenPageData.sixOne === "2"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        sixOne: "2",
                      });
                    }}
                    value='2'
                  />{" "}
                  1억원~2억원 미만
                </label>
                &nbsp;
                <label>
                  <input
                    type='radio'
                    name='six_one'
                    disabled={isBlock > 2 ? true : false}
                    checked={seventeenPageData.sixOne === "3"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        sixOne: "3",
                      });
                    }}
                    value='3'
                  />{" "}
                  2억원~3억원 미만
                </label>
                &nbsp;&nbsp;
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "19.62mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs204'>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='six_one'
                    disabled={isBlock > 2 ? true : false}
                    checked={seventeenPageData.sixOne === "4"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        sixOne: "4",
                      });
                    }}
                    value='4'
                  />{" "}
                  3억원~4억원 미만
                </label>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='six_one'
                    disabled={isBlock > 2 ? true : false}
                    checked={seventeenPageData.sixOne === "5"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        sixOne: "5",
                      });
                    }}
                    value='5'
                  />{" "}
                  4억원~5억원 미만
                </label>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='six_one'
                    disabled={isBlock > 2 ? true : false}
                    checked={seventeenPageData.sixOne === "6"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        sixOne: "6",
                      });
                    }}
                    value='6'
                  />
                  &nbsp; 5억원~10억원 미만
                </label>
                <label>
                  {" "}
                  &nbsp;
                  <input
                    type='radio'
                    name='six_one'
                    disabled={isBlock > 2 ? true : false}
                    checked={seventeenPageData.sixOne === "7"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        sixOne: "7",
                      });
                    }}
                    value='7'
                  />
                  &nbsp;10억원 이상
                </label>
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "26.22mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "32.83mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                7. &nbsp;귀사가 본 정보통신응용기술개발지원(융자)사업의 지원을
                받지 못했을 경우,&nbsp;
              </span>
              <span className='hrt cs152'>외부&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "39.43mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs152'>
                금융기관으로부터 융자자금 조달 시 가장 큰 문제점
              </span>
              <span className='hrt cs1'>
                은 무엇이었다고 생각하십니까?&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "46.03mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>(중복응답 가능)</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "52.64mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='checkbox'
                    checked={seventeenPageData.seven[0].number}
                    onChange={(e) => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        seven: seventeenPageData.seven.map((item, index) => {
                          if (index === 0) {
                            return {
                              ...item,
                              number: e.target.checked,
                            };
                          }
                          return item;
                        }),
                      });
                    }}
                  />{" "}
                  일반담보 부족
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='checkbox'
                    checked={seventeenPageData.seven[1].number}
                    onChange={(e) => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        seven: seventeenPageData.seven.map((item, index) => {
                          if (index === 1) {
                            return {
                              ...item,
                              number: e.target.checked,
                            };
                          }
                          return item;
                        }),
                      });
                    }}
                  />{" "}
                  금융기관의 까다로운 대출심사
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <label>
                  <input
                    type='checkbox'
                    checked={seventeenPageData.seven[2].number}
                    onChange={(e) => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        seven: seventeenPageData.seven.map((item, index) => {
                          if (index === 2) {
                            return {
                              ...item,
                              number: e.target.checked,
                            };
                          }
                          return item;
                        }),
                      });
                    }}
                  />{" "}
                  높은 금리
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "59.24mm",
                height: "5mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;{" "}
                <label>
                  <input
                    type='checkbox'
                    checked={seventeenPageData.seven[3].number}
                    onChange={(e) => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        seven: seventeenPageData.seven.map((item, index) => {
                          if (index === 3) {
                            return {
                              ...item,
                              number: e.target.checked,
                            };
                          }
                          return item;
                        }),
                      });
                    }}
                  />{" "}
                  적은 대출 금액
                </label>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <label>
                  <input
                    type='checkbox'
                    checked={seventeenPageData.seven[4].number}
                    onChange={(e) => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        seven: seventeenPageData.seven.map((item, index) => {
                          if (index === 4) {
                            return {
                              ...item,
                              number: e.target.checked,
                            };
                          }
                          return item;
                        }),
                      });
                    }}
                  />{" "}
                  기타
                </label>
                <Input
                  type='text'
                  disabled={!seventeenPageData.seven[4].number}
                  onChange={(e) => {
                    setisUpdate(true);
                    setSeventeenPageData({
                      ...seventeenPageData,
                      seven: seventeenPageData.seven.map((item, index) => {
                        if (index === 4) {
                          return {
                            ...item,
                            text: e.target.value,
                          };
                        }
                        return item;
                      }),
                    });
                  }}
                  value={seventeenPageData.seven[4].text}
                  style={{ marginLeft: "8px", width: "200px" }}
                />
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "65.85mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "72.45mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                8. 만약 당시 귀사가 본 정보통신응용기술개발지원(융자)사업의
                융자지원을&nbsp;
              </span>
              <span className='hrt cs152'>지원 받지&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "79.05mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs152'>
                못하였다면, 다음 중 어떤 상황에 직면
              </span>
              <span className='hrt cs1'>했을 것으로 생각하십니까?</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "85.66mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;{" "}
                <label>
                  <input
                    type='radio'
                    name='eight'
                    checked={seventeenPageData.eight === "1"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        eight: "1",
                      });
                    }}
                    value='1'
                  />{" "}
                  사실상 자력으로 기술개발에 필요한 자금조달이 어려웠을 것
                </label>
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "92.26mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;{" "}
                <label>
                  <input
                    type='radio'
                    name='eight'
                    checked={seventeenPageData.eight === "2"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        eight: "2",
                      });
                    }}
                    value='1'
                  />{" "}
                  일부 자체 조달이 가능했겠지만, 금액은 융자자금에 크게 미달했을
                  것
                </label>
              </span>
            </div>
            <div
              className='hls ps33'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "98.87mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;{" "}
                <label>
                  <input
                    type='radio'
                    name='eight'
                    checked={seventeenPageData.eight === "3"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        eight: "3",
                      });
                    }}
                    value='1'
                  />{" "}
                  융자자금과 동등한 금액을 자체 조달할 수는 있었지만 대출금리,
                  적기대출,
                  <div
                    className='hls ps33'
                    style={{
                      paddingLeft: "8mm",
                      lineHeight: "3.1mm",
                      whiteSpace: "nowrap",
                      left: "0mm",
                      // top: "105.47mm",
                      height: "3.88mm",
                      width: "150mm",
                      marginTop: "8px",
                    }}
                  >
                    <label
                      onClick={() => {
                        setisUpdate(true);
                        setSeventeenPageData({
                          ...seventeenPageData,
                          eight: "3",
                        });
                      }}
                      htmlFor='eight'
                      className='hrt cs1'
                    >
                      대출기간 등의 조건에서 크게 불리했을 것
                    </label>
                  </div>
                </label>
                &nbsp;
              </span>
            </div>

            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "112.07mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    name='eight'
                    checked={seventeenPageData.eight === "4"}
                    onChange={() => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        eight: "4",
                      });
                    }}
                    value='1'
                  />{" "}
                  융자자금을 못 받았어도 별다른 영향이 없었을 것
                </label>
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "118.68mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "125.28mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                9. 정보통신응용기술개발지원(융자)사업의 융자지원이 일반
                금융기관의 중소기업대출&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "131.89mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>상품과 비교할 때&nbsp;</span>
              <span className='hrt cs152'>
                어떤 측면에서 차별성이 있어야 하는지
              </span>
              <span className='hrt cs1'>
                &nbsp;우선순위로 2순위까지만&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "138.49mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                응답해 주십시오.
                <span className='hrt cs119'>
                  &nbsp;&nbsp;&nbsp;&nbsp;1위:{" "}
                  <select
                    onChange={(e) => {
                      console.log(e.target.value);
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        nine: {
                          ...seventeenPageData.nine,
                          one: {
                            ...seventeenPageData.nine.one,
                            number: e.target.value,
                          },
                        },
                      });
                    }}
                    value={
                      typeof seventeenPageData.nine.one.number === "boolean"
                        ? ""
                        : seventeenPageData.nine.one.number
                    }
                  >
                    <option value={""}>== 선택 ==</option>
                    <option
                      disabled={seventeenPageData.nine.two.number === "1"}
                      value='1'
                    >
                      1
                    </option>
                    <option
                      disabled={seventeenPageData.nine.two.number === "2"}
                      value='2'
                    >
                      2
                    </option>
                    <option
                      disabled={seventeenPageData.nine.two.number === "3"}
                      value='3'
                    >
                      3
                    </option>
                    <option
                      disabled={seventeenPageData.nine.two.number === "4"}
                      value='4'
                    >
                      4
                    </option>
                    <option
                      disabled={seventeenPageData.nine.two.number === "5"}
                      value='5'
                    >
                      5
                    </option>
                    <option
                      disabled={seventeenPageData.nine.two.number === "6"}
                      value='6'
                    >
                      6
                    </option>
                  </select>
                  &nbsp; &nbsp;&nbsp;2위:{" "}
                  <select
                    onChange={(e) => {
                      setisUpdate(true);
                      setSeventeenPageData({
                        ...seventeenPageData,
                        nine: {
                          ...seventeenPageData.nine,
                          two: {
                            ...seventeenPageData.nine.two,
                            number: e.target.value,
                          },
                        },
                      });
                    }}
                    value={
                      typeof seventeenPageData.nine.two.number === "boolean"
                        ? ""
                        : seventeenPageData.nine.two.number
                    }
                  >
                    <option value={""}>== 선택 ==</option>
                    <option
                      disabled={seventeenPageData.nine.one.number === "1"}
                      value='1'
                    >
                      1
                    </option>
                    <option
                      disabled={seventeenPageData.nine.one.number === "2"}
                      value='2'
                    >
                      2
                    </option>
                    <option
                      disabled={seventeenPageData.nine.one.number === "3"}
                      value='3'
                    >
                      3
                    </option>
                    <option
                      disabled={seventeenPageData.nine.one.number === "4"}
                      value='4'
                    >
                      4
                    </option>
                    <option
                      disabled={seventeenPageData.nine.one.number === "5"}
                      value='5'
                    >
                      5
                    </option>
                    <option
                      disabled={seventeenPageData.nine.one.number === "6"}
                      value='6'
                    >
                      6
                    </option>
                  </select>
                  &nbsp;
                </span>
              </span>
            </div>
            <div
              className='hls ps102'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "151.7mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <div>
                <span className='hrt cs150' style={{ width: "50%" }}>
                  ① 일반담보가 아닌 기술담보 형태의 대출
                </span>
                <span className='hrt cs150'>② 적기대출, 신속한 대출절차</span>
              </div>
              <div style={{ marginTop: "12px" }}>
                <span className='hrt cs150' style={{ width: "50%" }}>
                  ③ 낮은 대출금리
                </span>
                <span className='hrt cs150'> ④ 상대적으로 큰 금액의 대출 </span>
              </div>
              <div style={{ marginTop: "12px" }}>
                <span className='hrt cs150' style={{ width: "50%" }}>
                  ⑤ 대출기간의 장기성
                </span>
                <span className='hrt cs150'>
                  ⑥ 기타{" "}
                  {seventeenPageData.nine.one.number === "6" && (
                    <Input
                      type='text'
                      onChange={(e) => {
                        setisUpdate(true);
                        setSeventeenPageData({
                          ...seventeenPageData,
                          nine: {
                            ...seventeenPageData.nine,
                            one: {
                              ...seventeenPageData.nine.one,
                              text: e.target.value,
                            },
                          },
                        });
                      }}
                      value={seventeenPageData.nine.one.text}
                      style={{ marginLeft: "8px", width: "200px" }}
                    />
                  )}
                  {seventeenPageData.nine.two.number === "6" && (
                    <Input
                      type='text'
                      onChange={(e) => {
                        setisUpdate(true);
                        setSeventeenPageData({
                          ...seventeenPageData,
                          nine: {
                            ...seventeenPageData.nine,
                            two: {
                              ...seventeenPageData.nine.two,
                              text: e.target.value,
                            },
                          },
                        });
                      }}
                      value={seventeenPageData.nine.two.text}
                      style={{ marginLeft: "8px", width: "200px" }}
                    />
                  )}
                </span>
              </div>
            </div>

            <div
              className='hls ps116'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "174.39mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "178.27mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                10. 정보통신응용기술개발지원(융자)사업
              </span>
              <span className='hrt cs59'>이&nbsp;</span>
              <span className='hrt cs170'>어떻게 개선되어야 하는지</span>
              <span className='hrt cs59'>&nbsp;우선순위로 3순위</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "184.87mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs59'>
                까지만 응답해 주십시오. &nbsp;&nbsp;&nbsp;&nbsp;1위:{" "}
                <select
                  onChange={(e) => {
                    setisUpdate(true);
                    setSeventeenPageData({
                      ...seventeenPageData,
                      ten: {
                        ...seventeenPageData.ten,
                        one: {
                          ...seventeenPageData.ten.one,
                          number: e.target.value,
                        },
                      },
                    });
                  }}
                  value={
                    typeof seventeenPageData.ten.one.number === "boolean"
                      ? ""
                      : seventeenPageData.ten.one.number
                  }
                >
                  <option value={""}>== 선택 ==</option>
                  <option
                    disabled={
                      seventeenPageData.ten.two.number === "1" ||
                      seventeenPageData.ten.three.number === "1"
                    }
                    value='1'
                  >
                    1
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.two.number === "2" ||
                      seventeenPageData.ten.three.number === "2"
                    }
                    value='2'
                  >
                    2
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.two.number === "3" ||
                      seventeenPageData.ten.three.number === "3"
                    }
                    value='3'
                  >
                    3
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.two.number === "4" ||
                      seventeenPageData.ten.three.number === "4"
                    }
                    value='4'
                  >
                    4
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.two.number === "5" ||
                      seventeenPageData.ten.three.number === "5"
                    }
                    value='5'
                  >
                    5
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.two.number === "6" ||
                      seventeenPageData.ten.three.number === "6"
                    }
                    value='6'
                  >
                    6
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.two.number === "7" ||
                      seventeenPageData.ten.three.number === "7"
                    }
                    value='7'
                  >
                    7
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.two.number === "8" ||
                      seventeenPageData.ten.three.number === "8"
                    }
                    value='8'
                  >
                    8
                  </option>
                </select>
                &nbsp; &nbsp;&nbsp;2위:{" "}
                <select
                  onChange={(e) => {
                    setisUpdate(true);
                    setSeventeenPageData({
                      ...seventeenPageData,
                      ten: {
                        ...seventeenPageData.ten,
                        two: {
                          ...seventeenPageData.ten.two,
                          number: e.target.value,
                        },
                      },
                    });
                  }}
                  value={
                    typeof seventeenPageData.ten.two.number === "boolean"
                      ? ""
                      : seventeenPageData.ten.two.number
                  }
                >
                  <option value={""}>== 선택 ==</option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "1" ||
                      seventeenPageData.ten.three.number === "1"
                    }
                    value='1'
                  >
                    1
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "2" ||
                      seventeenPageData.ten.three.number === "2"
                    }
                    value='2'
                  >
                    2
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "3" ||
                      seventeenPageData.ten.three.number === "3"
                    }
                    value='3'
                  >
                    3
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "4" ||
                      seventeenPageData.ten.three.number === "4"
                    }
                    value='4'
                  >
                    4
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "5" ||
                      seventeenPageData.ten.three.number === "5"
                    }
                    value='5'
                  >
                    5
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "6" ||
                      seventeenPageData.ten.three.number === "6"
                    }
                    value='6'
                  >
                    6
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "7" ||
                      seventeenPageData.ten.three.number === "7"
                    }
                    value='7'
                  >
                    7
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "8" ||
                      seventeenPageData.ten.three.number === "8"
                    }
                    value='8'
                  >
                    8
                  </option>
                </select>
                &nbsp; &nbsp;&nbsp;3위:{" "}
                <select
                  onChange={(e) => {
                    setisUpdate(true);
                    setSeventeenPageData({
                      ...seventeenPageData,
                      ten: {
                        ...seventeenPageData.ten,
                        three: {
                          ...seventeenPageData.ten.three,
                          number: e.target.value,
                        },
                      },
                    });
                  }}
                  value={
                    typeof seventeenPageData.ten.three.number === "boolean"
                      ? ""
                      : seventeenPageData.ten.three.number
                  }
                >
                  <option value={""}>== 선택 ==</option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "1" ||
                      seventeenPageData.ten.two.number === "1"
                    }
                    value='1'
                  >
                    1
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "2" ||
                      seventeenPageData.ten.two.number === "2"
                    }
                    value='2'
                  >
                    2
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "3" ||
                      seventeenPageData.ten.two.number === "3"
                    }
                    value='3'
                  >
                    3
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "4" ||
                      seventeenPageData.ten.two.number === "4"
                    }
                    value='4'
                  >
                    4
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "5" ||
                      seventeenPageData.ten.two.number === "5"
                    }
                    value='5'
                  >
                    5
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "6" ||
                      seventeenPageData.ten.two.number === "6"
                    }
                    value='6'
                  >
                    6
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "7" ||
                      seventeenPageData.ten.two.number === "7"
                    }
                    value='7'
                  >
                    7
                  </option>
                  <option
                    disabled={
                      seventeenPageData.ten.one.number === "8" ||
                      seventeenPageData.ten.two.number === "8"
                    }
                    value='8'
                  >
                    8
                  </option>
                </select>
                &nbsp;
              </span>
            </div>

            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "198.08mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <div>
                <span className='hrt cs150' style={{ width: "50%" }}>
                  ① 공고방식의 다양화 등을 통한 홍보강화
                </span>
                <span className='hrt cs150'>② 지원절차 간소화</span>
              </div>

              <div
                style={{
                  marginTop: "12px",
                }}
              >
                <span className='hrt cs150' style={{ width: "50%" }}>
                  ③ 융자금리 인하
                </span>
                <span className='hrt cs150'>
                  ④ 융자금액 확대(융자규모 확대)
                </span>
              </div>

              <div
                style={{
                  marginTop: "12px",
                }}
              >
                <span className='hrt cs150' style={{ width: "50%" }}>
                  ⑤ 융자금 집행 및 정산 기준 완화
                </span>
                <span className='hrt cs150'> ⑥ 과제 성과평가 기준 완화</span>
              </div>

              <div
                style={{
                  marginTop: "12px",
                }}
              >
                <span className='hrt cs150' style={{ width: "50%" }}>
                  ⑦ 융자 상환기간 연장
                </span>
                <span className='hrt cs150'>
                  ⑧ 기타{" "}
                  {seventeenPageData.ten.one.number === "8" && (
                    <Input
                      type='text'
                      onChange={(e) => {
                        setisUpdate(true);
                        setSeventeenPageData({
                          ...seventeenPageData,
                          ten: {
                            ...seventeenPageData.ten,
                            one: {
                              ...seventeenPageData.ten.one,
                              text: e.target.value,
                            },
                          },
                        });
                      }}
                      value={seventeenPageData.ten.one.text}
                      style={{ marginLeft: "8px", width: "200px" }}
                    />
                  )}
                  {seventeenPageData.ten.two.number === "8" && (
                    <Input
                      type='text'
                      onChange={(e) => {
                        setisUpdate(true);
                        setSeventeenPageData({
                          ...seventeenPageData,
                          ten: {
                            ...seventeenPageData.ten,
                            two: {
                              ...seventeenPageData.ten.two,
                              text: e.target.value,
                            },
                          },
                        });
                      }}
                      value={seventeenPageData.ten.two.text}
                      style={{ marginLeft: "8px", width: "200px" }}
                    />
                  )}
                  {seventeenPageData.ten.three.number === "8" && (
                    <Input
                      type='text'
                      onChange={(e) => {
                        setisUpdate(true);
                        setSeventeenPageData({
                          ...seventeenPageData,
                          ten: {
                            ...seventeenPageData.ten,
                            three: {
                              ...seventeenPageData.ten.three,
                              text: e.target.value,
                            },
                          },
                        });
                      }}
                      value={seventeenPageData.ten.three.text}
                      style={{ marginLeft: "8px", width: "200px" }}
                    />
                  )}
                </span>
              </div>
            </div>

            <div
              className='hls ps116'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "224.5mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeventeenPageComponent;
