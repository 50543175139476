import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";

import { FivePageTypes } from "gita";

interface FivePageComponentProps {
  fivePageData: FivePageTypes;
  setFivePageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  targetYear: number;
}

const FivePageComponent = ({
  fivePageData,
  setFivePageData,
  setisUpdate,
  targetYear,
}: FivePageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          className='hpN'
          style={{
            left: "99.27mm",
            top: "282mm",
            width: "11.46mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 5 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps2'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.23mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>Ⅱ. Input (투입)</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "12.9mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                1. 본 융자사업과제의 수행기간(과제선정년도~과제종료년도)
                동안&nbsp;
              </span>
              <span className='hrt cs9'>총사업비 현황</span>
              <span className='hrt cs1'>을 기</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "19.51mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>재하여 주십시오.</span>
              <span className='hrt cs208'>
                (선정된 융자사업과제가 2개 이상인 경우에는 가장 최근 선정된 과
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "26.11mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs208'>제를 기준으로 작성해 주십시오)</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "43.17mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "32.91mm",
                height: "43.17mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "151mm",
                  top: "0.5mm",
                  height: "43.17mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 156 48.17'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "156mm",
                    height: "48.17mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_40'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(214,214,214)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_40)'
                    d='M0,0L32.07,0L32.07,42.17L0,42.17L0,0Z '
                  />
                  <path
                    d='M0,0 L0,42.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M32.07,0 L32.07,42.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M92.37,0 L92.37,42.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M150,0 L150,42.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L150.21,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M32.01,9.14 L150.21,9.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M32.01,17.29 L150.21,17.29'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M32.01,32.03 L150.21,32.03'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,42.17 L150.21,42.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M150,0 L150,42.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,42.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,42.17 L150.21,42.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L150.21,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "32.07mm",
                    height: "42.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "15.83mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.07mm",
                        }}
                      >
                        <span className='hrt cs171'>과제총사업비</span>
                      </div>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "31.07mm",
                        }}
                      >
                        <span className='hrt cs171'>(&nbsp;백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "32.07mm",
                    top: "0mm",
                    width: "60.3mm",
                    height: "9.14mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.31mm" }}>
                      <div
                        className='hls ps103'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "1.89mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "57.42mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          &nbsp;본 융자사업 융자지원금(a)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.37mm",
                    top: "0mm",
                    width: "57.63mm",
                    height: "9.14mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.31mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          <Input
                            onKeyDown={(e) =>
                              e.keyCode === 69 && e.preventDefault()
                            }
                            type='number'
                            onChange={(e) => {
                              const one =
                                e.target.value === "" ? false : e.target.value;
                              const {
                                two,
                                three: { number: three },
                              } = fivePageData.one;

                              const newNumber =
                                Number(one) + Number(two) + Number(three);
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                one: {
                                  ...fivePageData.one,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                  four: Number(newNumber.toFixed(2)),
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.one.one === "string"
                                ? fivePageData.one.one
                                : ""
                            }
                            style={{ width: "40%", textAlign: "right" }}
                          />
                          &nbsp;백만원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "32.07mm",
                    top: "9.14mm",
                    width: "60.3mm",
                    height: "8.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.81mm" }}>
                      <div
                        className='hls ps103'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "1.89mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "57.42mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          &nbsp;기업 자체자금 (b)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.37mm",
                    top: "9.14mm",
                    width: "57.63mm",
                    height: "8.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.81mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          <Input
                            onChange={(e) => {
                              const two =
                                e.target.value === "" ? false : e.target.value;
                              const {
                                one,
                                three: { number: three },
                              } = fivePageData.one;

                              const newNumber =
                                Number(one) + Number(two) + Number(three);

                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                one: {
                                  ...fivePageData.one,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                  four: Number(newNumber.toFixed(2)),
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.one.two === "string"
                                ? fivePageData.one.two
                                : ""
                            }
                            onKeyDown={(e) =>
                              e.keyCode === 69 && e.preventDefault()
                            }
                            type='number'
                            style={{ width: "40%", textAlign: "right" }}
                          />
                          &nbsp;백만원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "32.07mm",
                    top: "17.29mm",
                    width: "60.3mm",
                    height: "14.74mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "5.11mm" }}>
                      <div
                        className='hls ps104'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "1.89mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "57.42mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          &nbsp;타 기관 차입금 (c)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.37mm",
                    top: "17.29mm",
                    width: "57.63mm",
                    height: "14.74mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "0.52mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          <Input
                            onChange={(e) => {
                              const three =
                                e.target.value === "" ? false : e.target.value;
                              const { two, one } = fivePageData.one;

                              const newNumber =
                                Number(one) + Number(two) + Number(three);

                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                one: {
                                  ...fivePageData.one,
                                  three: {
                                    ...fivePageData.one.three,
                                    number:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  },
                                  four: Number(newNumber.toFixed(2)),
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.one.three.number === "string"
                                ? fivePageData.one.three.number
                                : ""
                            }
                            onKeyDown={(e) =>
                              e.keyCode === 69 && e.preventDefault()
                            }
                            type='number'
                            style={{
                              width: "40%",
                              fontSize: "0.8rem",
                              textAlign: "right",
                            }}
                          />
                          &nbsp;백만원
                        </span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      />
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          기관명 : &nbsp;&nbsp;
                          <Input
                            style={{
                              width: "40%",
                              fontSize: "0.7rem",
                              textAlign: "left",
                              padding: "0px 4px",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                one: {
                                  ...fivePageData.one,
                                  three: {
                                    ...fivePageData.one.three,
                                    text: e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              fivePageData.one.three.text
                                ? fivePageData.one.three.text
                                : ""
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "32.07mm",
                    top: "32.03mm",
                    width: "60.3mm",
                    height: "10.14mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.81mm" }}>
                      <div
                        className='hls ps103'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "1.89mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "57.42mm",
                        }}
                      >
                        <span className='hrt cs165'>&nbsp;계( a+b + c )</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "92.37mm",
                    top: "32.03mm",
                    width: "57.63mm",
                    height: "10.14mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.81mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "56.64mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          <Input
                            type='text'
                            style={{ width: "40%", textAlign: "right" }}
                            disabled
                            value={
                              typeof fivePageData.one.four === "number"
                                ? fivePageData.one.four
                                : 0
                            }
                          />
                          &nbsp;백만원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "78.63mm",
                height: "3.35mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs134'>
                &nbsp;&nbsp;※ 선정 과제가 2개 이상일 경우는 가장 최근 과제만
                기입
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "84.32mm",
                height: "3.35mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs134'>
                &nbsp;&nbsp;※ 타기관 차입금은 시중은행, 신용보증기금,
                기술보증기금, 중소기업진흥공단, 지방자치단체, 기타 등으로부터
                차입
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "90.02mm",
                height: "3.35mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs134'>한 금액을 합산하여 기입</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "95.71mm",
                height: "3.35mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs134'>
                &nbsp;&nbsp;※ 타기관 차입금이 없는 경우 금액을 0으로 기입
              </span>
            </div>
            <div
              className='hls ps65'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "2.91mm",
                top: "101.41mm",
                height: "3.35mm",
                width: "144.09mm",
              }}
            >
              <span className='hrt cs134'>
                ※ (c)의 기관명 : 예시&gt; 시중은행, 신용보증기금, 기술보증기금,
                중소기업진흥공단, &nbsp;지방자치단체, 기타 등
              </span>
            </div>
            <div
              className='hls ps65'
              style={{
                lineHeight: "2.63mm",
                whiteSpace: "nowrap",
                left: "2.91mm",
                top: "105.76mm",
                height: "3.35mm",
                width: "144.09mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "110.09mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                2. 귀사는 본 융자사업과제를 신청하기 전에
                제1금융권(시중은행)에서&nbsp;
              </span>
              <span className='hrt cs9'>대출을 시도하였</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "116.69mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs9'>거나</span>
              <span className='hrt cs183'>,</span>
              <span className='hrt cs11'>&nbsp;</span>
              <span className='hrt cs9'>대출을 받아본 경험</span>
              <span className='hrt cs1'>이 있습니까?</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "123.3mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFivePageData({
                        ...fivePageData,
                        two: {
                          ...fivePageData.two,
                          one: "1",
                        },
                      });
                    }}
                    checked={fivePageData.two.one === "1"}
                    name='yesorno'
                  />{" "}
                  예 ☞ 2-1번으로 이동 &nbsp;&nbsp;&nbsp;
                </label>
              </span>
              <span
                className='htC'
                style={{ left: "0.97mm", width: "1.12mm", height: "100%" }}
              />
              <span className='hrt cs1'>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setFivePageData({
                        ...fivePageData,
                        two: {
                          ...fivePageData.two,
                          one: "2",
                        },
                      });
                    }}
                    checked={fivePageData.two.one === "2"}
                    name='yesorno'
                  />
                  아니오 ☞ 3번으로 이동
                </label>
              </span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "129.91mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>
                2-1. 위 2번 항목에 예라고 응답한 경우,&nbsp;
              </span>
              <span className='hrt cs124'>대출액(대출신청액) 및 대출금리</span>
              <span className='hrt cs8'>를 기재해주시기&nbsp;</span>
            </div>
            <div
              className='hls ps5'
              style={{
                paddingLeft: "7.06mm",
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "136.19mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>바랍니다.&nbsp;</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "21.27mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "142.67mm",
                height: "21.27mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "148.53mm",
                  top: "1mm",
                  height: "21.27mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.53 26.26'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.53mm",
                    height: "26.26mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_41'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_41)'
                    d='M0,0L51.92,0L51.92,9.11L0,9.11L0,0Z '
                  />
                  <defs>
                    <pattern
                      id='w_42'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_42)'
                    d='M51.92,0L99.23,0L99.23,9.11L51.92,9.11L51.92,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M99.23,0L146.53,0L146.53,9.11L99.23,9.11L99.23,0Z '
                  />
                  <path
                    d='M0,0 L0,19.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M51.92,0 L51.92,19.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M99.23,0 L99.23,19.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M146.53,0 L146.53,19.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L146.73,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,9.11 L146.73,9.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,19.27 L146.73,19.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M146.53,0 L146.53,19.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,19.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,19.27 L146.73,19.27'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L146.73,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "51.92mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.93mm",
                        }}
                      >
                        <span className='hrt cs132'>신청년도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.92mm",
                    top: "0mm",
                    width: "47.3mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "46.3mm",
                        }}
                      >
                        <span className='hrt cs132'>
                          제1금융권 대출액(백만원)
                        </span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "46.3mm",
                        }}
                      >
                        <span className='hrt cs132'>(혹은 대출신청액)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "99.23mm",
                    top: "0mm",
                    width: "47.3mm",
                    height: "9.11mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "46.3mm",
                        }}
                      >
                        <span className='hrt cs132'>대출금리(%)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "9.11mm",
                    width: "51.92mm",
                    height: "10.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.82mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.93mm",
                        }}
                      >
                        <span className='hrt cs144'>
                          <select
                            disabled={fivePageData.two.one !== "1"}
                            onChange={(e) => {
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                two: {
                                  ...fivePageData.two,
                                  two: {
                                    ...fivePageData.two.two,
                                    one: e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.two.two.one === "string"
                                ? fivePageData.two.two.one
                                : ""
                            }
                          >
                            <option value={""}>선택</option>
                            {new Array(124)
                              .fill(undefined)
                              .map((item, index) => {
                                const year = index + 1900;
                                return (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                );
                              })}
                          </select>
                          년
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.92mm",
                    top: "9.11mm",
                    width: "47.3mm",
                    height: "10.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.64mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "46.3mm",
                        }}
                      >
                        <span className='hrt cs172'>
                          <Input
                            disabled={fivePageData.two.one !== "1"}
                            type='number'
                            style={{ width: "40%", textAlign: "right" }}
                            onKeyDown={(event) =>
                              event.keyCode === 69 && event.preventDefault()
                            }
                            onChange={(e) => {
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                two: {
                                  ...fivePageData.two,
                                  two: {
                                    ...fivePageData.two.two,
                                    two:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.two.two.two === "string"
                                ? fivePageData.two.two.two
                                : ""
                            }
                          />
                          &nbsp;백만원
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "99.23mm",
                    top: "9.11mm",
                    width: "47.3mm",
                    height: "10.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.64mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "46.3mm",
                        }}
                      >
                        <span className='hrt cs174'>
                          <Input
                            disabled={fivePageData.two.one !== "1"}
                            type='number'
                            onKeyDown={(event) =>
                              event.keyCode === 69 && event.preventDefault()
                            }
                            style={{ width: "40%", textAlign: "right" }}
                            onChange={(e) => {
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                two: {
                                  ...fivePageData.two,
                                  two: {
                                    ...fivePageData.two.two,
                                    three:
                                      e.target.value === ""
                                        ? false
                                        : Number(e.target.value) > 100
                                        ? 100
                                        : e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.two.two.three === "string"
                                ? fivePageData.two.two.three
                                : ""
                            }
                          />
                          &nbsp;%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps107'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "166.46mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "172.67mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>3. 귀사가 본 융자사업과제에&nbsp;</span>
              <span className='hrt cs152'>투입한 종업원 수</span>
              <span className='hrt cs1'>
                를 과제선정년도부터 과제로 인한 매출
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "179.27mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                발생년도까지 기재해 주시기 바랍니다.
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "18.51mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "186.07mm",
                height: "18.51mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "18.51mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.01 23.51'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.01mm",
                    height: "23.51mm",
                  }}
                >
                  <path
                    d='M0,0 L0,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,16.52 L148.07,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,16.52 L148.07,16.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "16.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs202'>
                          ※ 과제 선정년도 이전의 년도 투입인력은 “0”으로 기재
                        </span>
                      </div>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs202'>
                          &nbsp;&nbsp;&nbsp;&nbsp;(예: 2019년 선정기업은
                          2017년과 2018년은 투입인력을 “0”으로 기재
                        </span>
                      </div>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "11.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs202'>
                          ※ 과제로 인해 매출이 발생한 첫해년도까지만 기입하고 그
                          다음년도부터는 ‘0’으로 기입
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps24'
              style={{
                lineHeight: "20.31mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "207.31mm",
                height: "20.31mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "148.9mm",
                  top: "1mm",
                  height: "20.31mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.90 25.31'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.9mm",
                    height: "25.31mm",
                  }}
                >
                  <path
                    fill='url(#w_42)'
                    d='M0,0L20.13,0L20.13,7.16L0,7.16L0,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M20.13,0L41.26,0L41.26,7.16L20.13,7.16L20.13,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M41.26,0L62.39,0L62.39,7.16L41.26,7.16L41.26,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M62.39,0L83.51,0L83.51,7.16L62.39,7.16L62.39,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M83.51,0L104.64,0L104.64,7.16L83.51,7.16L83.51,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M104.64,0L125.77,0L125.77,7.16L104.64,7.16L104.64,0Z '
                  />
                  <path
                    fill='url(#w_42)'
                    d='M125.77,0L146.90,0L146.90,7.16L125.77,7.16L125.77,0Z '
                  />
                  <path
                    d='M0,0 L0,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M20.13,0 L20.13,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M41.26,0 L41.26,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M62.39,0 L62.39,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M83.51,0 L83.51,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M104.64,0 L104.64,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M125.77,0 L125.77,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M146.90,0 L146.90,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.10,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,7.16 L147.10,7.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,18.31 L147.10,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M146.90,0 L146.90,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,18.31 L147.10,18.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.10,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "20.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "19.13mm",
                        }}
                      >
                        <span className='hrt cs164'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "20.13mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2017년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "41.26mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "62.39mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.51mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "104.64mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "125.77mm",
                    top: "0mm",
                    width: "21.13mm",
                    height: "7.16mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs164'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "7.16mm",
                    width: "20.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "19.13mm",
                        }}
                      >
                        <span className='hrt cs122'>투입인력</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "20.13mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>
                          <Input
                            type='text'
                            style={{
                              width: "70%",
                              textAlign: "right",
                              padding: "0px 2px",
                            }}
                            disabled={targetYear > 2017}
                            onChange={(e) => {
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                three: {
                                  ...fivePageData.three,
                                  one:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.three.one === "number"
                                ? fivePageData.three.one
                                : ""
                            }
                          />
                          &nbsp; 명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "41.26mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>
                          <Input
                            type='text'
                            style={{
                              width: "70%",
                              textAlign: "right",
                              padding: "0px 2px",
                            }}
                            disabled={targetYear > 2018}
                            onChange={(e) => {
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                three: {
                                  ...fivePageData.three,
                                  two:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.three.two === "number"
                                ? fivePageData.three.two
                                : ""
                            }
                          />
                          &nbsp; 명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "62.39mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>
                          <Input
                            type='text'
                            style={{
                              width: "70%",
                              textAlign: "right",
                              padding: "0px 2px",
                            }}
                            disabled={targetYear > 2019}
                            onChange={(e) => {
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                three: {
                                  ...fivePageData.three,
                                  three:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.three.three === "number"
                                ? fivePageData.three.three
                                : ""
                            }
                          />
                          &nbsp; 명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.51mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>
                          <Input
                            type='text'
                            style={{
                              width: "70%",
                              textAlign: "right",
                              padding: "0px 2px",
                            }}
                            disabled={targetYear > 2020}
                            onChange={(e) => {
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                three: {
                                  ...fivePageData.three,
                                  four:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.three.four === "number"
                                ? fivePageData.three.four
                                : ""
                            }
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "104.64mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>
                          <Input
                            type='text'
                            style={{
                              width: "70%",
                              textAlign: "right",
                              padding: "0px 2px",
                            }}
                            disabled={targetYear > 2021}
                            onChange={(e) => {
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                three: {
                                  ...fivePageData.three,
                                  five:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.three.five === "number"
                                ? fivePageData.three.five
                                : ""
                            }
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "125.77mm",
                    top: "7.16mm",
                    width: "21.13mm",
                    height: "11.15mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.32mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.14mm",
                        }}
                      >
                        <span className='hrt cs175'>
                          <Input
                            type='text'
                            style={{
                              width: "70%",
                              textAlign: "right",
                              padding: "0px 2px",
                            }}
                            disabled={targetYear > 2022}
                            onChange={(e) => {
                              setisUpdate(true);
                              setFivePageData({
                                ...fivePageData,
                                three: {
                                  ...fivePageData.three,
                                  six:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                },
                              });
                            }}
                            value={
                              typeof fivePageData.three.six === "number"
                                ? fivePageData.three.six
                                : ""
                            }
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps4'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "228.59mm",
                height: "3mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FivePageComponent;
