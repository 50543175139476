import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";
import { ElevenPageTypes } from "gita";

import _ from "lodash";

interface ElevenPageComponentProps {
  elevenPageData: ElevenPageTypes;
  setElevenPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  targetYear: number;
}

const ElevenPageComponent = ({
  elevenPageData,
  setElevenPageData,
  setisUpdate,
  targetYear,
}: ElevenPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "347mm" }}>
        <div
          className='hpN'
          style={{
            left: "98.24mm",
            top: "332mm",
            width: "13.52mm",
            height: "35mm",
          }}
        >
          <span className='hrt cs0'>- 11 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "1.25mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>
                Ⅴ-2. Outcome (결과/서비스개발) &nbsp;
              </span>
            </div>
            <div
              className='hls ps7'
              style={{
                lineHeight: "7.74mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "10.3mm",
                height: "8.35mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "149.98mm",
                  top: "1mm",
                  height: "8.35mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.98 13.35'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.98mm",
                    height: "13.35mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_100'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(255,255,0)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_100)'
                    d='M0,0L147.98,0L147.98,6.36L0,6.36L0,0Z '
                  />
                  <path
                    d='M0,0 L0,6.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M147.98,0 L147.98,6.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.05,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,6.36 L148.05,6.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M147.98,0 L147.98,6.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,6.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,6.36 L148.05,6.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.05,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "147.98mm",
                    height: "6.36mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps49'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "1.29mm",
                          height: "3.88mm",
                          width: "144.38mm",
                        }}
                      >
                        <span className='hrt cs65'>
                          B. 「서비스개발」에 해당하는 경우, 응답
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps108'
              style={{
                lineHeight: "1.59mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "20.88mm",
                height: "2.12mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "24.39mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                1. 본 융자사업과제(서비스개발)로 발생한 매출
              </span>
              <span className='hrt cs152'>(</span>
              <span className='hrt cs9'>사업화 매출액</span>
              <span className='hrt cs152'>)</span>
              <span className='hrt cs1'>에 대한 질문입니다.&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "30.99mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp; 귀사는 사업화매출액이 정확하게 파악되십니까?
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "37.6mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setElevenPageData({
                        ...elevenPageData,
                        one: "1",
                      });
                    }}
                    checked={elevenPageData.one === "1"}
                    name='industry'
                  />
                  &nbsp; 사업화매출액이 정확하게 파악된다. (1-1문항으로 이동)
                </label>
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "44.2mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    onChange={() => {
                      setisUpdate(true);
                      setElevenPageData({
                        ...elevenPageData,
                        one: "2",
                      });
                    }}
                    checked={elevenPageData.one === "2"}
                    name='industry'
                  />
                  &nbsp; 사업화매출액이 모호하다. (1-2문항으로 이동)
                </label>
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "50.81mm",
                height: "3.7mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs8'>
                1-1. 「서비스개발」에 해당하는 경우, 귀사의 총매출액과&nbsp;
              </span>
              <span className='hrt cs12'>본 융자사업과제로 발생한&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "57.1mm",
                height: "3.7mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs12'>매출액(사업화 매출액)</span>
              <span className='hrt cs8'>
                에 대하여 응답하여 주십시오.&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "12.52mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "63.57mm",
                height: "12.52mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "12.52mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.01 17.52'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.01mm",
                    height: "17.52mm",
                  }}
                >
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "10.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※예: 2017년 선정기업은 2018년부터 기입, 2018년
                          선정기업은 2019년부터 기입
                        </span>
                      </div>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※2022년 선정기업은 2022년만 기입
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps36'
              style={{
                lineHeight: "54.15mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "78.81mm",
                height: "54.15mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "149.78mm",
                  top: "1mm",
                  height: "54.15mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.77 59.15'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.77mm",
                    height: "59.15mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_101'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_101)'
                    d='M0,0L23.65,0L23.65,14.17L0,14.17L0,0Z '
                  />
                  <path
                    fill='url(#w_101)'
                    d='M23.65,0L82.77,0L82.77,8.08L23.65,8.08L23.65,0Z '
                  />
                  <defs>
                    <pattern
                      id='w_102'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_102)'
                    d='M82.77,0L147.78,0L147.78,8.08L82.77,8.08L82.77,0Z '
                  />
                  <path
                    fill='url(#w_101)'
                    d='M23.65,8.08L55.18,8.08L55.18,14.17L23.65,14.17L23.65,8.08Z '
                  />
                  <path
                    fill='url(#w_101)'
                    d='M55.18,8.08L82.77,8.08L82.77,14.17L55.18,14.17L55.18,8.08Z '
                  />
                  <path
                    fill='url(#w_101)'
                    d='M82.77,8.08L119.22,8.08L119.22,14.17L82.77,14.17L82.77,8.08Z '
                  />
                  <path
                    fill='url(#w_101)'
                    d='M119.22,8.08L147.78,8.08L147.78,14.17L119.22,14.17L119.22,8.08Z '
                  />
                  <path
                    d='M0,0 L0,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M23.65,0 L23.65,21.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,14.17 L23.65,21.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,21.97 L23.65,29.77'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,21.97 L23.65,29.77'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,29.77 L23.65,37.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,29.77 L23.65,37.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,37.57 L23.65,45.37'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,37.57 L23.65,45.37'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,45.37 L23.65,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,45.37 L23.65,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.18,8.08 L55.18,14.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.18,14.17 L55.18,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M82.77,0 L82.77,14.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M82.77,14.17 L82.77,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M119.22,8.08 L119.22,14.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M119.22,14.17 L119.22,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M147.78,0 L147.78,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M23.59,8.08 L148.03,8.08'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.25,14.17 L55.24,14.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,14.17 L55.26,14.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.12,14.17 L82.84,14.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.10,14.17 L148.03,14.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,21.97 L23.71,21.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,21.97 L148.03,21.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,29.77 L23.71,29.77'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,29.77 L148.03,29.77'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,37.57 L23.71,37.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,37.57 L148.03,37.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,45.37 L23.71,45.37'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,45.37 L148.03,45.37'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,52.16 L148.03,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M147.78,0 L147.78,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M0,0 L0,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,52.16 L148.03,52.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "23.65mm",
                    height: "14.17mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "4.82mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs129'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "0mm",
                    width: "59.13mm",
                    height: "8.08mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.78mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "58.12mm",
                        }}
                      >
                        <span className='hrt cs129'>기업 총 매출액</span>
                        <span className='hrt cs130'>1)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "0mm",
                    width: "65.01mm",
                    height: "8.08mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.01mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "64.01mm",
                        }}
                      >
                        <span className='hrt cs132'>
                          융자사업과제로 인한 매출액
                        </span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "64.01mm",
                        }}
                      >
                        <span className='hrt cs132'>(사</span>
                        <span className='hrt cs129'>업화 매출액</span>
                        <span className='hrt cs130'>2)</span>
                        <span className='hrt cs132'>)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "8.08mm",
                    width: "31.53mm",
                    height: "6.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.78mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <span className='hrt cs129'>국내(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "8.08mm",
                    width: "27.59mm",
                    height: "6.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.78mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <span className='hrt cs129'>수출(백만원)</span>
                        <span className='hrt cs130'>3)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "8.08mm",
                    width: "36.45mm",
                    height: "6.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.78mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <span className='hrt cs129'>국내(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "8.08mm",
                    width: "28.55mm",
                    height: "6.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.78mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <span className='hrt cs129'>수출(백만원)</span>
                        <span className='hrt cs130'>3)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "14.17mm",
                    width: "23.65mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "14.17mm",
                    width: "31.53mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                one: {
                                  ...elevenPageData.two.one,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.one.one === "string"
                              ? elevenPageData.two.one.one
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "14.17mm",
                    width: "27.59mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                one: {
                                  ...elevenPageData.two.one,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.one.two === "string"
                              ? elevenPageData.two.one.two
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "14.17mm",
                    width: "36.45mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                one: {
                                  ...elevenPageData.two.one,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.one.three === "string"
                              ? elevenPageData.two.one.three
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "14.17mm",
                    width: "28.55mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                one: {
                                  ...elevenPageData.two.one,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.one.four === "string"
                              ? elevenPageData.two.one.four
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "21.97mm",
                    width: "23.65mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.45mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "21.97mm",
                    width: "31.53mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                two: {
                                  ...elevenPageData.two.two,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.two.one === "string"
                              ? elevenPageData.two.two.one
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "21.97mm",
                    width: "27.59mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                two: {
                                  ...elevenPageData.two.two,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.two.two === "string"
                              ? elevenPageData.two.two.two
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "21.97mm",
                    width: "36.45mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                two: {
                                  ...elevenPageData.two.two,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.two.three === "string"
                              ? elevenPageData.two.two.three
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "21.97mm",
                    width: "28.55mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                two: {
                                  ...elevenPageData.two.two,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.two.four === "string"
                              ? elevenPageData.two.two.four
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "29.77mm",
                    width: "23.65mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.45mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "29.77mm",
                    width: "31.53mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                three: {
                                  ...elevenPageData.two.three,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.three.one === "string"
                              ? elevenPageData.two.three.one
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "29.77mm",
                    width: "27.59mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                three: {
                                  ...elevenPageData.two.three,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.three.two === "string"
                              ? elevenPageData.two.three.two
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "29.77mm",
                    width: "36.45mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                three: {
                                  ...elevenPageData.two.three,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.three.three === "string"
                              ? elevenPageData.two.three.three
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "29.77mm",
                    width: "28.55mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                three: {
                                  ...elevenPageData.two.three,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.three.four === "string"
                              ? elevenPageData.two.three.four
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "37.57mm",
                    width: "23.65mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "37.57mm",
                    width: "31.53mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                four: {
                                  ...elevenPageData.two.four,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.four.one === "string"
                              ? elevenPageData.two.four.one
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "37.57mm",
                    width: "27.59mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                four: {
                                  ...elevenPageData.two.four,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.four.two === "string"
                              ? elevenPageData.two.four.two
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "37.57mm",
                    width: "36.45mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                four: {
                                  ...elevenPageData.two.four,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.four.three === "string"
                              ? elevenPageData.two.four.three
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "37.57mm",
                    width: "28.55mm",
                    height: "7.8mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "1" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                four: {
                                  ...elevenPageData.two.four,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.four.four === "string"
                              ? elevenPageData.two.four.four
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "45.37mm",
                    width: "23.65mm",
                    height: "6.79mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.13mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "45.37mm",
                    width: "31.53mm",
                    height: "6.79mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.5mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={elevenPageData.one !== "1"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                five: {
                                  ...elevenPageData.two.five,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.five.one === "string"
                              ? elevenPageData.two.five.one
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "45.37mm",
                    width: "27.59mm",
                    height: "6.79mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.5mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={elevenPageData.one !== "1"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                five: {
                                  ...elevenPageData.two.five,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.five.two === "string"
                              ? elevenPageData.two.five.two
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "45.37mm",
                    width: "36.45mm",
                    height: "6.79mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.5mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <Input
                          disabled={elevenPageData.one !== "1"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                five: {
                                  ...elevenPageData.two.five,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.five.three === "string"
                              ? elevenPageData.two.five.three
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "45.37mm",
                    width: "28.55mm",
                    height: "6.79mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.5mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <Input
                          disabled={elevenPageData.one !== "1"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              two: {
                                ...elevenPageData.two,
                                five: {
                                  ...elevenPageData.two.five,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.two.five.four === "string"
                              ? elevenPageData.two.five.four
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "135.54mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                1) 기업 총 매출액: 당해 년도 발생한 총 매출액을 의미.
                융자사업과제 매출을 포함한 총 매출액을 기입
              </span>
            </div>
            <div
              className='hls ps89'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "139.44mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                2) 사업화 매출액: 기술과제를 통해 개발한 새로운 서비스의 판매로
                인해 유발된 매출액 또는 기술과제를 통해 개발한 기술을&nbsp;
              </span>
            </div>
            <div
              className='hls ps89'
              style={{
                paddingLeft: "3.54mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "143.34mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                활용하여 기존에 판매하던 서비스의 기능 강화 또는 가격 인하로
                인해 발생한 매출증가액(객관적인 계산이 어려운 경우에는&nbsp;
              </span>
            </div>
            <div
              className='hls ps89'
              style={{
                paddingLeft: "3.54mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "147.24mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                가급적 정확하게 추정하여 기입하여 주시기 바랍니다.)
              </span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "151.14mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                3) 외화표시(US$, ￥ 등)로 되어 있을 경우, 발생시점 기준의 환율을
                추정하여 원화로 환산한 값을 기재 요망.&nbsp;
              </span>
            </div>
            <div
              className='hls ps67'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "155.01mm",
                height: "3.7mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "161.3mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>1-2. 위 1-1문항에서&nbsp;</span>
              <span className='hrt cs12'>
                사업화 매출액을 정확하게 파악할 수 없는 경우에 기입
              </span>
              <span className='hrt cs8'>해 주시기 바</span>
            </div>
            <div
              className='hls ps5'
              style={{
                paddingLeft: "7.06mm",
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "167.58mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>랍니다.</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "12.52mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "174.06mm",
                height: "12.52mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "12.52mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.01 17.52'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.01mm",
                    height: "17.52mm",
                  }}
                >
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "10.52mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI'>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※예: 2017년 선정기업은 2018년부터 기입, 2018년
                          선정기업은 2019년부터 기입
                        </span>
                      </div>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※2022년 선정기업은 2022년만 기입
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps111'
              style={{
                lineHeight: "56.15mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "189.3mm",
                height: "56.15mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.77mm",
                  top: "1mm",
                  height: "56.15mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.77 61.15'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "155.77mm",
                    height: "61.15mm",
                  }}
                >
                  <path
                    fill='url(#w_101)'
                    d='M0,0L24.65,0L24.65,16.91L0,16.91L0,0Z '
                  />
                  <path
                    fill='url(#w_101)'
                    d='M24.65,0L83.77,0L83.77,8.89L24.65,8.89L24.65,0Z '
                  />
                  <path
                    fill='url(#w_102)'
                    d='M83.77,0L148.78,0L148.78,8.89L83.77,8.89L83.77,0Z '
                  />
                  <path
                    fill='url(#w_101)'
                    d='M24.65,8.89L56.18,8.89L56.18,16.91L24.65,16.91L24.65,8.89Z '
                  />
                  <path
                    fill='url(#w_101)'
                    d='M56.18,8.89L83.77,8.89L83.77,16.91L56.18,16.91L56.18,8.89Z '
                  />
                  <path
                    fill='url(#w_102)'
                    d='M83.77,8.89L116.27,8.89L116.27,16.91L83.77,16.91L83.77,8.89Z '
                  />
                  <path
                    fill='url(#w_102)'
                    d='M116.27,8.89L148.78,8.89L148.78,16.91L116.27,16.91L116.27,8.89Z '
                  />
                  <path
                    d='M0,0 L0,54.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M24.65,0 L24.65,24.93'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,16.91 L24.65,24.93'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,24.92 L24.65,32.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,24.92 L24.65,32.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,32.94 L24.65,40.03'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,32.94 L24.65,40.03'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,40.03 L24.65,47.12'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,40.03 L24.65,47.12'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,47.11 L24.65,54.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,47.11 L24.65,54.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M56.18,8.89 L56.18,16.91'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M56.18,16.91 L56.18,54.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M83.77,0 L83.77,16.91'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M83.77,16.91 L83.77,54.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M116.27,8.89 L116.27,54.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.1",
                    }}
                  />
                  <path
                    d='M148.78,0 L148.78,54.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L149.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M24.59,8.89 L149.03,8.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.25,16.91 L56.24,16.91'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,16.91 L56.25,16.91'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M56.12,16.91 L83.83,16.91'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M56.10,16.91 L149.03,16.91'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,24.92 L24.71,24.92'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,24.92 L149.03,24.92'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,32.94 L24.71,32.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,32.94 L149.03,32.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,40.03 L24.71,40.03'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,40.03 L149.03,40.03'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,47.11 L24.71,47.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,47.11 L149.03,47.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,54.15 L149.03,54.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M148.78,0 L148.78,54.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M0,0 L0,54.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,54.15 L149.03,54.15'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L149.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "24.65mm",
                    height: "16.91mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "6.19mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs129'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "0mm",
                    width: "59.13mm",
                    height: "8.89mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.18mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "58.12mm",
                        }}
                      >
                        <span className='hrt cs129'>기업 총 매출액</span>
                        <span className='hrt cs130'>1)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "0mm",
                    width: "65.01mm",
                    height: "8.89mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.42mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "64.01mm",
                        }}
                      >
                        <span className='hrt cs129'>융자사업과제의&nbsp;</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "64.01mm",
                        }}
                      >
                        <span className='hrt cs129'>매출액 기여율</span>
                        <span className='hrt cs130'>3)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "8.89mm",
                    width: "31.53mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.75mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <span className='hrt cs129'>국내(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "8.89mm",
                    width: "27.59mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.75mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <span className='hrt cs129'>수출(백만원)</span>
                        <span className='hrt cs130'>2)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "8.89mm",
                    width: "32.5mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.75mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <span className='hrt cs129'>국내 매출액 기여율</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "8.89mm",
                    width: "32.5mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.75mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <span className='hrt cs129'>수출액 기여율</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "16.91mm",
                    width: "24.65mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.75mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "16.91mm",
                    width: "31.53mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                one: {
                                  ...elevenPageData.three.one,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.one.one === "string"
                              ? elevenPageData.three.one.one
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "16.91mm",
                    width: "27.59mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                one: {
                                  ...elevenPageData.three.one,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.one.two === "string"
                              ? elevenPageData.three.one.two
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "16.91mm",
                    width: "32.5mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                one: {
                                  ...elevenPageData.three.one,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.one.three === "string"
                              ? elevenPageData.three.one.three
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "16.91mm",
                    width: "32.5mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2017
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                one: {
                                  ...elevenPageData.three.one,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.one.four === "string"
                              ? elevenPageData.three.one.four
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "24.92mm",
                    width: "24.65mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.75mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "24.92mm",
                    width: "31.53mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                two: {
                                  ...elevenPageData.three.two,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.two.one === "string"
                              ? elevenPageData.three.two.one
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "24.92mm",
                    width: "27.59mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                two: {
                                  ...elevenPageData.three.two,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.two.two === "string"
                              ? elevenPageData.three.two.two
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "24.92mm",
                    width: "32.5mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                two: {
                                  ...elevenPageData.three.two,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.two.three === "string"
                              ? elevenPageData.three.two.three
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "24.92mm",
                    width: "32.5mm",
                    height: "8.02mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2018
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                two: {
                                  ...elevenPageData.three.two,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.two.four === "string"
                              ? elevenPageData.three.two.four
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "32.94mm",
                    width: "24.65mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.28mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "32.94mm",
                    width: "31.53mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                three: {
                                  ...elevenPageData.three.three,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.three.one === "string"
                              ? elevenPageData.three.three.one
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "32.94mm",
                    width: "27.59mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                three: {
                                  ...elevenPageData.three.three,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.three.two === "string"
                              ? elevenPageData.three.three.two
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "32.94mm",
                    width: "32.5mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                three: {
                                  ...elevenPageData.three.three,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.three.three === "string"
                              ? elevenPageData.three.three.three
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "32.94mm",
                    width: "32.5mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2019
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                three: {
                                  ...elevenPageData.three.three,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.three.four === "string"
                              ? elevenPageData.three.three.four
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "40.03mm",
                    width: "24.65mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.28mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "40.03mm",
                    width: "31.53mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                four: {
                                  ...elevenPageData.three.four,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.four.one === "string"
                              ? elevenPageData.three.four.one
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "40.03mm",
                    width: "27.59mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                four: {
                                  ...elevenPageData.three.four,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.four.two === "string"
                              ? elevenPageData.three.four.two
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "40.03mm",
                    width: "32.5mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                four: {
                                  ...elevenPageData.three.four,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.four.three === "string"
                              ? elevenPageData.three.four.three
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "40.03mm",
                    width: "32.5mm",
                    height: "7.09mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <Input
                          disabled={
                            elevenPageData.one !== "2" || targetYear > 2020
                          }
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                four: {
                                  ...elevenPageData.three.four,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.four.four === "string"
                              ? elevenPageData.three.four.four
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "47.11mm",
                    width: "24.65mm",
                    height: "7.04mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.26mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "47.11mm",
                    width: "31.53mm",
                    height: "7.04mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <Input
                          disabled={elevenPageData.one !== "2"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                five: {
                                  ...elevenPageData.three.five,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.five.one === "string"
                              ? elevenPageData.three.five.one
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "47.11mm",
                    width: "27.59mm",
                    height: "7.04mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.6mm",
                        }}
                      >
                        <Input
                          disabled={elevenPageData.one !== "2"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                five: {
                                  ...elevenPageData.three.five,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.five.two === "string"
                              ? elevenPageData.three.five.two
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "47.11mm",
                    width: "32.5mm",
                    height: "7.04mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.5mm",
                        }}
                      >
                        <Input
                          disabled={elevenPageData.one !== "2"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                five: {
                                  ...elevenPageData.three.five,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.five.three === "string"
                              ? elevenPageData.three.five.three
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "47.11mm",
                    width: "32.5mm",
                    height: "7.04mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.9mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <Input
                          disabled={elevenPageData.one !== "2"}
                          type='number'
                          onKeyDown={(event) =>
                            event.keyCode === 69 && event.preventDefault()
                          }
                          onChange={(e) => {
                            setisUpdate(true);
                            setElevenPageData({
                              ...elevenPageData,
                              three: {
                                ...elevenPageData.three,
                                five: {
                                  ...elevenPageData.three.five,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              },
                            });
                          }}
                          value={
                            typeof elevenPageData.three.five.four === "string"
                              ? elevenPageData.three.five.four
                              : ""
                          }
                          className='eleven-input-number'
                          style={{ width: "70%", height: "90%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "247.88mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                1) 기업 총 매출액: 당해년도 발생한 총 매출액을 의미.
                융자사업과제 매출을 포함한 총 매출액을 기입.
              </span>
            </div>
            <div
              className='hls ps88'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "251.78mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs13'>
                2) 융자사업과제의 매출액 기여율: 매출액에 대해 융자사업과제가
                기여한 정도를 의미(0~100%).
              </span>
            </div>
            <div
              className='hls ps88'
              style={{
                lineHeight: "2.48mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "255.68mm",
                height: "3.17mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs179'>※&nbsp;</span>
              <span className='hrt cs180'>
                융자사업과제를 통해 개발된 기술, 구축된 시스템, 기술장비 등이
                서비스 제공에&nbsp;
              </span>
              <span className='hrt cs181'>필수요소</span>
              <span className='hrt cs180'>인 경우, 100%로 기입.</span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "259.82mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                3) 외화표시(US$, ￥ 등)로 되어 있을 경우, 발생시점 기준의 환율을
                추정하여 원화로 환산한 값을 기재 요망.&nbsp;
              </span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "263.72mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "263.72mm",
                height: "3mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElevenPageComponent;
