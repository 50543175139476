import React from "react";
import { Col, Row, Table, Button, Input, Modal, Form, Select } from "antd";
import Draggable from "react-draggable";
import styles from "./admin.module.css";
import { Option } from "antd/es/mentions";

const AdminComponent = ({
  dataSource,
  columns,
  setPage,
  setSearch,
  search,
  isModalOpen,
  showModal,
  handleOk,
  handleCancel,
  form,
  setForm,
  bounds,
  onStart,
  draggleRef,
  handleDownload,
  rowSelection,
  hasSelected,
  downLoadList,
  isFile,
  setIsFile,
  targetFile,
  setTargetFile,
  setIsStatus,
  isStatus,
  rowDownLoadList,
}: any) => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <div className={styles.table_nav}>
            <div>
              <Button onClick={() => downLoadList()} disabled={!hasSelected}>
                선택 다운받기
              </Button>

              <Button
                style={{
                  marginLeft: "20px",
                }}
                disabled={!hasSelected}
                onClick={() => rowDownLoadList()}
              >
                통계
              </Button>
            </div>
            <div>
              <Select
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={isStatus}
                onChange={(e) => {
                  setIsStatus(e);
                }}
                options={[
                  { value: "-1", label: "전체" },
                  { value: "0", label: "미제출" },
                  { value: "1", label: "제출완료" },
                ]}
              ></Select>
              <Input
                style={{ width: "200px", marginRight: "30px" }}
                placeholder='회사 이름'
                onChange={(e: any) => setSearch(e.target.value)}
                value={search}
                className={"form-input"}
              />
              <Button onClick={showModal} type='primary'>
                추가
              </Button>
            </div>
          </div>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowSelection={rowSelection}
            rowKey='id'
            pagination={{
              onChange(current) {
                setPage(current);
              },

              showSizeChanger: true,
              pageSizeOptions: ["10", "30", "50", "100", "200", "400", "600"],
              defaultPageSize: 600,
            }}
          />
        </Col>
      </Row>
      <Modal
        title={
          <div
            style={{
              width: "100%",
              // cursor: "move",
            }}
          >
            회사 추가하기
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='submit' type='primary' onClick={handleOk}>
            추가하기
          </Button>,
          <Button key='cancel' danger onClick={handleCancel}>
            취소
          </Button>,
        ]}
        // modalRender={(modal) => (
        //   <Draggable
        //     bounds={bounds}
        //     onStart={(event, uiData) => onStart(event, uiData)}
        //   >
        //     <div ref={draggleRef}>{modal}</div>
        //   </Draggable>
        // )}
      >
        <Form
          layout={"vertical"}
          style={{
            padding: "40px",
          }}
          className={styles.form_container}
        >
          <div style={{ marginBottom: "20px" }}>
            <Button disabled={!isFile} onClick={() => setIsFile(false)}>
              TEXT
            </Button>
            <Button
              disabled={isFile}
              style={{ marginLeft: "20px" }}
              onClick={() => setIsFile(true)}
            >
              Excel File
            </Button>
          </div>

          {isFile ? (
            <Form.Item label='업로드 할 엑셀 파일'>
              <Input
                type='file'
                style={{ height: "40px" }}
                accept='.xlsx'
                onChange={(e: any) => setTargetFile(e.target)}
                value={targetFile?.value}
                className={"form-input"}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item label='회사 이름'>
                <Input
                  style={{ height: "40px" }}
                  placeholder='추가할 회사 이름을 입력해 주세요.'
                  onChange={(e: any) =>
                    setForm({ ...form, companyName: e.target.value.trim() })
                  }
                  value={form.companyName}
                  className={"form-input"}
                />
              </Form.Item>
              <Form.Item label='인증 코드'>
                <Input
                  style={{ height: "40px" }}
                  placeholder='추가할 회사의 인증 코드를 입력해 주세요.'
                  onChange={(e: any) =>
                    setForm({ ...form, code: e.target.value.trim() })
                  }
                  value={form.code}
                  className={"form-input"}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default AdminComponent;
