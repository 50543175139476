import Reactm, { useState, useRef, useEffect } from "react";
import AdminComponent from "./AdminComponent";
import styles from "./admin.module.css";
import useRefresh from "../../hooks/useRefresh";

import { Link, useNavigate } from "react-router-dom";
import { DraggableEvent, DraggableData } from "react-draggable";

import { Button, message, notification } from "antd";

import CompanyAPI from "src/helpers/apis/company/company";
import { useDispatch } from "react-redux";
import { login } from "src/store/actions/user";
import VoteAPI from "src/helpers/apis/vote/vote";
import { handleClientDownload, zipDesignFile } from "src/ts/makeExcel";
import { rowZipDesignFile } from "src/ts/rowMakeExcel";
import { setLoading } from "src/store/actions/user";

import ExcelJS from "exceljs";
import _ from "lodash";

import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import UpdateCompany from "./crud/UpdateCompany";
import DeleteCompany from "./crud/DeleteCompany";

const AdminContainer = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [noteapi, contextHolderNote] = notification.useNotification();
  const [isStatus, setIsStatus] = useState("-1");

  const [isFile, setIsFile] = useState(false);

  const [targetFile, setTargetFile] = useState<any>();

  const navigator = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("vote_admin_token");

    if (!token) {
      navigator("/admin/login");
    }
  }, []);

  const [isLogin, setisLogin] = useState(false);
  const [page, setPage] = useState(1);

  const [companyList, setCompanyList] = useState<any>([]);

  ///////
  // update
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [targetUpdate, setTargetUpdate] = useState({
    id: 0,
    companyName: "",
    name: "",
    status: 0,
    code: "",
    rename: "",
  });

  useEffect(() => {
    if (!isUpdateForm) {
      setTargetUpdate({
        id: 0,
        companyName: "",
        name: "",
        status: 0,
        code: "",
        rename: "",
      });
    }
  }, [isUpdateForm]);

  ////

  ////////////

  const [isDeleteForm, setIsDeleteForm] = useState(false);
  const [targetDelete, setTargetDelete] = useState(0);

  useEffect(() => {
    if (!isDeleteForm) {
      setTargetDelete(0);
    }
  }, [isDeleteForm]);
  /////////////

  // 리스트 가져오기
  useEffect(() => {
    const token = localStorage.getItem("vote_admin_token");

    if (token) {
      CompanyAPI.getCompanyList({
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setCompanyList(res);
          dispatch(login(true));
        })
        .catch((err) => {
          if (err.response.status < 500) {
            localStorage.removeItem("vote_admin_token");
            navigator("/admin/login");
            dispatch(login(false));
          } else {
            messageApi.error("관리자에게 문의하세요.");
          }
        });
    }
  }, []);

  useRefresh({ isLogin });

  const dataSource = companyList;

  const columns = [
    {
      title: "",
      align: "center",
      // width: 80,
      render: (text: any, a: any, index: any, c: any) => {
        return <div>{(page - 1) * 10 + index + 1}</div>;
      },
    },
    {
      title: "회사이름",
      align: "center",
      dataIndex: "name",
      render: (name: any, a: any, b: any) => {
        return <div>{name}</div>;
      },
    },
    {
      title: "인증번호",
      align: "center",
      dataIndex: "code",
      render: (code: any, a: any, b: any) => {
        return (
          <span
            className="admin-code"
            onClick={() => {
              // clipboard
              const textarea = document.createElement("textarea");
              document.body.appendChild(textarea);
              textarea.value = code;
              textarea.select();
              document.execCommand("copy");
              document.body.removeChild(textarea);
              message.success("copy succeess");
            }}
          >
            {code}
          </span>
        );
      },
    },
    {
      title: "이동",
      align: "center",
      dataIndex: "companyName",
      render: (companyName: any, a: any, b: any) => {
        return (
          <div>
            <Link to={`/vote/1/${companyName}`}>
              <i className={` ${styles.link} fas fa-external-link-alt`}></i>
            </Link>
          </div>
        );
      },
    },
    {
      title: "상태",
      align: "center",
      dataIndex: "status",
      render: (status: any, a: any, b: any) => {
        return <div>{status === 0 ? "미제출" : "제출 완료"}</div>;
      },
    },

    {
      title: "copy",
      align: "center",
      dataIndex: "copy",
      render: (text: any, item: any) => (
        <i
          onClick={() => {
            handleCopy(item.companyName);
          }}
          className="fas fa-copy"
        ></i>
      ),
    },

    {
      title: "download",
      align: "center",
      dataIndex: "download",
      render: (text: any, item: any) => {
        const { status }: { status: boolean } = item;
        return (
          <Button
            disabled={!status}
            onClick={() => handleDownload(item.companyName, item)}
            style={
              {
                // padding: "0px",
                // height: 0,
                // width: 0,
              }
            }
            shape="circle"
            icon={
              <i
                className={`fas fa-file-download ${
                  status ? "start-download" : ""
                }`}
              ></i>
            }
          ></Button>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "download",
      render: (text: any, item: any) => {
        const { status }: { status: boolean } = item;
        const items: MenuProps["items"] = [
          {
            label: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100px",
                }}
                onClick={() => {
                  setIsUpdateForm(true);
                  setTargetUpdate({ ...item, rename: item.name });
                }}
              >
                <i style={{}} className="fas fa-edit"></i>
                <span>Update</span>
              </div>
            ),
            key: "0",
          },
          {
            label: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() => {
                  setIsDeleteForm(true);
                  setTargetDelete(item.id);
                }}
              >
                <i className="fas fa-trash-alt"></i>
                <span>Delete</span>
              </div>
            ),
            key: "1",
          },
        ];

        return (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <i className="fas fa-ellipsis-v" />
          </Dropdown>
        );
      },
    },
  ];

  // 회사 이름 검색
  const [search, setSearch] = useState("");

  // 추가
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (isFile) {
      const createDataList: any = [];
      if (targetFile && targetFile.files) {
        console.log(targetFile);
        const file = targetFile.files[0];
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
          const buffer: any = reader.result;

          const workbook = await wb.xlsx.load(buffer);
          workbook.eachSheet(async (sheet, id) => {
            if (sheet.name === "Sheet1") {
              const promise = sheet.eachRow((row: any, rowIndex) => {
                if (
                  row.values[2].toString().trim() &&
                  row.values[3].toString().trim() &&
                  rowIndex !== 1
                ) {
                  createDataList.push({
                    companyName: row.values[2].toString(),
                    code: row.values[3].toString(),
                    status: 0,
                  });
                }
              });
            }
          });

          try {
            const res = await CompanyAPI.createCompanyList({ createDataList });
            console.log(res);
            noteapi.open({
              message: <h4>추가완료</h4>,
              key: "create_list",
              description: (
                <div>
                  {!_.isEmpty(res.realList) && <h2>추가된 회사</h2>}
                  {res.realList.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        <span style={{ whiteSpace: "pre-line" }}>
                          {item.name}
                        </span>
                      </div>
                    );
                  })}
                  {!_.isEmpty(res.existList) && (
                    <>
                      <hr /> <h2>추가 되지 않은 회사</h2>
                    </>
                  )}
                  {res.existList.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        <span style={{ whiteSpace: "pre-line" }}>
                          {item.companyName}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ),
            });
            setCompanyList([...companyList, ...res.realList]);
            setTargetFile("");
            setIsModalOpen(false);
          } catch (e) {
            const err: any = e;
            if (err.response.status < 500) {
              localStorage.removeItem("vote_admin_token");
              navigator("/admin/login");
              dispatch(login(false));
            } else {
              messageApi.error("관리자에게 문의하세요.");
            }
          }
        };
      }
    } else {
      try {
        console.log(form);
        const res = await CompanyAPI.createCompany({ ...form, status: 0 });
        console.log(res);
        setCompanyList([...companyList, res]);
        setIsModalOpen(false);
        setForm({
          companyName: "",
          code: "",
        });
      } catch (e: any) {
        console.log(e);
        const err: any = e.response;
        if (err.status === 403) {
          messageApi.info("이미 존재하는 회사입니다.");
        } else if (err.status === 400) {
          messageApi.info("회사이름 또는 인증코드를 입력해주세요.");
        }
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setForm({
      companyName: "",
      code: "",
    });
  };

  const [form, setForm] = useState({
    companyName: "",
    code: "",
  });

  // 모달 이동

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  // 하나만 다운로드
  const handleDownload = (item: any, data: any) => {
    const token = localStorage.getItem("vote_token");
    const adminToken = localStorage.getItem("vote_admin_token");
    dispatch(setLoading(true));

    try {
      VoteAPI.getVote({
        headers: {
          Authorization: token ? token : adminToken,
        },
        params: {
          companyName: item,
        },
      })
        .then(async (res) => {
          await handleClientDownload(res.vote, data.name);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status >= 500) {
            messageApi.error("관리자에게 문의해 주세요.");
          } else if (err.response.status === 403) {
            // alert("접근 권한이 없습니다.");
            dispatch(login(false));
          } else if (err.response.status === 404) {
            alert("잘못된 주소로 접근했습니다.");
          } else if (err.response.status >= 400 && err.response.status < 500) {
            alert("로그인 정보가 만료됐습니다. 다시 로그인 해주세요.");
            dispatch(login(false));

            navigator("/");
          } else {
            alert("다운로드 오류입니다. 관리자에게 문의해 주세요.");
          }
          dispatch(setLoading(false));
        });
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
      navigator("/");
    }
  };

  // clipboard
  const handleCopy = (data: any) => {
    const textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.value = `${window.location.origin}/vote/1/${data}`;
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    message.success("copy succeess");
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectId, setSelectId] = useState<number[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[], items: any) => {
    setSelectId(items.map((item: any) => item.id));
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: !record.status, // Column configuration not to be checked
      name: record.staus,
    }),
  };
  const hasSelected = selectedRowKeys.length > 0;

  // 여러개 다운로드 받기
  const downLoadList = async () => {
    dispatch(setLoading(true));

    const token = localStorage.getItem("vote_token");
    const adminToken = localStorage.getItem("vote_admin_token");

    try {
      const res = await VoteAPI.getVoteList({
        headers: {
          Authorization: token ? token : adminToken,
        },
        params: {
          companyNameList: selectId.join(","),
        },
      });
      await zipDesignFile(res.voteList);
    } catch (e: any) {
      console.log(e);
    }

    dispatch(setLoading(false));
  };

  // 여러개 통계 받기
  const rowDownLoadList = async () => {
    const idList = companyList
      .filter((item: any) => item.status === 1)
      .map((item: any) => item.id);
    dispatch(setLoading(true));

    const token = localStorage.getItem("vote_token");
    const adminToken = localStorage.getItem("vote_admin_token");
    try {
      const res = await VoteAPI.getVoteList({
        headers: {
          Authorization: token ? token : adminToken,
        },
        params: {
          companyNameList: selectId.join(","),
        },
      });
      console.log(res.voteList);
      await rowZipDesignFile(res.voteList);
    } catch (e: any) {
      console.log(e);
    }

    dispatch(setLoading(false));
  };

  // 수정
  const onSubmit = async () => {
    try {
      const token = localStorage.getItem("vote_admin_token");
      console.log(targetUpdate);
      const { company }: any = await CompanyAPI.updateCompany(
        { ...targetUpdate },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setCompanyList(
        companyList.map((item: any) => {
          if (item.id === targetUpdate.id) {
            return {
              ...targetUpdate,
              ...company,
            };
          }
          return item;
        })
      );
      setIsUpdateForm(false);
    } catch (e) {
      const err: any = e;
      if (err.response.status === 400) {
        messageApi.info("입력을 완료해 주세요.");
      } else if (err.response.status < 500) {
        localStorage.removeItem("vote_admin_token");
        navigator("/admin/login");
        dispatch(login(false));
      } else {
        messageApi.error("관리자에게 문의하세요.");
      }
    }
  };

  // 삭제
  const onDeleteSubmit = async () => {
    try {
      const token = localStorage.getItem("vote_admin_token");
      console.log(targetUpdate);
      const { company }: any = await CompanyAPI.deleteCompany({
        data: {
          id: targetDelete,
        },
        config: {
          headers: {
            Authorization: token,
          },
        },
      });

      setCompanyList(
        companyList.filter((item: any) => {
          return item.id !== targetDelete;
        })
      );
      setIsDeleteForm(false);
    } catch (e) {
      const err: any = e;
      if (err.response.status === 400) {
        messageApi.info("입력을 완료해 주세요.");
      } else if (err.response.status < 500) {
        localStorage.removeItem("vote_admin_token");
        navigator("/admin/login");
        dispatch(login(false));
      } else {
        messageApi.error("관리자에게 문의하세요.");
      }
    }
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      {contextHolderNote}
      <AdminComponent
        dataSource={dataSource.filter((item: any) => {
          return (
            item.name.toUpperCase().includes(search.toUpperCase()) &&
            (item.status === Number(isStatus) || Number(isStatus) === -1)
          );
        })}
        columns={columns}
        setPage={setPage}
        setSearch={setSearch}
        search={search}
        isModalOpen={isModalOpen}
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        form={form}
        setForm={setForm}
        bounds={bounds}
        onStart={onStart}
        draggleRef={draggleRef}
        handleDownload={handleDownload}
        rowSelection={rowSelection}
        hasSelected={hasSelected}
        downLoadList={downLoadList}
        isFile={isFile}
        setIsFile={setIsFile}
        targetFile={targetFile}
        setTargetFile={setTargetFile}
        setIsStatus={setIsStatus}
        isStatus={isStatus}
        rowDownLoadList={rowDownLoadList}
      />

      <UpdateCompany
        isModalOpen={isUpdateForm}
        handleOk={() => setIsUpdateForm(false)}
        handleCancel={() => setIsUpdateForm(false)}
        form={targetUpdate}
        setForm={setTargetUpdate}
        bounds={bounds}
        onStart={onStart}
        draggleRef={draggleRef}
        onSubmit={onSubmit}
      />

      <DeleteCompany
        isModalOpen={isDeleteForm}
        handleOk={() => setIsDeleteForm(false)}
        handleCancel={() => setIsDeleteForm(false)}
        form={targetDelete}
        setForm={setTargetDelete}
        bounds={bounds}
        onStart={onStart}
        draggleRef={draggleRef}
        onSubmit={onDeleteSubmit}
        companyName={_.find(companyList, { id: targetDelete })}
      />
    </div>
  );
};

export default AdminContainer;
