import React from "react";

const NinePageBlock = ({ eightNumber }: any) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "23%",
          zIndex: 1000,
        }}
      >
        {eightNumber === false ? (
          <div>
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 20px",
              }}
            >
              8페이지 작성 후 이용해 주세요. <br />
            </h1>
          </div>
        ) : (
          <div>
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 20px",
              }}
            >
              [제품개발]에 해당하는 질문입니다. <br />
            </h1>
            <h3
              style={{
                position: "absolute",
                top: "100%",
                left: "10%",
              }}
            >
              11페이지를 작성하셨다면 13페이지로 이동해주세요.
            </h3>
          </div>
        )}
      </div>
      <div
        className='hpa'
        style={{ width: "210mm", height: "297mm", opacity: 0.3 }}
      >
        <div
          className='hpN'
          style={{
            left: "99.27mm",
            top: "282mm",
            width: "11.46mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 9 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "1.25mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>
                Ⅴ-1. Outcome (결과/제품개발)&nbsp;
              </span>
            </div>
            <div
              className='hls ps7'
              style={{
                lineHeight: "8.93mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "10.30mm",
                height: "9.35mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.98mm",
                  top: "1mm",
                  height: "9.35mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.98 14.35'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "155.98mm",
                    height: "14.35mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_80'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(255,255,0)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_80)'
                    d='M0,0L148.98,0L148.98,7.36L0,7.36L0,0Z '
                  />
                  <path
                    d='M0,0 L0,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.98,0 L148.98,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L149.04,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,7.36 L149.04,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.98,0 L148.98,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,7.36 L149.04,7.36'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L149.04,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.98mm",
                    height: "7.36mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.50mm" }}>
                      <div
                        className='hls ps49'
                        style={{
                          lineHeight: "3.10mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "1.29mm",
                          height: "3.88mm",
                          width: "145.37mm",
                        }}
                      >
                        <span className='hrt cs65'>
                          A.「제품개발」에 해당하는 경우, 응답
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "21.79mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                1. 본 융자사업과제(제품개발)로 발생한 매출
              </span>
              <span className='hrt cs152'>(</span>
              <span className='hrt cs9'>사업화 매출액</span>
              <span className='hrt cs152'>)</span>
              <span className='hrt cs1'>에 대한 질문입니다.&nbsp;</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "28.39mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;귀사는 사업화매출액이 정확하게 파악되십니까?
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "35mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;&nbsp;① 사업화매출액이 정확하게 파악된다.
                (1-1문항으로 이동)
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "41.60mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                &nbsp;&nbsp;&nbsp;② 사업화매출액이 모호하다. (1-2문항으로 이동)
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "48.21mm",
                height: "3.70mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs8'>
                1-1. 「제품개발」에 해당하는 경우, 귀사의 총매출액과&nbsp;
              </span>
              <span className='hrt cs12'>본 융자사업과제로 발생한 매출액</span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "54.50mm",
                height: "3.70mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs12'>(사업화 매출액)</span>
              <span className='hrt cs8'>
                에 대하여 응답하여 주십시오.&nbsp;
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "12.52mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "60.97mm",
                height: "12.52mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "12.52mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.01 17.52'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "155.01mm",
                    height: "17.52mm",
                  }}
                >
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "10.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※예: 2017년 선정기업은 2018년부터 기입, 2018년
                          선정기업은 2019년부터 기입
                        </span>
                      </div>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※2022년 선정기업은 2022년만 기입
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps36'
              style={{
                lineHeight: "37.18mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "76.21mm",
                height: "37.18mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "149.78mm",
                  top: "1mm",
                  height: "37.18mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.77 42.18'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "154.77mm",
                    height: "42.18mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_81'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_81)'
                    d='M0,0L23.65,0L23.65,12.57L0,12.57L0,0Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M23.65,0L82.77,0L82.77,8.05L23.65,8.05L23.65,0Z '
                  />
                  <defs>
                    <pattern
                      id='w_82'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_82)'
                    d='M82.77,0L147.78,0L147.78,8.05L82.77,8.05L82.77,0Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M23.65,8.05L55.18,8.05L55.18,12.57L23.65,12.57L23.65,8.05Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M55.18,8.05L82.77,8.05L82.77,12.57L55.18,12.57L55.18,8.05Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M82.77,8.05L119.22,8.05L119.22,12.57L82.77,12.57L82.77,8.05Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M119.22,8.05L147.78,8.05L147.78,12.57L119.22,12.57L119.22,8.05Z '
                  />
                  <path
                    d='M0,0 L0,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M23.65,0 L23.65,17.10'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,12.57 L23.65,17.10'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,17.10 L23.65,21.62'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,17.10 L23.65,21.62'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,21.62 L23.65,26.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,21.62 L23.65,26.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,26.14 L23.65,30.67'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,26.14 L23.65,30.67'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M23.65,30.66 L23.65,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.65,30.66 L23.65,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.18,8.05 L55.18,12.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.18,12.57 L55.18,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M82.77,0 L82.77,12.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M82.77,12.57 L82.77,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M119.22,8.05 L119.22,12.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M119.22,12.57 L119.22,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M147.78,0 L147.78,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M23.59,8.05 L148.03,8.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.25,12.57 L55.24,12.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,12.57 L55.26,12.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M55.12,12.57 L82.84,12.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M55.10,12.57 L148.03,12.57'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,17.10 L23.71,17.10'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,17.10 L148.03,17.10'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,21.62 L23.71,21.62'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,21.62 L148.03,21.62'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,26.14 L23.71,26.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,26.14 L148.03,26.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,30.66 L23.71,30.66'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.57,30.66 L148.03,30.66'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,35.19 L148.03,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M147.78,0 L147.78,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M0,0 L0,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,35.19 L148.03,35.19'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L148.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "23.65mm",
                    height: "12.57mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "4.03mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs129'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "0mm",
                    width: "59.13mm",
                    height: "8.05mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "1.76mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "58.12mm",
                        }}
                      >
                        <span className='hrt cs129'>기업 총 매출액</span>
                        <span className='hrt cs130'>1)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "0mm",
                    width: "65.01mm",
                    height: "8.05mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "64.01mm",
                        }}
                      >
                        <span className='hrt cs132'>
                          융자사업과제로 인한 매출액
                        </span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "64.01mm",
                        }}
                      >
                        <span className='hrt cs132'>(사</span>
                        <span className='hrt cs129'>업화 매출액</span>
                        <span className='hrt cs130'>2)</span>
                        <span className='hrt cs132'>)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "8.05mm",
                    width: "31.53mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <span className='hrt cs129'>국내(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "8.05mm",
                    width: "27.59mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      >
                        <span className='hrt cs129'>수출(백만원)</span>
                        <span className='hrt cs130'>3)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "8.05mm",
                    width: "36.45mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      >
                        <span className='hrt cs129'>국내(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "8.05mm",
                    width: "28.55mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      >
                        <span className='hrt cs129'>수출(백만원)</span>
                        <span className='hrt cs130'>3)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "12.57mm",
                    width: "23.65mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "12.57mm",
                    width: "31.53mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "12.57mm",
                    width: "27.59mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "12.57mm",
                    width: "36.45mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "12.57mm",
                    width: "28.55mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "17.10mm",
                    width: "23.65mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "17.10mm",
                    width: "31.53mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "17.10mm",
                    width: "27.59mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "17.10mm",
                    width: "36.45mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "17.10mm",
                    width: "28.55mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "21.62mm",
                    width: "23.65mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "21.62mm",
                    width: "31.53mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "21.62mm",
                    width: "27.59mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "21.62mm",
                    width: "36.45mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "21.62mm",
                    width: "28.55mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "26.14mm",
                    width: "23.65mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "26.14mm",
                    width: "31.53mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "26.14mm",
                    width: "27.59mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "26.14mm",
                    width: "36.45mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "26.14mm",
                    width: "28.55mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "30.66mm",
                    width: "23.65mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.65mm",
                    top: "30.66mm",
                    width: "31.53mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "55.18mm",
                    top: "30.66mm",
                    width: "27.59mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "82.77mm",
                    top: "30.66mm",
                    width: "36.45mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.45mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "119.22mm",
                    top: "30.66mm",
                    width: "28.55mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.56mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "115.96mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                1) 기업 총 매출액: 당해년도 발생한 총 매출액을 의미.
                융자사업과제 매출을 포함한 총 매출액을 기입.
              </span>
            </div>
            <div
              className='hls ps89'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "119.87mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                2) 사업화 매출액: 기술과제를 통해 개발한 새로운 제품의 판매로
                인해 유발된 매출액 또는 기술과제를 통해 개발한 기술을&nbsp;
              </span>
            </div>
            <div
              className='hls ps89'
              style={{
                paddingLeft: "3.54mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "123.77mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                활용하여 기존에 판매하던 제품의 기능 강화 또는 가격 인하로 인해
                발생한 매출증가액(객관적인 계산이 어려운 경우에는&nbsp;
              </span>
            </div>
            <div
              className='hls ps89'
              style={{
                paddingLeft: "3.54mm",
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "127.67mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>가급적 정확하게 추정하여 기입)</span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "131.57mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                3) 외화표시(US$, ￥ 등)로 되어 있을 경우, 발생시점 기준의 환율을
                추정하여 원화로 환산한 값을 기재 요망.&nbsp;
              </span>
            </div>
            <div
              className='hls ps67'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "135.44mm",
                height: "3.70mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "141.72mm",
                height: "3.70mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>1-2. 위 1-1문항에서&nbsp;</span>
              <span className='hrt cs12'>
                사업화 매출액을 정확하게 파악할 수 없는 경우에 기입
              </span>
              <span className='hrt cs8'>해 주시기 바</span>
            </div>
            <div
              className='hls ps5'
              style={{
                paddingLeft: "7.06mm",
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "148.01mm",
                height: "3.70mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>랍니다.</span>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "12.52mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "154.48mm",
                height: "12.52mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.01mm",
                  top: "1mm",
                  height: "12.52mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.01 17.52'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "155.01mm",
                    height: "17.52mm",
                  }}
                >
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148.01,0 L148.01,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0,0 L0,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,10.52 L148.07,10.52'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.06,0 L148.07,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.01mm",
                    height: "10.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "1.80mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※예: 2017년 선정기업은 2018년부터 기입, 2018년
                          선정기업은 2019년부터 기입
                        </span>
                      </div>
                      <div
                        className='hls ps0'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "5.82mm",
                          height: "3.53mm",
                          width: "144.41mm",
                        }}
                      >
                        <span className='hrt cs209'>
                          ※2022년 선정기업은 2022년만 기입
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps112'
              style={{
                lineHeight: "39.06mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "169.72mm",
                height: "39.06mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "150.77mm",
                  top: "1mm",
                  height: "39.06mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 155.77 44.06'
                  style={{
                    left: "-2.50mm",
                    top: "-2.50mm",
                    width: "155.77mm",
                    height: "44.06mm",
                  }}
                >
                  <path
                    fill='url(#w_81)'
                    d='M0,0L24.65,0L24.65,11.87L0,11.87L0,0Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M24.65,0L83.77,0L83.77,7.34L24.65,7.34L24.65,0Z '
                  />
                  <path
                    fill='url(#w_82)'
                    d='M83.77,0L148.78,0L148.78,7.34L83.77,7.34L83.77,0Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M24.65,7.34L56.18,7.34L56.18,11.87L24.65,11.87L24.65,7.34Z '
                  />
                  <path
                    fill='url(#w_81)'
                    d='M56.18,7.34L83.77,7.34L83.77,11.87L56.18,11.87L56.18,7.34Z '
                  />
                  <path
                    fill='url(#w_82)'
                    d='M83.77,7.34L116.27,7.34L116.27,11.87L83.77,11.87L83.77,7.34Z '
                  />
                  <path
                    fill='url(#w_82)'
                    d='M116.27,7.34L148.78,7.34L148.78,11.87L116.27,11.87L116.27,7.34Z '
                  />
                  <path
                    d='M0,0 L0,37.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M24.65,0 L24.65,16.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,11.87 L24.65,16.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,16.39 L24.65,21.56'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,16.39 L24.65,21.56'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,21.56 L24.65,26.73'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,21.56 L24.65,26.73'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,26.73 L24.65,31.90'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,26.73 L24.65,31.90'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M24.65,31.89 L24.65,37.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.65,31.89 L24.65,37.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M56.18,7.34 L56.18,11.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M56.18,11.87 L56.18,37.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M83.77,0 L83.77,11.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M83.77,11.87 L83.77,37.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M116.27,7.34 L116.27,37.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.10",
                    }}
                  />
                  <path
                    d='M148.78,0 L148.78,37.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L149.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M24.59,7.34 L149.03,7.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.25,11.87 L56.24,11.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,11.87 L56.25,11.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M56.12,11.87 L83.83,11.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M56.10,11.87 L149.03,11.87'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,16.39 L24.71,16.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,16.39 L149.03,16.39'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,21.56 L24.71,21.56'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,21.56 L149.03,21.56'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,26.73 L24.71,26.73'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,26.73 L149.03,26.73'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,31.89 L24.71,31.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.57,31.89 L149.03,31.89'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,37.06 L149.03,37.06'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M148.78,0 L148.78,37.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M0,0 L0,37.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,37.06 L149.03,37.06'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                  <path
                    d='M-0.25,0 L149.03,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.50",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "24.65mm",
                    height: "11.87mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "3.67mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs129'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "0mm",
                    width: "59.13mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "1.41mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "58.12mm",
                        }}
                      >
                        <span className='hrt cs129'>기업 총 매출액</span>
                        <span className='hrt cs130'>1)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "0mm",
                    width: "65.01mm",
                    height: "7.34mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "1.41mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "64.01mm",
                        }}
                      >
                        <span className='hrt cs129'>
                          융자사업과제의 매출액 기여율
                        </span>
                        <span className='hrt cs130'>3)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "7.34mm",
                    width: "31.53mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      >
                        <span className='hrt cs129'>국내(백만원)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "7.34mm",
                    width: "27.59mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      >
                        <span className='hrt cs129'>수출(백만원)</span>
                        <span className='hrt cs130'>2)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "7.34mm",
                    width: "32.50mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.50mm",
                        }}
                      >
                        <span className='hrt cs129'>국내 매출액 기여율</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "7.34mm",
                    width: "32.50mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      >
                        <span className='hrt cs129'>수출액 기여율</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "11.87mm",
                    width: "24.65mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2018년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "11.87mm",
                    width: "31.53mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "11.87mm",
                    width: "27.59mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "11.87mm",
                    width: "32.50mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.50mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "11.87mm",
                    width: "32.50mm",
                    height: "4.52mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI'>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "16.39mm",
                    width: "24.65mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2019년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "16.39mm",
                    width: "31.53mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "16.39mm",
                    width: "27.59mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "16.39mm",
                    width: "32.50mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.50mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "16.39mm",
                    width: "32.50mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "21.56mm",
                    width: "24.65mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2020년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "21.56mm",
                    width: "31.53mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "21.56mm",
                    width: "27.59mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "21.56mm",
                    width: "32.50mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.50mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "21.56mm",
                    width: "32.50mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "26.73mm",
                    width: "24.65mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2021년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "26.73mm",
                    width: "31.53mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "26.73mm",
                    width: "27.59mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "26.73mm",
                    width: "32.50mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.50mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "26.73mm",
                    width: "32.50mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "31.89mm",
                    width: "24.65mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.65mm",
                        }}
                      >
                        <span className='hrt cs131'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.65mm",
                    top: "31.89mm",
                    width: "31.53mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "30.54mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.18mm",
                    top: "31.89mm",
                    width: "27.59mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.60mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "83.77mm",
                    top: "31.89mm",
                    width: "32.50mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.50mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "116.27mm",
                    top: "31.89mm",
                    width: "32.50mm",
                    height: "5.17mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.50mm", top: "0.50mm" }}
                  >
                    <div className='hcI' style={{ top: "0.32mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "31.51mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "211.21mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                1) 기업 총 매출액: 당해년도 발생한 총 매출액을 의미.
                융자사업과제 매출을 포함한 총 매출액을 기입.
              </span>
            </div>
            <div
              className='hls ps88'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "215.12mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs13'>
                2) 융자사업과제의 매출액 기여율: 매출액에 대해 융자사업과제가
                기여한 정도를 의미(0~100%).
              </span>
            </div>
            <div
              className='hls ps88'
              style={{
                lineHeight: "2.48mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "219.01mm",
                height: "3.17mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs179'>※&nbsp;</span>
              <span className='hrt cs180'>
                융자사업과제를 통해 개발된 기술, 구축된 시스템, 기술장비 등이
                제품 생산(제공)에&nbsp;
              </span>
              <span className='hrt cs181'>필수요소</span>
              <span className='hrt cs180'>인 경우, 100%로 기입.</span>
            </div>
            <div
              className='hls ps8'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "223.15mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                3) 외화표시(US$, ￥ 등)로 되어 있을 경우, 발생시점 기준의 환율을
                추정하여 원화로 환산한 값을 기재 요망.&nbsp;
              </span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "227.02mm",
                height: "3.70mm",
                width: "146.48mm",
              }}
            />
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.10mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "233.30mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NinePageBlock;
