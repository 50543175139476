import React from "react";
import { useEffect } from "react";
import _ from "lodash";
import VoteAPI from "src/helpers/apis/vote/vote";
import { login } from "src/store/actions/user";
import { useDispatch } from "react-redux";

const useUnitSave = ({
  unitSave,
  companyName,
  vote,
  messageApi,
  setUnitSave,
  setServerUpdate,
  setisUpdate,
}: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (unitSave) {
      console.log("저장");
      VoteAPI.updateVote({
        companyName,
        vote,
      })
        .then((res) => {
          messageApi.info("저장되었습니다.");
          setUnitSave(false);
          setServerUpdate(false);
          setisUpdate(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status < 500) {
            dispatch(login(false));
            messageApi.info(
              "로그인 정보가 만료 됐습니다. 다시 로그인 해 주세요."
            );
            const token = localStorage.getItem("vote_token");
          } else {
            messageApi.error("관리자에게 문의해 주세요.");
          }
        });
    }
  }, [unitSave]);

  return null;
};

export default useUnitSave;
