import axios from "axios";

export const axiosApi = axios.create({
  headers: {
    Authorization: localStorage.getItem("vote_token"),
  },
});

export const get = async (url: string, config: any) => {
  const res = await axiosApi.get(url, { ...config });
  return res.data;
};

export const post = async (url: string, data: any, config = {}) => {
  const res = await axiosApi.post(url, data, { ...config });
  return res.data;
};

export const put = async (url: string, data: any, config = {}) => {
  const res = await axiosApi.put(url, data, { ...config });
  return res.data;
};

export const deleteAxios = async (url: string, config = {}) => {
  const res = await axiosApi.delete(url, { ...config });
  return res.data;
};

axiosApi.interceptors.request.use(
  (config: any) => {
    // 요청이 전달되기 전에 작업 수행
    const token = localStorage.getItem("vote_token");
    const adminToken = localStorage.getItem("vote_admin_token");
    config.headers["Authorization"] = adminToken ? adminToken : token;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가하기
axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    // 요청 오류가 있는 작업 수행

    try {
      if (err.response.data.err !== "exist") {
        if (err.response.status === 403 || err.response.status === 401) {
          const token = localStorage.getItem("vote_token");
          localStorage.removeItem("vote_token");
          if (!token) {
            localStorage.removeItem("vote_admin_token");
          }
          window.location.replace("/test");
        }
      }
    } catch (e) {
      console.log(e);
    }

    return Promise.reject(err);
  }
);
