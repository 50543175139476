import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";

import { EightPageTypes } from "gita";
import _ from "lodash";

interface eightPageComponentProps {
  eightPageData: EightPageTypes;
  setEightPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const EightPageComponent = ({
  eightPageData,
  setEightPageData,
  setisUpdate,
}: eightPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          className='hpN'
          style={{
            left: "99.27mm",
            top: "282mm",
            width: "11.46mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 8 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "-0.19mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                2. 다음은 융자사업과제를 통한&nbsp;
              </span>
              <span className='hrt cs9'>기술개발 활용분야</span>
              <span className='hrt cs1'>에 대한 질문입니다.&nbsp;</span>
            </div>
            <div
              className='hls ps45'
              style={{
                lineHeight: "0.5mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "8.05mm",
                height: "0.71mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps85'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "9.1mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs95'>
                (1) 본 융자사업과제를 통해 발생된 최종산출물의 형태는 무엇인지
                항목에 체크(○)해 주십시오.
              </span>
            </div>
            <div
              className='hls ps45'
              style={{
                lineHeight: "23.48mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "17.05mm",
                height: "23.48mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.49mm",
                  width: "148.97mm",
                  top: "0.49mm",
                  height: "23.48mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.97 28.48'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.97mm",
                    height: "28.48mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_70'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_70)'
                    d='M0,0L24.51,0L24.51,6.17L0,6.17L0,0Z '
                  />
                  <path
                    fill='url(#w_70)'
                    d='M24.51,0L93.53,0L93.53,6.17L24.51,6.17L24.51,0Z '
                  />
                  <path
                    fill='url(#w_70)'
                    d='M93.53,0L147.98,0L147.98,6.17L93.53,6.17L93.53,0Z '
                  />
                  <path
                    d='M0,0 L0,22.50'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M24.51,0 L24.51,22.50'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M93.53,0 L93.53,22.50'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M147.98,0 L147.98,22.50'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L148.18,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,6.17 L148.18,6.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,14.33 L148.18,14.33'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,22.49 L148.18,22.49'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.98,0 L147.98,22.50'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,22.50'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,22.49 L148.18,22.49'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L148.18,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "24.51mm",
                    height: "6.17mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.91mm",
                        }}
                      >
                        <span className='hrt cs128'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.51mm",
                    top: "0mm",
                    width: "69.02mm",
                    height: "6.17mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "65.42mm",
                        }}
                      >
                        <span className='hrt cs128'>내용</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "93.53mm",
                    top: "0mm",
                    width: "54.45mm",
                    height: "6.17mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.84mm",
                        }}
                      >
                        <span className='hrt cs128'>항목</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "6.17mm",
                    width: "24.51mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps50'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.91mm",
                        }}
                      >
                        <span className='hrt cs27'>A.&nbsp;</span>
                        <span className='hrt cs28'>제품개발</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.51mm",
                    top: "6.17mm",
                    width: "69.02mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "65.42mm",
                        }}
                      >
                        <span className='hrt cs128'>
                          센서, 저장장치 등 제품
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "93.53mm",
                    top: "6.17mm",
                    width: "54.45mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.84mm",
                        }}
                      >
                        <span className='hrt cs122'>
                          <label htmlFor='page8_2'>
                            <input
                              type='radio'
                              onChange={() => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  two: "1",
                                });
                              }}
                              checked={eightPageData.two === "1"}
                              name='aorb'
                              id='page8_2'
                            />{" "}
                            ☞ (1)-A 문항으로 이동
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "14.33mm",
                    width: "24.51mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps50'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.91mm",
                        }}
                      >
                        <span className='hrt cs27'>B.&nbsp;</span>
                        <span className='hrt cs28'>서비스개발</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.51mm",
                    top: "14.33mm",
                    width: "69.02mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "65.42mm",
                        }}
                      >
                        <span
                          className='hrt cs128'
                          style={{ fontSize: "0.73rem" }}
                        >
                          플랫폼, 모바일App, 솔루션, 게임, 시스템 등 서비스
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "93.53mm",
                    top: "14.33mm",
                    width: "54.45mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.84mm",
                        }}
                      >
                        <span className='hrt cs122'>
                          <label htmlFor='page8_2b'>
                            <input
                              type='radio'
                              onChange={() => {
                                setisUpdate(true);
                                setEightPageData({
                                  ...eightPageData,
                                  two: "2",
                                });
                              }}
                              checked={eightPageData.two === "2"}
                              name='aorb'
                              id='page8_2b'
                            />{" "}
                            ☞ (1)-B 문항으로 이동
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps45'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "44.55mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs92'>&nbsp;</span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "51.16mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>(1)-A.</span>
              <span className='hrt cs12'>「제품개발」에 해당하는 경우</span>
              <span className='hrt cs8'>
                , 다음 중 해당란에 표시하여 주시기 바랍니다.&nbsp;
              </span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "57.44mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(※과제 신청시점과
                현재시점(2022년)의 사업화 추진단계를 각각 판단하여 기재)
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "66.3mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "63.92mm",
                height: "66.3mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "149.99mm",
                  top: "1mm",
                  height: "66.3mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.99 71.30'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.99mm",
                    height: "71.3mm",
                  }}
                >
                  <path
                    fill='url(#w_70)'
                    d='M0,0L56.32,0L56.32,7.16L0,7.16L0,0Z '
                  />
                  <path
                    fill='url(#w_70)'
                    d='M56.32,0L110.65,0L110.65,7.16L56.32,7.16L56.32,0Z '
                  />
                  <path
                    fill='url(#w_70)'
                    d='M110.65,0L148,0L148,7.16L110.65,7.16L110.65,0Z '
                  />
                  <path
                    d='M0,0 L0,64.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M56.32,0 L56.32,64.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M110.65,0 L110.65,64.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148,0 L148,64.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L148.20,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,7.16 L148.20,7.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,15.33 L148.20,15.33'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,23.49 L148.20,23.49'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,31.65 L148.20,31.65'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,39.82 L148.20,39.82'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,47.98 L148.20,47.98'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,56.14 L148.20,56.14'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,64.31 L148.20,64.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M148,0 L148,64.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,64.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,64.31 L148.20,64.31'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L148.20,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "56.32mm",
                    height: "7.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>사업화 추진단계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "0mm",
                    width: "54.32mm",
                    height: "7.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs126'>과제 신청시점</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "0mm",
                    width: "37.35mm",
                    height: "7.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs126'>현재시점 (2022년)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "7.16mm",
                    width: "56.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>기술기획</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "7.16mm",
                    width: "54.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);

                              let two = eightPageData.twoA.two;
                              if (
                                eightPageData.twoA.two &&
                                Number(eightPageData.twoA.two) < 1
                              ) {
                                two = false;
                              }

                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  one: "1",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoA.one === "1"}
                            disabled={eightPageData.two !== "1"}
                            name='1_A_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "7.16mm",
                    width: "37.35mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  two: "1",
                                },
                              });
                            }}
                            disabled={
                              eightPageData.two !== "1" ||
                              !eightPageData.twoA.one ||
                              Number(eightPageData.twoA.one) > 1
                            }
                            checked={eightPageData.twoA.two === "1"}
                            name='1_A_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "15.33mm",
                    width: "56.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>기술개발단계&nbsp;</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "15.33mm",
                    width: "54.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoA.two;
                              if (
                                eightPageData.twoA.two &&
                                Number(eightPageData.twoA.two) < 2
                              ) {
                                two = false;
                              }
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  one: "2",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoA.one === "2"}
                            disabled={eightPageData.two !== "1"}
                            name='1_A_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "15.33mm",
                    width: "37.35mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  two: "2",
                                },
                              });
                            }}
                            disabled={
                              eightPageData.two !== "1" ||
                              !eightPageData.twoA.one ||
                              Number(eightPageData.twoA.one) > 2
                            }
                            checked={eightPageData.twoA.two === "2"}
                            name='1_A_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "23.49mm",
                    width: "56.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>기술개발완료</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "23.49mm",
                    width: "54.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoA.two;
                              if (
                                eightPageData.twoA.two &&
                                Number(eightPageData.twoA.two) < 3
                              ) {
                                two = false;
                              }
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  one: "3",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoA.one === "3"}
                            disabled={eightPageData.two !== "1"}
                            name='1_A_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "23.49mm",
                    width: "37.35mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  two: "3",
                                },
                              });
                            }}
                            disabled={
                              eightPageData.two !== "1" ||
                              !eightPageData.twoA.one ||
                              Number(eightPageData.twoA.one) > 3
                            }
                            checked={eightPageData.twoA.two === "3"}
                            name='1_A_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "31.65mm",
                    width: "56.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>시제품 제작</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "31.65mm",
                    width: "54.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoA.two;
                              if (
                                eightPageData.twoA.two &&
                                Number(eightPageData.twoA.two) < 4
                              ) {
                                two = false;
                              }
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  one: "4",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoA.one === "4"}
                            disabled={eightPageData.two !== "1"}
                            name='1_A_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "31.65mm",
                    width: "37.35mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  two: "4",
                                },
                              });
                            }}
                            disabled={
                              eightPageData.two !== "1" ||
                              !eightPageData.twoA.one ||
                              Number(eightPageData.twoA.one) > 4
                            }
                            checked={eightPageData.twoA.two === "4"}
                            name='1_A_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "39.82mm",
                    width: "56.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>제품화</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "39.82mm",
                    width: "54.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoA.two;
                              if (
                                eightPageData.twoA.two &&
                                Number(eightPageData.twoA.two) < 5
                              ) {
                                two = false;
                              }
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  one: "5",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoA.one === "5"}
                            disabled={eightPageData.two !== "1"}
                            name='1_A_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "39.82mm",
                    width: "37.35mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  two: "5",
                                },
                              });
                            }}
                            disabled={
                              eightPageData.two !== "1" ||
                              !eightPageData.twoA.one ||
                              Number(eightPageData.twoA.one) > 5
                            }
                            checked={eightPageData.twoA.two === "5"}
                            name='1_A_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "47.98mm",
                    width: "56.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>양산착수</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "47.98mm",
                    width: "54.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoA.two;
                              if (
                                eightPageData.twoA.two &&
                                Number(eightPageData.twoA.two) < 6
                              ) {
                                two = false;
                              }
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  one: "6",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoA.one === "6"}
                            disabled={eightPageData.two !== "1"}
                            name='1_A_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "47.98mm",
                    width: "37.35mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  two: "6",
                                },
                              });
                            }}
                            disabled={
                              eightPageData.two !== "1" ||
                              !eightPageData.twoA.one ||
                              Number(eightPageData.twoA.one) > 6
                            }
                            checked={eightPageData.twoA.two === "6"}
                            name='1_A_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "56.14mm",
                    width: "56.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>판매</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "56.14mm",
                    width: "54.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoA.two;
                              if (
                                eightPageData.twoA.two &&
                                Number(eightPageData.twoA.two) < 7
                              ) {
                                two = false;
                              }
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  one: "7",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoA.one === "7"}
                            disabled={eightPageData.two !== "1"}
                            name='1_A_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "56.14mm",
                    width: "37.35mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoA: {
                                  ...eightPageData.twoA,
                                  two: "7",
                                },
                              });
                            }}
                            disabled={
                              eightPageData.two !== "1" ||
                              !eightPageData.twoA.one ||
                              Number(eightPageData.twoA.one) > 7
                            }
                            checked={eightPageData.twoA.two === "7"}
                            name='1_A_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps45'
              style={{
                lineHeight: "3.43mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "134.21mm",
                height: "4.23mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs120'>&nbsp;►</span>
              <span className='hrt cs121'>
                최종산출물이 제품개발인 경우, 9페이지 Ⅴ-1 Outcome
              </span>
              <span className='hrt cs120'>&nbsp;(결과/제품개발)로 이동</span>
            </div>
            <div
              className='hls ps45'
              style={{
                lineHeight: "2.48mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "142.95mm",
                height: "3.17mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "148.32mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>(1)-B.&nbsp;</span>
              <span className='hrt cs124'>「서비스개발」에 해당하는 경우</span>
              <span className='hrt cs8'>
                , 다음 중 해당란에 표시하여 주시기 바랍니다.&nbsp;
              </span>
            </div>
            <div
              className='hls ps5'
              style={{
                lineHeight: "2.94mm",
                whiteSpace: "nowrap",
                left: "3.52mm",
                top: "154.61mm",
                height: "3.7mm",
                width: "146.48mm",
              }}
            >
              <span className='hrt cs8'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(※과제신청년도와
                현재(2022년)의 사업화 추진단계를 각각 판단하여 기재)
              </span>
            </div>
            <div
              className='hls ps45'
              style={{
                lineHeight: "55.97mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "162.56mm",
                height: "55.97mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "149.99mm",
                  top: "1mm",
                  height: "55.97mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.99 60.97'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.99mm",
                    height: "60.97mm",
                  }}
                >
                  <path
                    fill='url(#w_70)'
                    d='M0,0L56.32,0L56.32,8.16L0,8.16L0,0Z '
                  />
                  <path
                    fill='url(#w_70)'
                    d='M56.32,0L110.65,0L110.65,8.16L56.32,8.16L56.32,0Z '
                  />
                  <path
                    fill='url(#w_70)'
                    d='M110.65,0L148,0L148,8.16L110.65,8.16L110.65,0Z '
                  />
                  <path
                    d='M0,0 L0,53.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M56.32,0 L56.32,53.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M110.65,0 L110.65,53.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M148,0 L148,53.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L148.20,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,8.16 L148.20,8.16'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,17.32 L148.20,17.32'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,26.49 L148.20,26.49'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,35.65 L148.20,35.65'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,44.81 L148.20,44.81'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,53.97 L148.20,53.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M148,0 L148,53.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,53.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,53.97 L148.20,53.97'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L148.20,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "56.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>사업화 추진단계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "0mm",
                    width: "54.32mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs126'>과제 신청연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "0mm",
                    width: "37.35mm",
                    height: "8.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.82mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs126'>현재(2022년) 단계</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "8.16mm",
                    width: "56.32mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>기술기획</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "8.16mm",
                    width: "54.32mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.14mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoB.two;
                              if (
                                eightPageData.twoB.two &&
                                Number(eightPageData.twoB.two) < 1
                              ) {
                                two = false;
                              }

                              setEightPageData({
                                ...eightPageData,
                                twoB: {
                                  ...eightPageData.twoB,
                                  one: "1",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoB.one === "1"}
                            disabled={eightPageData.two !== "2"}
                            name='1_B_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "8.16mm",
                    width: "37.35mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.14mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);

                              setEightPageData({
                                ...eightPageData,
                                twoB: {
                                  ...eightPageData.twoB,
                                  two: "1",
                                },
                              });
                            }}
                            checked={eightPageData.twoB.two === "1"}
                            disabled={
                              eightPageData.two !== "2" ||
                              !eightPageData.twoB.one ||
                              Number(eightPageData.twoB.one) > 1
                            }
                            name='1_B_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "17.32mm",
                    width: "56.32mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>기술개발단계&nbsp;</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "17.32mm",
                    width: "54.32mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.14mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoB.two;
                              if (
                                eightPageData.twoB.two &&
                                Number(eightPageData.twoB.two) < 2
                              ) {
                                two = false;
                              }
                              setEightPageData({
                                ...eightPageData,
                                twoB: {
                                  ...eightPageData.twoB,
                                  one: "2",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoB.one === "2"}
                            disabled={eightPageData.two !== "2"}
                            name='1_B_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "17.32mm",
                    width: "37.35mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.14mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoB: {
                                  ...eightPageData.twoB,
                                  two: "2",
                                },
                              });
                            }}
                            checked={eightPageData.twoB.two === "2"}
                            disabled={
                              eightPageData.two !== "2" ||
                              !eightPageData.twoB.one ||
                              Number(eightPageData.twoB.one) > 2
                            }
                            name='1_B_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "26.49mm",
                    width: "56.32mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>기술개발완료</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "26.49mm",
                    width: "54.32mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.14mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoB.two;
                              if (
                                eightPageData.twoB.two &&
                                Number(eightPageData.twoB.two) < 3
                              ) {
                                two = false;
                              }
                              setEightPageData({
                                ...eightPageData,
                                twoB: {
                                  ...eightPageData.twoB,
                                  one: "3",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoB.one === "3"}
                            disabled={eightPageData.two !== "2"}
                            name='1_B_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "26.49mm",
                    width: "37.35mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.14mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoB: {
                                  ...eightPageData.twoB,
                                  two: "3",
                                },
                              });
                            }}
                            checked={eightPageData.twoB.two === "3"}
                            disabled={
                              eightPageData.two !== "2" ||
                              !eightPageData.twoB.one ||
                              Number(eightPageData.twoB.one) > 3
                            }
                            name='1_B_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "35.65mm",
                    width: "56.32mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>서비스 오픈</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "35.65mm",
                    width: "54.32mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.14mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoB.two;
                              if (
                                eightPageData.twoB.two &&
                                Number(eightPageData.twoB.two) < 4
                              ) {
                                two = false;
                              }
                              setEightPageData({
                                ...eightPageData,
                                twoB: {
                                  ...eightPageData.twoB,
                                  one: "4",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoB.one === "4"}
                            disabled={eightPageData.two !== "2"}
                            name='1_B_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "35.65mm",
                    width: "37.35mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.14mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoB: {
                                  ...eightPageData.twoB,
                                  two: "4",
                                },
                              });
                            }}
                            checked={eightPageData.twoB.two === "4"}
                            disabled={
                              eightPageData.two !== "2" ||
                              !eightPageData.twoB.one ||
                              Number(eightPageData.twoB.one) > 4
                            }
                            name='1_B_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "44.81mm",
                    width: "56.32mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.32mm" }}>
                      <div
                        className='hls ps44'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "52.72mm",
                        }}
                      >
                        <span className='hrt cs126'>서비스 고도화 및 확대</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "56.32mm",
                    top: "44.81mm",
                    width: "54.32mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.14mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "50.72mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              let two = eightPageData.twoB.two;
                              if (
                                eightPageData.twoB.two &&
                                Number(eightPageData.twoB.two) < 5
                              ) {
                                two = false;
                              }
                              setEightPageData({
                                ...eightPageData,
                                twoB: {
                                  ...eightPageData.twoB,
                                  one: "5",
                                  two,
                                },
                              });
                            }}
                            checked={eightPageData.twoB.one === "5"}
                            disabled={eightPageData.two !== "2"}
                            name='1_B_past'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "110.65mm",
                    top: "44.81mm",
                    width: "37.35mm",
                    height: "9.16mm",
                  }}
                >
                  <div className='hcD' style={{ left: "1.8mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.14mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "33.75mm",
                        }}
                      >
                        <span className='hrt cs127'>
                          <input
                            type='radio'
                            onChange={() => {
                              setisUpdate(true);
                              setEightPageData({
                                ...eightPageData,
                                twoB: {
                                  ...eightPageData.twoB,
                                  two: "5",
                                },
                              });
                            }}
                            checked={eightPageData.twoB.two === "5"}
                            disabled={
                              eightPageData.two !== "2" ||
                              !eightPageData.twoB.one ||
                              Number(eightPageData.twoB.one) > 5
                            }
                            name='1_B_present'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps45'
              style={{
                lineHeight: "3.43mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "222.03mm",
                height: "4.23mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs153'>&nbsp;►</span>
              <span className='hrt cs154'>최종산출물이 서비스개발인 경우,</span>
              <span className='hrt cs149'>&nbsp;11페이지 Ⅴ-2 Outcome</span>
              <span className='hrt cs153'>&nbsp;(결과/서비스개발)로 이동</span>
            </div>
            <div
              className='hls ps45'
              style={{
                lineHeight: "1.59mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "230.82mm",
                height: "2.12mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EightPageComponent;
