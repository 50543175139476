import {
  TwoPageTypes,
  ThreePageTypes,
  FourPageTypes,
  FivePageTypes,
  SixPageTypes,
  SevenPageTypes,
  EightPageTypes,
  NinePageTypes,
  TeenPageTypes,
  ElevenPageTypes,
  TwelvePageTypes,
  ThirteenPageTypes,
  FourteenPageTypes,
  FifteenPageTypes,
  SixteenPageDataTypes,
  SeventeenPageDataTypes,
  EighteenPageDataTypes,
} from "gita";
import _ from "lodash";

const fiveYears: any = {
  "2017": ["one", "two", "three", "four", "five", "six"],
  "2018": ["two", "three", "four", "five", "six"],
  "2019": ["three", "four", "five", "six"],
  "2020": ["four", "five", "six"],
  "2021": ["five", "six"],
  "2022": ["six"],
};

const nineYears: any = {
  "2017": ["one", "two", "three", "four", "five"],
  "2018": ["two", "three", "four", "five"],
  "2019": ["three", "four", "five"],
  "2020": ["four", "five"],
  "2021": ["five"],
  "2022": ["five"],
};

const teenYearsTwo: any = {
  "2017": ["one", "two", "three", "four", "five"],
  "2018": ["two", "three", "four", "five"],
  "2019": ["three", "four", "five"],
  "2020": ["four", "five"],
  "2021": ["five"],
  "2022": ["five"],
};

const teenYearsFour: any = {
  "2017": ["one", "two", "three", "four", "five", "six", "seven"],
  "2018": ["two", "three", "four", "five", "six", "seven"],
  "2019": ["three", "four", "five", "six", "seven"],
  "2020": ["four", "five", "six", "seven"],
  "2021": ["five", "six", "seven"],
  "2022": ["six", "seven"],
};

const pageString = {
  twoPage: "2페이지",
};

const checkFormString: any = {
  twoPage: {
    info: {
      company: "기업명",
      projectName1: "과제명(1)",
      date1: "과제명(1) - 선정년도",
      projectName2: "과제명(2)",
      date2: "과제명(2) - 선정년도",
      responseName: "응답자 성함",
      department: "부서",
      phone: "연락처",
      email: "E-mail",
    },
    one: {
      one: "1. 기업설립연도",
      two: {
        number: "1. 벤처기업 등록여부",
        year: "1. 벤처기업 등록연도",
      },
      three: {
        number: "1. Innobiz기업 등록여부",
        year: "1. Innobiz기업 등록연도",
      },
      four: {
        number: "1. 상장기업 여부",
        year: "1. 상장기업 상장연도",
      },
      five: {
        number: "1. 공인 부설연구소 유무",
        year: "1. 공인 부설연구소 설립연도",
      },
    },
    two: {
      one: "",
      two: "2. 연구개발인력 정규직 종업원 수",
      three: "2. 연구개발인력 비정규직 종업원 수",
      four: "2. 연구개발 외 정규직 종업원 수",
      five: "2. 연구개발 외 비정규직 종업원 수",
    },

    three: {
      one: "3. 자기자금",
      two: "3. 정부출연금",
      three: "3. 융자금",
      four: "3. 투자유치금",
      five: "3. 자기자금 + 정부출연금 + 융자금 + 투자유치금 값이 100이여야 합니다.",
    },
  },
  threePage: {
    four: "4번",
    five: {
      one: "5번 신청시점",
      two: "5번 현재시점",
    },
  },
  fourPage: {
    six: "6번",
    seven: {
      one: "7-1번 국내 연구소와 기술협력활동",
      two: "7-2번 국내 기업과 기술협력활동",
      three: "7-3번 국내 대학교와 기술협력활동",
    },
    eight: {
      one: "8-1번 해외 연구소와 기술협력활동",
      two: "8-2번 해외 기업과 기술협력활동",
      three: "8-3번 해외 대학교와 기술협력활동",
    },
  },
  fivePage: {
    one: {
      one: "1번 본 융자사업 융자지원금",
      two: "1번 기업 자체자금 ",
      three: {
        number: "1번 타 기관 차입금",
        text: "1번 타 기관명",
      },
      four: "1번 계( a+ b + c )",
    },
    two: {
      one: "2번",
      two: {
        one: "2-1 신청년도",
        two: "2-1 제1금융권 대출액",
        three: "2-1 대출금리",
      },
    },
    three: {
      one: "3번 2017년",
      two: "3번 2018년",
      three: "3번 2019년",
      four: "3번 2020년",
      five: "3번 2021년",
      six: "3번 2022년",
    },
  },
  sixPage: {
    one: "1번 학회참석 국내",
    two: "1번 학회참석 국외",
    three: "1번 포럼 및 세미나 참석 국내",
    four: "1번 포럼 및 세미나 참석 국외",
    five: "1번 박람회 참석 국내",
    six: "1번 박람회 참석 국내",
    seven: {
      number: "1번 기타 활동 건수",
      text: "1번 기타",
    },
    eight: "1번 합계",
  },
  sevenPage: {
    one: [
      {
        one: "1번 지식재산권 유형",
        two: "1번 지식재산권 명칭",
        three: "1번 지식재산권 출원(등록) 번호",
        four: "1번 지식재산권 출원인",
        five: "1번 지식재산권 출원 국가",
      },
      {
        one: "2번 지식재산권 유형",
        two: "2번 지식재산권 명칭",
        three: "2번 지식재산권 출원(등록) 번호",
        four: "2번 지식재산권 출원인",
        five: "2번 지식재산권 출원 국가",
      },
      {
        one: "3번 지식재산권 유형",
        two: "3번 지식재산권 명칭",
        three: "3번 지식재산권 출원(등록) 번호",
        four: "3번 지식재산권 출원인",
        five: "3번 지식재산권 출원 국가",
      },
      {
        one: "4번 지식재산권 유형",
        two: "4번 지식재산권 명칭",
        three: "4번 지식재산권 출원(등록) 번호",
        four: "4번 지식재산권 출원인",
        five: "4번 지식재산권 출원 국가",
      },
      {
        one: "5번 지식재산권 유형",
        two: "5번 지식재산권 명칭",
        three: "5번 지식재산권 출원(등록) 번호",
        four: "5번 지식재산권 출원인",
        five: "5번 지식재산권 출원 국가",
      },
    ],
  },
  eightPage: {
    two: "2-1",
    twoA: {
      one: "2-1-A 과제 신청시점",
      two: "2-1-A 현재시점(2022년)",
    },
    twoB: {
      one: "2-1-B 과제 신청연도",
      two: "2-1-B 과제 현재(2022년) 단계",
    },
  },
  ninePage: {
    one: "1번",
    two: {
      one: {
        one: "1-1 2018 기업총 매출액 국내",
        two: "1-1 2018 기업총 매출액 수출",
        three: "1-1 2018 융자사업과제로 인한 매출액 국내",
        four: "1-1 2018 융자사업과제로 인한 매출액 수출",
        oneThree:
          "1-1 2018 융자사업과제로 인한 국내 매출액이 기업총 국내 매출액보다 클 수 없습니다.",
        twoFour:
          "1-1 2018 융자사업과제로 인한 수출 매출액이 기업총 수출 매출액보다 클 수 없습니다.",
      },
      two: {
        one: "1-1 2019 기업총 매출액 국내",
        two: "1-1 2019 기업총 매출액 수출",
        three: "1-1 2019 융자사업과제로 인한 매출액 국내",
        four: "1-1 2019 융자사업과제로 인한 매출액 수출",
        oneThree:
          "1-1 2019 융자사업과제로 인한 국내 매출액이 기업총 국내 매출액보다 클 수 없습니다.",
        twoFour:
          "1-1 2019 융자사업과제로 인한 수출 매출액이 기업총 수출 매출액보다 클 수 없습니다.",
      },
      three: {
        one: "1-1 2020 기업총 매출액 국내",
        two: "1-1 2020 기업총 매출액 수출",
        three: "1-1 2020 융자사업과제로 인한 매출액 국내",
        four: "1-1 2020 융자사업과제로 인한 매출액 수출",
        oneThree:
          "1-1 2020 융자사업과제로 인한 국내 매출액이 기업총 국내 매출액보다 클 수 없습니다.",
        twoFour:
          "1-1 2020 융자사업과제로 인한 수출 매출액이 기업총 수출 매출액보다 클 수 없습니다.",
      },
      four: {
        one: "1-1 2021 기업총 매출액 국내",
        two: "1-1 2021 기업총 매출액 수출",
        three: "1-1 2021 융자사업과제로 인한 매출액 국내",
        four: "1-1 2021 융자사업과제로 인한 매출액 수출",
        oneThree:
          "1-1 2021 융자사업과제로 인한 국내 매출액이 기업총 국내 매출액보다 클 수 없습니다.",
        twoFour:
          "1-1 2021 융자사업과제로 인한 수출 매출액이 기업총 수출 매출액보다 클 수 없습니다.",
      },
      five: {
        one: "1-1 2022 기업총 매출액 국내",
        two: "1-1 2022 기업총 매출액 수출",
        three: "1-1 2022 융자사업과제로 인한 매출액 국내",
        four: "1-1 2022 융자사업과제로 인한 매출액 수출",
        oneThree:
          "1-1 2022 융자사업과제로 인한 국내 매출액이 기업총 국내 매출액보다 클 수 없습니다.",
        twoFour:
          "1-1 2022 융자사업과제로 인한 수출 매출액이 기업총 수출 매출액보다 클 수 없습니다.",
      },
    },
    three: {
      one: {
        one: "1-2 2018 기업총 매출액 국내",
        two: "1-2 2018 기업총 매출액 수출",
        three: "1-2 2018 융자사업과제의 매출액 기여율 국내 매출액 기여율",
        four: "1-2 2018 융자사업과제의 매출액 기여율 수출액 기여율",
        three100: "1-2 2018 국내 매출액 기여율은 100을 넘길 수 없습니다.",
        four100: "1-2 2018 수출액 기여율은 100을 넘길 수 없습니다.",
      },
      two: {
        one: "1-2 2019 기업총 매출액 국내",
        two: "1-2 2019 기업총 매출액 수출",
        three: "1-2 2019 융자사업과제의 매출액 기여율 국내 매출액 기여율",
        four: "1-2 2019 융자사업과제의 매출액 기여율 수출액 기여율",
        three100: "1-2 2019 국내 매출액 기여율은 100을 넘길 수 없습니다.",
        four100: "1-2 2019 수출액 기여율은 100을 넘길 수 없습니다.",
      },
      three: {
        one: "1-2 2020 기업총 매출액 국내",
        two: "1-2 2020 기업총 매출액 수출",
        three: "1-2 2020 융자사업과제의 매출액 기여율 국내 매출액 기여율",
        four: "1-2 2020 융자사업과제의 매출액 기여율 수출액 기여율",
        three100: "1-2 2020 국내 매출액 기여율은 100을 넘길 수 없습니다.",
        four100: "1-2 2020 수출액 기여율은 100을 넘길 수 없습니다.",
      },
      four: {
        one: "1-2 2021 기업총 매출액 국내",
        two: "1-2 2021 기업총 매출액 수출",
        three: "1-2 2021 융자사업과제의 매출액 기여율 국내 매출액 기여율",
        four: "1-2 2021 융자사업과제의 매출액 기여율 수출액 기여율",
        three100: "1-2 2021 국내 매출액 기여율은 100을 넘길 수 없습니다.",
        four100: "1-2 2021 수출액 기여율은 100을 넘길 수 없습니다.",
      },
      five: {
        one: "1-2 2022 기업총 매출액 국내",
        two: "1-2 2022 기업총 매출액 수출",
        three: "1-2 2022 융자사업과제의 매출액 기여율 국내 매출액 기여율",
        four: "1-2 2022 융자사업과제의 매출액 기여율 수출액 기여율",
        three100: "1-2 2022 국내 매출액 기여율은 100을 넘길 수 없습니다.",
        four100: "1-2 2022 수출액 기여율은 100을 넘길 수 없습니다.",
      },
    },
  },
  teenPage: {
    two: "2번",
    twoOne: {
      one: "2-1 2018년 비용절감액",
      two: "2-1 2019년 비용절감액",
      three: "2-1 2020년 비용절감액",
      four: "2-1 2021년 비용절감액",
      five: "2-1 2022년 비용절감액",
    },
    three: {
      one: "3번 신규고용인력",
      two: "3번 연구개발인력 정규직",
      three: "3번 연구개발인력 비정규직",
      four: "3번 연구개발 외 인력 정규직",
      five: "3번 연구개발 외 인력 비정규직",
    },
    four: {
      one: {
        one: "4번 2016년 정규직",
        two: "4번 2016년 비정규직",
      },
      two: {
        one: "4번 2017년 정규직",
        two: "4번 2017년 비정규직",
      },
      three: {
        one: "4번 2018년 정규직",
        two: "4번 2018년 비정규직",
      },
      four: {
        one: "4번 2019년 정규직",
        two: "4번 2019년 비정규직",
      },
      five: {
        one: "4번 2020년 정규직",
        two: "4번 2020년 비정규직",
      },
      six: {
        one: "4번 2021년 정규직",
        two: "4번 2021년 비정규직",
      },
      seven: {
        one: "4번 2022년 정규직",
        two: "4번 2022년 비정규직",
      },
    },
    five: "5번 투자유치액",
  },
  elevenPage: {
    one: "1번",
    two: {
      one: {
        one: "1-1 2018년 기업 총 매출액 국내",
        two: "1-1 2018년 기업 총 매출액 수출",
        three: "1-1 2018년 융자사업과제로 인한 매출액 국내",
        four: "1-1 2018년 융자사업과제로 인한 매출액 수출",
        oneThree:
          "1-1 2018년 융자사업과제로 인한 국내 매출액이 기업총 국내 매출액보다 클 수 없습니다.",
        twoFour:
          "1-1 2018년 융자사업과제로 인한 수출 매출액이 기업총 수출 매출액보다 클 수 없습니다.",
      },
      two: {
        one: "1-1 2019년 기업 총 매출액 국내",
        two: "1-1 2019년 기업 총 매출액 수출",
        three: "1-1 2019년 융자사업과제로 인한 매출액 국내",
        four: "1-1 2019년 융자사업과제로 인한 매출액 수출",
        oneThree:
          "1-1 2019년 융자사업과제로 인한 국내 매출액이 기업총 국내 매출액보다 클 수 없습니다.",
        twoFour:
          "1-1 2019년 융자사업과제로 인한 수출 매출액이 기업총 수출 매출액보다 클 수 없습니다.",
      },
      three: {
        one: "1-1 2020년 기업 총 매출액 국내",
        two: "1-1 2020년 기업 총 매출액 수출",
        three: "1-1 2020년 융자사업과제로 인한 매출액 국내",
        four: "1-1 2020년 융자사업과제로 인한 매출액 수출",
        oneThree:
          "1-1 2020년 융자사업과제로 인한 국내 매출액이 기업총 국내 매출액보다 클 수 없습니다.",
        twoFour:
          "1-1 2020년 융자사업과제로 인한 수출 매출액이 기업총 수출 매출액보다 클 수 없습니다.",
      },
      four: {
        one: "1-1 2021년 기업 총 매출액 국내",
        two: "1-1 2021년 기업 총 매출액 수출",
        three: "1-1 2021년 융자사업과제로 인한 매출액 국내",
        four: "1-1 2021년 융자사업과제로 인한 매출액 수출",
        oneThree:
          "1-1 2021년 융자사업과제로 인한 국내 매출액이 기업총 국내 매출액보다 클 수 없습니다.",
        twoFour:
          "1-1 2021년 융자사업과제로 인한 수출 매출액이 기업총 수출 매출액보다 클 수 없습니다.",
      },
      five: {
        one: "1-1 2022년 기업 총 매출액 국내",
        two: "1-1 2022년 기업 총 매출액 수출",
        three: "1-1 2022년 융자사업과제로 인한 매출액 국내",
        four: "1-1 2022년 융자사업과제로 인한 매출액 수출",
        oneThree:
          "1-1 2022년 융자사업과제로 인한 국내 매출액이 기업총 국내 매출액보다 클 수 없습니다.",
        twoFour:
          "1-1 2022년 융자사업과제로 인한 수출 매출액이 기업총 수출 매출액보다 클 수 없습니다.",
      },
    },
    three: {
      one: {
        one: "1-2 2018년 기업 촐 매출액 국내",
        two: "1-2 2018년 기업 촐 매출액 수출",
        three: "1-2 2018년 융자사업과제의 매출액 기여율 국내 매출액 기여율",
        four: "1-2 2018년 융자사업과제의 매출액 기여율 수출액 기여율",
        three100: "1-2 2018년 국내 매출액 기여율은 100을 넘길 수 없습니다.",
        four100: "1-2 2018년 수출액 기여율은 100을 넘길 수 없습니다.",
      },
      two: {
        one: "1-2 2019년 기업 촐 매출액 국내",
        two: "1-2 2019년 기업 촐 매출액 수출",
        three: "1-2 2019년 융자사업과제의 매출액 기여율 국내 매출액 기여율",
        four: "1-2 2019년 융자사업과제의 매출액 기여율 수출액 기여율",
        three100: "1-2 2019년 국내 매출액 기여율은 100을 넘길 수 없습니다.",
        four100: "1-2 2019년 수출액 기여율은 100을 넘길 수 없습니다.",
      },
      three: {
        one: "1-2 2020년 기업 촐 매출액 국내",
        two: "1-2 2020년 기업 촐 매출액 수출",
        three: "1-2 2020년 융자사업과제의 매출액 기여율 국내 매출액 기여율",
        four: "1-2 2020년 융자사업과제의 매출액 기여율 수출액 기여율",
        three100: "1-2 2020년 국내 매출액 기여율은 100을 넘길 수 없습니다.",
        four100: "1-2 2020년 수출액 기여율은 100을 넘길 수 없습니다.",
      },
      four: {
        one: "1-2 2021년 기업 촐 매출액 국내",
        two: "1-2 2021년 기업 촐 매출액 수출",
        three: "1-2 2021년 융자사업과제의 매출액 기여율 국내 매출액 기여율",
        four: "1-2 2021년 융자사업과제의 매출액 기여율 수출액 기여율",
        three100: "1-2 2021년 국내 매출액 기여율은 100을 넘길 수 없습니다.",
        four100: "1-2 2021년 수출액 기여율은 100을 넘길 수 없습니다.",
      },
      five: {
        one: "1-2 2022년 기업 촐 매출액 국내",
        two: "1-2 2022년 기업 촐 매출액 수출",
        three: "1-2 2022년 융자사업과제의 매출액 기여율 국내 매출액 기여율",
        four: "1-2 2022년 융자사업과제의 매출액 기여율 수출액 기여율",
        three100: "1-2 2022년 국내 매출액 기여율은 100을 넘길 수 없습니다.",
        four100: "1-2 2022년 수출액 기여율은 100을 넘길 수 없습니다.",
      },
    },
  },
  twelvePage: {
    two: "2번",
    twoOne: {
      one: "2-1 2018년 비용절감액",
      two: "2-1 2019년 비용절감액",
      three: "2-1 2020년 비용절감액",
      four: "2-1 2021년 비용절감액",
      five: "2-1 2022년 비용절감액",
    },
    three: {
      one: "3번 신규고용인력 합계",
      two: "3번 연구개발 인력 정규직",
      three: "3번 연구개발 인력 비정규직",
      four: "3번 연구개발 외 인력 정규직",
      five: "3번 연구개발 외 인력 비정규직",
    },
    four: {
      one: {
        one: "4번 2016년 정규직",
        two: "4번 2016년 정규직",
      },
      two: {
        one: "4번 2017년 정규직",
        two: "4번 2017년 정규직",
      },
      three: {
        one: "4번 2018년 정규직",
        two: "4번 2018년 정규직",
      },
      four: {
        one: "4번 2019년 정규직",
        two: "4번 2019년 정규직",
      },
      five: {
        one: "4번 2020년 정규직",
        two: "4번 2020년 정규직",
      },
      six: {
        one: "4번 2021년 정규직",
        two: "4번 2021년 정규직",
      },
      seven: {
        one: "4번 2022년 정규직",
        two: "4번 2022년 정규직",
      },
    },
    five: "5번 투자유치액",
  },
  thirteenPage: {
    one: {
      one: {
        one: "1-1-1 융자사업이 기업의 기술개발에 기여",
        two: "1-1-2 융자사업이 기업의 기술진 능력 개선",
        three: "1-1-3 융자사업이 국가 과학기술 발전에 기여",
      },
      two: {
        one: "1-2-1 융자사업이 기업의 제품 및 서비스 경쟁력 강화에 기여",
        two: "1-2-2 융자사업이 산업발전에 기여",
        three: "1-2-3 융자사업이 새로운 수요를 창출하는데 기여",
        four: "1-2-4 융자사업이 국가 경쟁력을 강화하는데 기여",
      },
      three: {
        one: "1-3-1 융자사업이 기업의 고용창출에 기여",
        two: "1-3-2 융자사업이 공공의 이익에 부합",
      },
    },
    two: {
      one: {
        year: "2번 과제신청연도",
        step: "2번 과제신청 단계",
      },
      two: {
        year: "2번 과제 종료연도",
        step: "2번 과제 종료 단계",
      },
      three: {
        year: "",
        step: "2번 현재 단계",
      },
    },
  },
  fourteenPage: {
    one: {
      one: "1번 1) 융자지원을 통한 연구개발 목표 달성 만족도",
      two: "1번 2) 선정에 대한 만족도",
      three: "1번 3) 융자금의 이자율에 대한 만족도",
      four: "1번 4) 융자금의 2년 거치 3년 분할 상환 조건에 대한 만족도",
      five: "1번 5) 융자금 지급에 대한 만족도",
      six: "1번 6) 융자신청 등 지원시스템의 만족도",
      seven: "1번 7) 신청액 대비 실제 지원결정 금액 만족도",
    },
    oneOne: "1-1번",
    oneTwo: "1-2번",
    oneThree: "1-3번",
  },
  fifteenPage: {
    two: {
      one: "2-1번",
      two: "2-2번 ",
    },
    three: {
      one: {
        one: "3-1-1 기술보증기금 융자담당 직원에 대한 만족도",
        two: "3-1-2 기술보증기금 절차에 대한 만족도",
        three: "3-1-3 기술보증기금에 대한 기타의견",
      },
      two: {
        one: "3-2-1 은행 융자담당 직원에 대한 만족도",
        two: "3-2-2 신청에서 지급까지의 은행절차에 대한 만족도",
        three: "3-2-3 융자집행 은행에 대한 기타의견 ",
      },
    },
    four: {
      one: "4-1",
      two: "4-2",
      three: "4-3",
    },
    five: {
      one: "5-1",
      two: "5-2",
    },
  },
  sixteenPage: {
    one: "1번",
    two: "2번",
    three: "3번",
    four: "4번",
    five: "5번",
    six: "6번",
  },
  seventeenPage: {
    sixOne: "6-1번",
    seven: "7번",
    eight: "8번",
    nine: {
      one: "9번 1순위",
      two: "9번 2순위",
    },
    ten: {
      one: "10번 1순위",
      two: "10번 2순위",
      three: "10번 3순위",
    },
  },
  eighteenPage: {
    eleven: {
      elevenOne: "11-1번",
      elevenTwo: "11-2번",
      elevenThree: "11-3번",
      elevenFour: "11-4번",
      elevenFive: "11-5번",
      elevenSix: "11-6번",
      elevenSeven: "11-7번",
      elevenEight: "11-8번",
      elevenNine: "11-9번",
    },
    twelve: "12번",
  },
};

// 2페이지
const twoPageHandleCheck = (formData: any) => {
  let isError = false;
  const twoPageCheck = {
    info: "",
    one: "",
    two: "",
    three: "",
  };
  const { twoPage: checktwoPage } = checkFormString;

  const { twoPage }: { twoPage: TwoPageTypes } = formData;

  // info
  if (!twoPage.info.company) {
    isError = true;
    twoPageCheck.info += `${checkFormString.twoPage.info.company}\n`;
  }
  if (!twoPage.info.projectName1) {
    isError = true;
    twoPageCheck.info += `${checkFormString.twoPage.info.projectName1}\n`;
  }
  if (!twoPage.info.date1) {
    isError = true;
    twoPageCheck.info += `${checkFormString.twoPage.info.date1}\n`;
  }
  if (twoPage.info.date2 && !twoPage.info.projectName2) {
    isError = true;
    twoPageCheck.info += `${checkFormString.twoPage.info.projectName2}\n`;
  }
  if (twoPage.info.projectName2 && !twoPage.info.date2) {
    isError = true;
    twoPageCheck.info += `${checkFormString.twoPage.info.date2}\n`;
  }
  if (!twoPage.info.responseName) {
    isError = true;
    twoPageCheck.info += `${checkFormString.twoPage.info.responseName}\n`;
  }
  if (!twoPage.info.department) {
    isError = true;
    twoPageCheck.info += `${checkFormString.twoPage.info.department}\n`;
  }
  if (!twoPage.info.phone) {
    isError = true;
    twoPageCheck.info += `${checkFormString.twoPage.info.phone}\n`;
  }
  if (!twoPage.info.email) {
    isError = true;
    twoPageCheck.info += `${checkFormString.twoPage.info.email}\n`;
  }

  // 1번
  if (!twoPage.one.one) {
    isError = true;
    twoPageCheck.one += `${checkFormString.twoPage.one.one}\n`;
  }

  if (!twoPage.one.two.number) {
    isError = true;
    twoPageCheck.one += `${checkFormString.twoPage.one.two.number}\n`;
  } else {
    if (twoPage.one.two.number !== "2") {
      if (!twoPage.one.two.year) {
        isError = true;
        twoPageCheck.one += `${checkFormString.twoPage.one.two.year}\n`;
      }
    }
  }

  if (!twoPage.one.three.number) {
    isError = true;
    twoPageCheck.one += `${checkFormString.twoPage.one.three.number}\n`;
  } else {
    if (twoPage.one.three.number !== "2") {
      if (!twoPage.one.three.year) {
        isError = true;
        twoPageCheck.one += `${checkFormString.twoPage.one.three.year}\n`;
      }
    }
  }

  if (!twoPage.one.four.number) {
    isError = true;
    twoPageCheck.one += `${checkFormString.twoPage.one.four.number}\n`;
  } else {
    if (twoPage.one.four.number !== "4") {
      if (!twoPage.one.four.year) {
        isError = true;
        twoPageCheck.one += `${checkFormString.twoPage.one.four.year}\n`;
      }
    }
  }

  if (!twoPage.one.five.number) {
    isError = true;
    twoPageCheck.one += `${checkFormString.twoPage.one.five.number}\n`;
  } else {
    if (twoPage.one.five.number !== "2") {
      if (!twoPage.one.five.year) {
        isError = true;
        twoPageCheck.one += `${checkFormString.twoPage.one.five.year}\n`;
      }
    }
  }

  // 2번
  // if (!twoPage.two.one === false) {
  //   isError = true;
  //   twoPageCheck.two += `${checkFormString.twoPage.two.one}\n`;
  // }
  if (twoPage.two.two === false) {
    isError = true;
    twoPageCheck.two += `${checkFormString.twoPage.two.two}\n`;
  }
  if (twoPage.two.three === false) {
    isError = true;
    twoPageCheck.two += `${checkFormString.twoPage.two.three}\n`;
  }
  if (twoPage.two.four === false) {
    isError = true;
    twoPageCheck.two += `${checkFormString.twoPage.two.four}\n`;
  }
  if (twoPage.two.five === false) {
    isError = true;
    twoPageCheck.two += `${checkFormString.twoPage.two.five}\n`;
  }

  // 3번
  if (!twoPage.three.one) {
    isError = true;
    twoPageCheck.three += `${checkFormString.twoPage.three.one}\n`;
  }
  if (!twoPage.three.two) {
    isError = true;
    twoPageCheck.three += `${checkFormString.twoPage.three.two}\n`;
  }
  if (!twoPage.three.three) {
    isError = true;
    twoPageCheck.three += `${checkFormString.twoPage.three.three}\n`;
  }
  if (!twoPage.three.four) {
    isError = true;
    twoPageCheck.three += `${checkFormString.twoPage.three.four}\n`;
  }

  if (
    twoPage.three.one &&
    twoPage.three.two &&
    twoPage.three.three &&
    twoPage.three.four
  ) {
    let data =
      Number(twoPage.three.one) +
      Number(twoPage.three.two) +
      Number(twoPage.three.three) +
      Number(twoPage.three.four);

    data = +data.toFixed(12);

    if (data !== 100) {
      isError = true;
      twoPageCheck.three += `${checkFormString.twoPage.three.five}   (현재: ${data})\n`;
    }
  }

  return [twoPageCheck, isError];
};

// 3페이지
const threePageHandleCheck = (formData: any) => {
  let isError = false;
  const threePageCheck = {
    four: "",
    five: "",
  };

  const { threePage }: { threePage: ThreePageTypes } = formData;

  // 4번
  if (!threePage.four) {
    isError = true;
    threePageCheck.four += `${checkFormString.threePage.four}\n`;
  }

  // 5번
  if (!threePage.five.one) {
    isError = true;
    threePageCheck.five += `${checkFormString.threePage.five.one}\n`;
  }

  if (!threePage.five.two) {
    isError = true;
    threePageCheck.five += `${checkFormString.threePage.five.two}\n`;
  }
  return [threePageCheck, isError];
};

// 4페이지
const fourPageHandleCheck = (formData: any) => {
  let isError = false;
  const fourPageCheck = {
    six: "",
    seven: "",
    eight: "",
  };

  const { fourPage }: { fourPage: FourPageTypes } = formData;

  // 6번
  if (!fourPage.six) {
    fourPageCheck.six = `${checkFormString.fourPage.six}`;
  }

  // 7번
  if (!fourPage.seven.one) {
    isError = true;
    fourPageCheck.seven += `${checkFormString.fourPage.seven.one}\n`;
  }
  if (!fourPage.seven.two) {
    isError = true;
    fourPageCheck.seven += `${checkFormString.fourPage.seven.two}\n`;
  }
  if (!fourPage.seven.three) {
    isError = true;
    fourPageCheck.seven += `${checkFormString.fourPage.seven.three}\n`;
  }

  // 8번
  if (!fourPage.eight.one) {
    isError = true;
    fourPageCheck.eight += `${checkFormString.fourPage.eight.one}\n`;
  }
  if (!fourPage.eight.two) {
    isError = true;
    fourPageCheck.eight += `${checkFormString.fourPage.eight.two}\n`;
  }
  if (!fourPage.eight.three) {
    isError = true;
    fourPageCheck.eight += `${checkFormString.fourPage.eight.three}\n`;
  }

  return [fourPageCheck, isError];
};

// 5페이지
const fivePageHandleCheck = (formData: any) => {
  let isError = false;
  const fivePageCheck = {
    one: "",
    two: "",
    three: "",
  };

  const {
    fivePage,
    twoPage,
  }: { fivePage: FivePageTypes; twoPage: TwoPageTypes } = formData;

  let date: any = "";

  date =
    Number(twoPage.info.date1) >= Number(twoPage.info.date2)
      ? twoPage.info.date1
      : twoPage.info.date2;

  if (!date) {
    return [fivePageCheck, isError];
  }

  // 1번
  if (!fivePage.one.one) {
    isError = true;
    fivePageCheck.one += `${checkFormString.fivePage.one.one}\n`;
  }
  if (!fivePage.one.two) {
    isError = true;
    fivePageCheck.one += `${checkFormString.fivePage.one.two}\n`;
  }
  if (!fivePage.one.three.number) {
    isError = true;
    fivePageCheck.one += `${checkFormString.fivePage.one.three.number}\n`;
  }
  if (Number(fivePage.one.three.number) && !fivePage.one.three.text) {
    isError = true;
    fivePageCheck.one += `${checkFormString.fivePage.one.three.text}\n`;
  }

  // 2번
  if (!fivePage.two.one) {
    isError = true;
    fivePageCheck.two += `${checkFormString.fivePage.two.one}\n`;
  }

  if (fivePage.two.one === "1") {
    if (!fivePage.two.two.one) {
      isError = true;
      fivePageCheck.two += `${checkFormString.fivePage.two.two.one}\n`;
    }
    if (!fivePage.two.two.two) {
      isError = true;
      fivePageCheck.two += `${checkFormString.fivePage.two.two.two}\n`;
    }
    if (!fivePage.two.two.three) {
      isError = true;
      fivePageCheck.two += `${checkFormString.fivePage.two.two.three}\n`;
    }
  }

  // 3번
  const dateList: string[] = fiveYears[date];
  const fiveThree: any = _.cloneDeep(fivePage);
  if (!_.isEmpty(dateList)) {
    for (let year of dateList) {
      if (fiveThree.three[year] === false) {
        isError = true;
        fivePageCheck.three += `${checkFormString.fivePage.three[year]}\n`;
      }
    }
  }
  return [fivePageCheck, isError];
};

// 6페이지
const sixPageHandleCheck = (formData: any) => {
  let isError = false;
  const sixPageCheck = {
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
    seven: "",
    eight: "",
  };

  const { sixPage, twoPage }: { sixPage: SixPageTypes; twoPage: TwoPageTypes } =
    formData;

  if (sixPage.one === false) {
    isError = true;
    sixPageCheck.one += `${checkFormString.sixPage.one}`;
  }
  if (sixPage.two === false) {
    isError = true;
    sixPageCheck.two += `${checkFormString.sixPage.two}`;
  }
  if (sixPage.three === false) {
    isError = true;
    sixPageCheck.three += `${checkFormString.sixPage.three}`;
  }
  if (sixPage.four === false) {
    isError = true;
    sixPageCheck.four += `${checkFormString.sixPage.four}`;
  }
  if (sixPage.five === false) {
    isError = true;
    sixPageCheck.five += `${checkFormString.sixPage.five}`;
  }
  if (sixPage.six === false) {
    isError = true;
    sixPageCheck.six += `${checkFormString.sixPage.six}`;
  }

  if (sixPage.seven.number && !sixPage.seven.text) {
    isError = true;
    sixPageCheck.seven += `${checkFormString.sixPage.seven.text}\n`;
  }

  if (sixPage.seven.text && sixPage.seven.number === false) {
    isError = true;
    sixPageCheck.seven += `${checkFormString.sixPage.seven.number}\n`;
  }

  return [sixPageCheck, isError];
};

// 7페이지
const sevenPageHandleCheck = (formData: any) => {
  let isError = false;
  const sevenPageCheck: any = {
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
  };

  const {
    sevenPage,
    twoPage,
  }: { sevenPage: SevenPageTypes; twoPage: TwoPageTypes } = formData;

  let once = false;
  const indexOne: any = {
    0: "one",
    1: "two",
    2: "three",
    3: "four",
    4: "five",
  };
  for (let i = 0; i < 5; i++) {
    if (
      sevenPage.one[i].one !== false ||
      sevenPage.one[i].two !== false ||
      sevenPage.one[i].three !== false ||
      sevenPage.one[i].four !== false ||
      sevenPage.one[i].five !== false
    ) {
      once = true;
      if (sevenPage.one[i].one === false) {
        isError = true;
        sevenPageCheck[
          indexOne[i]
        ] += `${checkFormString.sevenPage.one[i].one}\n`;
      }
      if (sevenPage.one[i].two === false) {
        isError = true;
        sevenPageCheck[
          indexOne[i]
        ] += `${checkFormString.sevenPage.one[i].two}\n`;
      }
      if (sevenPage.one[i].three === false) {
        isError = true;
        sevenPageCheck[
          indexOne[i]
        ] += `${checkFormString.sevenPage.one[i].three}\n`;
      }
      if (sevenPage.one[i].four === false) {
        isError = true;
        sevenPageCheck[
          indexOne[i]
        ] += `${checkFormString.sevenPage.one[i].four}\n`;
      }
      if (sevenPage.one[i].five === false) {
        isError = true;
        sevenPageCheck[
          indexOne[i]
        ] += `${checkFormString.sevenPage.one[i].five}\n`;
      }
    }
  }

  if (!once) {
    isError = true;
    sevenPageCheck.one += "1개이상 작성 필요";
  }
  return [sevenPageCheck, isError];
};

// 8페이지
const eightPageHandleCheck = (formData: any) => {
  let isError = false;
  const eightPageCheck: any = {
    two: "",
    twoA: "",
    twoB: "",
  };

  const {
    eightPage,
    twoPage,
  }: { eightPage: EightPageTypes; twoPage: TwoPageTypes } = formData;

  if (eightPage.two === false) {
    isError = true;
    eightPageCheck.two += `${checkFormString.eightPage.two}\n`;
  }

  if (eightPage.two === "1") {
    if (eightPage.twoA.one === false) {
      isError = true;
      eightPageCheck.twoA += `${checkFormString.eightPage.twoA.one}\n`;
    }
    if (eightPage.twoA.two === false) {
      isError = true;
      eightPageCheck.twoA += `${checkFormString.eightPage.twoA.two}\n`;
    }
  }

  if (eightPage.two === "2") {
    if (eightPage.twoB.one === false) {
      isError = true;
      eightPageCheck.twoB += `${checkFormString.eightPage.twoB.one}\n`;
    }
    if (eightPage.twoB.two === false) {
      isError = true;
      eightPageCheck.twoB += `${checkFormString.eightPage.twoB.two}\n`;
    }
  }

  return [eightPageCheck, isError];
};

// 9페이지
const ninePageHandleCheck = (formData: any) => {
  let isError = false;
  const ninePageCheck: any = {
    one: "",
    two: "",
    three: "",
  };

  const {
    ninePage,
    twoPage,
    eightPage,
  }: {
    ninePage: NinePageTypes;
    twoPage: TwoPageTypes;
    eightPage: EightPageTypes;
  } = formData;

  let date: any = "";

  const isConfirm = eightPage.two;

  if (isConfirm !== "1") {
    return [ninePageCheck, isError];
  }

  date =
    Number(twoPage.info.date1) >= Number(twoPage.info.date2)
      ? twoPage.info.date1
      : twoPage.info.date2;
  if (!date) {
    return [ninePageCheck, isError];
  }

  if (ninePage.one === false) {
    isError = true;
    ninePageCheck.one += `${checkFormString.ninePage.one}\n`;
  }

  if (ninePage.one === "1") {
    const yearList = nineYears[date];
    const reNinePage: any = ninePage;
    for (let i = 0; i < yearList.length; i++) {
      if (reNinePage.two[yearList[i]].one === false) {
        isError = true;
        ninePageCheck.two += `${
          checkFormString.ninePage.two[yearList[i]].one
        }\n`;
      }
      if (reNinePage.two[yearList[i]].two === false) {
        isError = true;
        ninePageCheck.two += `${
          checkFormString.ninePage.two[yearList[i]].two
        }\n`;
      }
      if (reNinePage.two[yearList[i]].three === false) {
        isError = true;
        ninePageCheck.two += `${
          checkFormString.ninePage.two[yearList[i]].three
        }\n`;
      }
      if (reNinePage.two[yearList[i]].four === false) {
        isError = true;
        ninePageCheck.two += `${
          checkFormString.ninePage.two[yearList[i]].four
        }\n`;
      }

      if (
        reNinePage.two[yearList[i]].one &&
        reNinePage.two[yearList[i]].three
      ) {
        if (
          Number(reNinePage.two[yearList[i]].one) <
          Number(reNinePage.two[yearList[i]].three)
        ) {
          isError = true;
          ninePageCheck.two += `${
            checkFormString.ninePage.two[yearList[i]].oneThree
          }\n`;
        }
      }

      if (reNinePage.two[yearList[i]].two && reNinePage.two[yearList[i]].four) {
        if (
          Number(reNinePage.two[yearList[i]].two) <
          Number(reNinePage.two[yearList[i]].four)
        ) {
          isError = true;
          ninePageCheck.two += `${
            checkFormString.ninePage.two[yearList[i]].twoFour
          }\n`;
        }
      }
    }
  }

  if (ninePage.one === "2") {
    const yearList = nineYears[date];
    const reNinePage: any = ninePage;
    for (let i = 0; i < yearList.length; i++) {
      if (reNinePage.three[yearList[i]].one === false) {
        isError = true;
        ninePageCheck.three += `${
          checkFormString.ninePage.three[yearList[i]].one
        }\n`;
      }
      if (reNinePage.three[yearList[i]].two === false) {
        isError = true;
        ninePageCheck.three += `${
          checkFormString.ninePage.three[yearList[i]].two
        }\n`;
      }
      if (reNinePage.three[yearList[i]].three === false) {
        isError = true;
        ninePageCheck.three += `${
          checkFormString.ninePage.three[yearList[i]].three
        }\n`;
      }
      if (reNinePage.three[yearList[i]].four === false) {
        isError = true;
        ninePageCheck.three += `${
          checkFormString.ninePage.three[yearList[i]].four
        }\n`;
      }

      if (reNinePage.three[yearList[i]].three) {
        if (Number(reNinePage.three[yearList[i]].three) > 100) {
          isError = true;
          ninePageCheck.three += `${
            checkFormString.ninePage.three[yearList[i]].three100
          }\n`;
        }
      }

      if (reNinePage.three[yearList[i]].four) {
        if (Number(reNinePage.three[yearList[i]].four) > 100) {
          isError = true;
          ninePageCheck.three += `${
            checkFormString.ninePage.three[yearList[i]].four100
          }\n`;
        }
      }
    }
  }
  return [ninePageCheck, isError];
};

// 10페이지
const teenPageHandleCheck = (formData: any) => {
  let isError = false;
  const teenPageCheck: any = {
    two: "",
    twoOne: "",
    three: "",
    four: "",
    five: "",
  };

  const {
    teenPage,
    twoPage,
    eightPage,
  }: {
    teenPage: TeenPageTypes;
    twoPage: TwoPageTypes;
    eightPage: EightPageTypes;
  } = formData;

  let date: any = "";

  const isConfirm = eightPage.two;

  if (isConfirm !== "1") {
    return [teenPageCheck, isError];
  }

  date =
    Number(twoPage.info.date1) >= Number(twoPage.info.date2)
      ? twoPage.info.date1
      : twoPage.info.date2;
  if (!date) {
    return [teenPageCheck, isError];
  }

  // 2번
  if (teenPage.two === false) {
    isError = true;
    teenPageCheck.two += `${checkFormString.teenPage.two}\n`;
  }

  // 2-1번
  if (teenPage.two === "1") {
    const yearList = teenYearsTwo[date];
    console.log(yearList);
    const reTeenPage: any = teenPage;
    for (let item of yearList) {
      if (reTeenPage.twoOne[item] === false) {
        isError = true;
        teenPageCheck.twoOne += `${checkFormString.teenPage.twoOne[item]}\n`;
      }
    }
  }

  // 3번
  if (teenPage.three.one === false) {
    isError = true;
    teenPageCheck.three += `${checkFormString.teenPage.three.one}\n`;
  }
  if (teenPage.three.two === false) {
    isError = true;
    teenPageCheck.three += `${checkFormString.teenPage.three.two}\n`;
  }
  if (teenPage.three.three === false) {
    isError = true;
    teenPageCheck.three += `${checkFormString.teenPage.three.three}\n`;
  }
  if (teenPage.three.four === false) {
    isError = true;
    teenPageCheck.three += `${checkFormString.teenPage.three.four}\n`;
  }
  if (teenPage.three.five === false) {
    isError = true;
    teenPageCheck.three += `${checkFormString.teenPage.three.five}\n`;
  }

  // 4번
  const yearList = teenYearsFour[date];
  const reTeenPage: any = teenPage;
  for (let item of yearList) {
    if (reTeenPage.four[item].one === false) {
      isError = true;
      teenPageCheck.four += `${checkFormString.teenPage.four[item].one}\n`;
    }
    if (reTeenPage.four[item].two === false) {
      isError = true;
      teenPageCheck.four += `${checkFormString.teenPage.four[item].two}\n`;
    }
  }

  // 5번
  if (teenPage.five === false) {
    isError = true;
    teenPageCheck.five += `${checkFormString.teenPage.five}\n`;
  }

  return [teenPageCheck, isError];
};

// 11페이지
const elevenPageHandleCheck = (formData: any) => {
  let isError = false;
  const elevenPageCheck: any = {
    one: "",
    two: "",
    three: "",
  };

  const {
    elevenPage,
    twoPage,
    eightPage,
  }: {
    elevenPage: ElevenPageTypes;
    twoPage: TwoPageTypes;
    eightPage: EightPageTypes;
  } = formData;

  let date: any = "";

  const isConfirm = eightPage.two;

  if (isConfirm !== "2") {
    return [elevenPageCheck, isError];
  }

  date =
    Number(twoPage.info.date1) >= Number(twoPage.info.date2)
      ? twoPage.info.date1
      : twoPage.info.date2;

  if (!date) {
    return [elevenPageCheck, isError];
  }

  if (elevenPage.one === false) {
    isError = true;
    elevenPageCheck.one += `${checkFormString.elevenPage.one}\n`;
  }

  if (elevenPage.one === "1") {
    const yearList = nineYears[date];
    const reelevenPage: any = elevenPage;
    console.log(yearList);
    for (let i = 0; i < yearList.length; i++) {
      if (reelevenPage.two[yearList[i]].one === false) {
        isError = true;
        elevenPageCheck.two += `${
          checkFormString.elevenPage.two[yearList[i]].one
        }\n`;
      }
      if (reelevenPage.two[yearList[i]].two === false) {
        isError = true;
        elevenPageCheck.two += `${
          checkFormString.elevenPage.two[yearList[i]].two
        }\n`;
      }
      if (reelevenPage.two[yearList[i]].three === false) {
        isError = true;
        elevenPageCheck.two += `${
          checkFormString.elevenPage.two[yearList[i]].three
        }\n`;
      }
      if (reelevenPage.two[yearList[i]].four === false) {
        isError = true;
        elevenPageCheck.two += `${
          checkFormString.elevenPage.two[yearList[i]].four
        }\n`;
      }

      if (
        reelevenPage.two[yearList[i]].one &&
        reelevenPage.two[yearList[i]].three
      ) {
        if (
          Number(reelevenPage.two[yearList[i]].one) <
          Number(reelevenPage.two[yearList[i]].three)
        ) {
          isError = true;
          elevenPageCheck.two += `${
            checkFormString.elevenPage.two[yearList[i]].oneThree
          }\n`;
        }
      }

      if (
        reelevenPage.two[yearList[i]].two &&
        reelevenPage.two[yearList[i]].four
      ) {
        if (
          Number(reelevenPage.two[yearList[i]].two) <
          Number(reelevenPage.two[yearList[i]].four)
        ) {
          isError = true;
          elevenPageCheck.two += `${
            checkFormString.elevenPage.two[yearList[i]].twoFour
          }\n`;
        }
      }
    }
  }

  if (elevenPage.one === "2") {
    const yearList = nineYears[date];
    const reelevenPage: any = elevenPage;
    for (let i = 0; i < yearList.length; i++) {
      if (reelevenPage.three[yearList[i]].one === false) {
        isError = true;
        elevenPageCheck.three += `${
          checkFormString.elevenPage.three[yearList[i]].one
        }\n`;
      }
      if (reelevenPage.three[yearList[i]].two === false) {
        isError = true;
        elevenPageCheck.three += `${
          checkFormString.elevenPage.three[yearList[i]].two
        }\n`;
      }
      if (reelevenPage.three[yearList[i]].three === false) {
        isError = true;
        elevenPageCheck.three += `${
          checkFormString.elevenPage.three[yearList[i]].three
        }\n`;
      }
      if (reelevenPage.three[yearList[i]].four === false) {
        isError = true;
        elevenPageCheck.three += `${
          checkFormString.elevenPage.three[yearList[i]].four
        }\n`;
      }

      if (reelevenPage.three[yearList[i]].three) {
        if (Number(reelevenPage.three[yearList[i]].three) > 100) {
          isError = true;
          elevenPageCheck.three += `${
            checkFormString.elevenPage.three[yearList[i]].three100
          }\n`;
        }
      }

      if (reelevenPage.three[yearList[i]].four) {
        if (Number(reelevenPage.three[yearList[i]].four) > 100) {
          isError = true;
          elevenPageCheck.three += `${
            checkFormString.elevenPage.three[yearList[i]].four100
          }\n`;
        }
      }
    }
  }
  return [elevenPageCheck, isError];
};

// 12페이지
const twelvePageHandleCheck = (formData: any) => {
  let isError = false;
  const twelvePageCheck: any = {
    two: "",
    twoOne: "",
    three: "",
    four: "",
    five: "",
  };

  const {
    twelvePage,
    twoPage,
    eightPage,
  }: {
    twelvePage: TwelvePageTypes;
    twoPage: TwoPageTypes;
    eightPage: EightPageTypes;
  } = formData;

  let date: any = "";

  const isConfirm = eightPage.two;

  if (isConfirm !== "2") {
    return [twelvePageCheck, isError];
  }

  date =
    Number(twoPage.info.date1) >= Number(twoPage.info.date2)
      ? twoPage.info.date1
      : twoPage.info.date2;
  if (!date) {
    return [twelvePageCheck, isError];
  }

  // 2번
  if (twelvePage.two === false) {
    isError = true;
    twelvePageCheck.two += `${checkFormString.twelvePage.two}\n`;
  }

  // 2-1번
  if (twelvePage.two === "1") {
    const yearList = teenYearsTwo[date];
    const retwelvePage: any = twelvePage;
    for (let item of yearList) {
      if (retwelvePage.twoOne[item] === false) {
        isError = true;
        twelvePageCheck.twoOne += `${checkFormString.twelvePage.twoOne[item]}\n`;
      }
    }
  }

  // 3번
  if (twelvePage.three.one === false) {
    isError = true;
    twelvePageCheck.three += `${checkFormString.twelvePage.three.one}\n`;
  }
  if (twelvePage.three.two === false) {
    isError = true;
    twelvePageCheck.three += `${checkFormString.twelvePage.three.two}\n`;
  }
  if (twelvePage.three.three === false) {
    isError = true;
    twelvePageCheck.three += `${checkFormString.twelvePage.three.three}\n`;
  }
  if (twelvePage.three.four === false) {
    isError = true;
    twelvePageCheck.three += `${checkFormString.twelvePage.three.four}\n`;
  }
  if (twelvePage.three.five === false) {
    isError = true;
    twelvePageCheck.three += `${checkFormString.twelvePage.three.five}\n`;
  }

  // 4번
  const twentyYearsFour: any = {
    "2017": ["one", "two", "three", "four", "five", "six", "seven"],
    "2018": ["two", "three", "four", "five", "six", "seven"],
    "2019": ["three", "four", "five", "six", "seven"],
    "2020": ["four", "five", "six", "seven"],
    "2021": ["five", "six", "seven"],
    "2022": ["seven"],
  };

  const yearList = twentyYearsFour[date];
  const retwelvePage: any = twelvePage;
  for (let item of yearList) {
    if (retwelvePage.four[item].one === false) {
      isError = true;
      twelvePageCheck.four += `${checkFormString.twelvePage.four[item].one}\n`;
    }
    if (retwelvePage.four[item].two === false) {
      isError = true;
      twelvePageCheck.four += `${checkFormString.twelvePage.four[item].two}\n`;
    }
  }

  // 5번
  if (twelvePage.five === false) {
    isError = true;
    twelvePageCheck.five += `${checkFormString.twelvePage.five}\n`;
  }

  return [twelvePageCheck, isError];
};

// 13페이지
const thirteenPageHandleCheck = (formData: any) => {
  let isError = false;
  const thirteenPageCheck: any = {
    one: "",
    two: "",
  };

  const {
    thirteenPage,
    twoPage,
  }: { thirteenPage: ThirteenPageTypes; twoPage: TwoPageTypes } = formData;

  let date: any = "";

  date =
    Number(twoPage.info.date1) >= Number(twoPage.info.date2)
      ? twoPage.info.date1
      : twoPage.info.date2;

  if (!date) {
    return [thirteenPageCheck, isError];
  }

  console.log(date);
  //
  if (thirteenPage.one.one.one === false) {
    isError = true;
    thirteenPageCheck.one += `${checkFormString.thirteenPage.one.one.one}\n`;
  }
  if (thirteenPage.one.one.two === false) {
    isError = true;
    thirteenPageCheck.one += `${checkFormString.thirteenPage.one.one.two}\n`;
  }
  if (thirteenPage.one.one.three === false) {
    isError = true;
    thirteenPageCheck.one += `${checkFormString.thirteenPage.one.one.three}\n`;
  }

  //
  if (thirteenPage.one.two.one === false) {
    isError = true;
    thirteenPageCheck.one += `${checkFormString.thirteenPage.one.two.one}\n`;
  }
  if (thirteenPage.one.two.two === false) {
    isError = true;
    thirteenPageCheck.one += `${checkFormString.thirteenPage.one.two.two}\n`;
  }
  if (thirteenPage.one.two.three === false) {
    isError = true;
    thirteenPageCheck.one += `${checkFormString.thirteenPage.one.two.three}\n`;
  }
  if (thirteenPage.one.two.four === false) {
    isError = true;
    thirteenPageCheck.one += `${checkFormString.thirteenPage.one.two.four}\n`;
  }

  //
  if (thirteenPage.one.three.one === false) {
    isError = true;
    thirteenPageCheck.one += `${checkFormString.thirteenPage.one.three.one}\n`;
  }
  if (thirteenPage.one.three.two === false) {
    isError = true;
    thirteenPageCheck.one += `${checkFormString.thirteenPage.one.three.two}\n`;
  }

  //
  // if (!thirteenPage.two.one.year) {
  //   isError = true;
  //   thirteenPageCheck.two += `${checkFormString.thirteenPage.two.one.year}\n`;
  // }
  if (!thirteenPage.two.one.step) {
    isError = true;
    thirteenPageCheck.two += `${checkFormString.thirteenPage.two.one.step}\n`;
  }
  //
  // if (!thirteenPage.two.two.year) {
  //   isError = true;
  //   thirteenPageCheck.two += `${checkFormString.thirteenPage.two.two.year}\n`;
  // }
  if (date != "2022" && !thirteenPage.two.two.step) {
    isError = true;
    thirteenPageCheck.two += `${checkFormString.thirteenPage.two.two.step}\n`;
  }
  //
  if (!thirteenPage.two.three.step) {
    isError = true;
    thirteenPageCheck.two += `${checkFormString.thirteenPage.two.three.step}\n`;
  }

  return [thirteenPageCheck, isError];
};

// 14
const fourteenPageHandleCheck = (formData: any) => {
  let isError = false;
  const fourteenPageCheck: any = {
    one: "",
    oneOne: "",
    oneTwo: "",
    oneThree: "",
  };

  const {
    fourteenPage,
    twoPage,
  }: { fourteenPage: FourteenPageTypes; twoPage: TwoPageTypes } = formData;

  if (fourteenPage.one.one === false) {
    isError = true;
    fourteenPageCheck.one += `${checkFormString.fourteenPage.one.one}\n`;
  }
  if (fourteenPage.one.two === false) {
    isError = true;
    fourteenPageCheck.one += `${checkFormString.fourteenPage.one.two}\n`;
  }
  if (fourteenPage.one.three === false) {
    isError = true;
    fourteenPageCheck.one += `${checkFormString.fourteenPage.one.three}\n`;
  }
  if (fourteenPage.one.four === false) {
    isError = true;
    fourteenPageCheck.one += `${checkFormString.fourteenPage.one.four}\n`;
  }
  if (fourteenPage.one.five === false) {
    isError = true;
    fourteenPageCheck.one += `${checkFormString.fourteenPage.one.five}\n`;
  }
  if (fourteenPage.one.six === false) {
    isError = true;
    fourteenPageCheck.one += `${checkFormString.fourteenPage.one.six}\n`;
  }
  if (fourteenPage.one.seven === false) {
    isError = true;
    fourteenPageCheck.one += `${checkFormString.fourteenPage.one.seven}\n`;
  }

  if (fourteenPage.oneOne === false) {
    isError = true;
    fourteenPageCheck.oneOne += `${checkFormString.fourteenPage.oneOne}\n`;
  }

  if (fourteenPage.oneTwo === false) {
    isError = true;
    fourteenPageCheck.oneTwo += `${checkFormString.fourteenPage.oneTwo}\n`;
  }

  if (fourteenPage.oneThree === false) {
    isError = true;
    fourteenPageCheck.oneThree += `${checkFormString.fourteenPage.oneThree}\n`;
  }

  return [fourteenPageCheck, isError];
};

//15페이지
const fifteenPageHandleCheck = (formData: any) => {
  let isError = false;
  const fifteenPageCheck: any = {
    two: "",
    three: "",
    four: "",
    five: "",
  };

  const {
    fifteenPage,
    twoPage,
  }: { fifteenPage: FifteenPageTypes; twoPage: TwoPageTypes } = formData;

  //2
  if (fifteenPage.two.one === false) {
    isError = true;
    fifteenPageCheck.two += `${checkFormString.fifteenPage.two.one}\n`;
  }

  if (fifteenPage.two.two === false) {
    isError = true;
    fifteenPageCheck.two += `${checkFormString.fifteenPage.two.two}\n`;
  }

  // 3-1
  if (fifteenPage.three.one.one === false) {
    isError = true;
    fifteenPageCheck.three += `${checkFormString.fifteenPage.three.one.one}\n`;
  }
  if (fifteenPage.three.one.two === false) {
    isError = true;
    fifteenPageCheck.three += `${checkFormString.fifteenPage.three.one.two}\n`;
  }

  // 3-2
  if (fifteenPage.three.two.one === false) {
    isError = true;
    fifteenPageCheck.three += `${checkFormString.fifteenPage.three.two.one}\n`;
  }
  if (fifteenPage.three.two.two === false) {
    isError = true;
    fifteenPageCheck.three += `${checkFormString.fifteenPage.three.two.two}\n`;
  }

  // 4
  if (fifteenPage.four.one === false) {
    isError = true;
    fifteenPageCheck.four += `${checkFormString.fifteenPage.four.one}\n`;
  }
  if (fifteenPage.four.one === "1") {
    if (fifteenPage.four.two === false) {
      isError = true;
      fifteenPageCheck.four += `${checkFormString.fifteenPage.four.two}\n`;
    }
  }
  if (fifteenPage.four.one === "1") {
    if (fifteenPage.four.three === false) {
      isError = true;
      fifteenPageCheck.four += `${checkFormString.fifteenPage.four.three}\n`;
    }
  }

  //5
  if (fifteenPage.five.one === false) {
    isError = true;
    fifteenPageCheck.five += `${checkFormString.fifteenPage.five.one}\n`;
  }
  if (fifteenPage.five.two === false) {
    isError = true;
    fifteenPageCheck.five += `${checkFormString.fifteenPage.five.two}\n`;
  }

  return [fifteenPageCheck, isError];
};
// 16
const sixteenPageHandleCheck = (formData: any) => {
  let isError = false;
  const sixteenPageCheck: any = {
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
  };

  const {
    sixteenPage,
    twoPage,
  }: { sixteenPage: SixteenPageDataTypes; twoPage: TwoPageTypes } = formData;

  if (sixteenPage.one === false) {
    isError = true;
    sixteenPageCheck.one += `${checkFormString.sixteenPage.one}`;
  }
  if (sixteenPage.two.number === false) {
    isError = true;
    sixteenPageCheck.two += `${checkFormString.sixteenPage.two}`;
  }
  if (sixteenPage.three.number === false) {
    isError = true;
    sixteenPageCheck.three += `${checkFormString.sixteenPage.three}`;
  }

  let isFour = false;
  for (let item of sixteenPage.four) {
    if (item.number !== false) {
      isFour = true;
    }
  }
  if (!isFour) {
    isError = true;
    sixteenPageCheck.four += `${checkFormString.sixteenPage.four}`;
  }

  if (sixteenPage.five === false) {
    isError = true;
    sixteenPageCheck.five += `${checkFormString.sixteenPage.five}`;
  }
  if (sixteenPage.six === false) {
    isError = true;
    sixteenPageCheck.six += `${checkFormString.sixteenPage.six}`;
  }
  return [sixteenPageCheck, isError];
};

// 17페이지
const seventeenPageHandleCheck = (formData: any) => {
  let isError = false;
  const seventeenPageCheck: any = {
    sixOne: "",
    seven: "",
    eight: "",
    nine: "",
    teen: "",
  };

  const {
    sixteenPage,
    seventeenPage,
    twoPage,
  }: {
    seventeenPage: SeventeenPageDataTypes;
    twoPage: TwoPageTypes;
    sixteenPage: SixteenPageDataTypes;
  } = formData;

  //
  const isSix = sixteenPage.six === "1" || sixteenPage.six === "2";

  if (isSix) {
    if (seventeenPage.sixOne === false) {
      isError = true;
      seventeenPageCheck.sixOne += `${checkFormString.seventeenPage.sixOne}`;
    }
  }
  //
  let isSeven = false;
  for (let item of seventeenPage.seven) {
    if (item.number !== false) {
      isSeven = true;
    }
  }

  if (!isSeven) {
    isError = true;
    seventeenPageCheck.seven += `${checkFormString.seventeenPage.seven}`;
  }
  //
  if (seventeenPage.eight === false) {
    isError = true;
    seventeenPageCheck.eight += `${checkFormString.seventeenPage.eight}`;
  }
  //
  if (!seventeenPage.nine.one.number) {
    isError = true;
    seventeenPageCheck.nine += `${checkFormString.seventeenPage.nine.one}\n`;
  }
  if (!seventeenPage.nine.two.number) {
    isError = true;
    seventeenPageCheck.nine += `${checkFormString.seventeenPage.nine.two}\n`;
  }
  //
  if (!seventeenPage.ten.one.number) {
    isError = true;
    seventeenPageCheck.teen += `${checkFormString.seventeenPage.ten.one}\n`;
  }
  if (!seventeenPage.ten.two.number) {
    isError = true;
    seventeenPageCheck.teen += `${checkFormString.seventeenPage.ten.two}\n`;
  }
  if (!seventeenPage.ten.three.number) {
    isError = true;
    seventeenPageCheck.teen += `${checkFormString.seventeenPage.ten.three}\n`;
  }

  return [seventeenPageCheck, isError];
};

// 18페이지
const eighteenPageHandleCheck = (formData: any) => {
  let isError = false;
  const eighteenPageCheck: any = {
    eleven: "",
    twelve: "",
  };

  const {
    sixteenPage,
    eighteenPage,
    twoPage,
  }: {
    eighteenPage: EighteenPageDataTypes;
    twoPage: TwoPageTypes;
    sixteenPage: SixteenPageDataTypes;
  } = formData;

  if (eighteenPage.eleven.elevenOne === false) {
    isError = true;
    eighteenPageCheck.eleven += `${checkFormString.eighteenPage.eleven.elevenOne}\n`;
  }
  if (eighteenPage.eleven.elevenTwo === false) {
    isError = true;
    eighteenPageCheck.eleven += `${checkFormString.eighteenPage.eleven.elevenTwo}\n`;
  }
  if (eighteenPage.eleven.elevenThree === false) {
    isError = true;
    eighteenPageCheck.eleven += `${checkFormString.eighteenPage.eleven.elevenThree}\n`;
  }
  if (eighteenPage.eleven.elevenFour === false) {
    isError = true;
    eighteenPageCheck.eleven += `${checkFormString.eighteenPage.eleven.elevenFour}\n`;
  }
  if (eighteenPage.eleven.elevenFive === false) {
    isError = true;
    eighteenPageCheck.eleven += `${checkFormString.eighteenPage.eleven.elevenFive}\n`;
  }
  if (eighteenPage.eleven.elevenSix === false) {
    isError = true;
    eighteenPageCheck.eleven += `${checkFormString.eighteenPage.eleven.elevenSix}\n`;
  }
  if (eighteenPage.eleven.elevenSeven === false) {
    isError = true;
    eighteenPageCheck.eleven += `${checkFormString.eighteenPage.eleven.elevenSeven}\n`;
  }
  if (eighteenPage.eleven.elevenEight === false) {
    isError = true;
    eighteenPageCheck.eleven += `${checkFormString.eighteenPage.eleven.elevenEight}\n`;
  }
  if (eighteenPage.eleven.elevenNine.number === false) {
    isError = true;
    eighteenPageCheck.eleven += `${checkFormString.eighteenPage.eleven.elevenNine}\n`;
  }

  // if (!eighteenPage.twelve) {
  //   eighteenPageCheck.twelve = `${checkFormString.eighteenPage.twelve}`;
  // }
  return [eighteenPageCheck, isError];
};

const checkSubmitForm = (formData: any) => {
  const data = {
    twoPage: twoPageHandleCheck(formData),
    threePage: threePageHandleCheck(formData),
    fourPage: fourPageHandleCheck(formData),
    fivePage: fivePageHandleCheck(formData),
    sixPage: sixPageHandleCheck(formData),
    sevenPage: sevenPageHandleCheck(formData),
    eightPage: eightPageHandleCheck(formData),
    ninePage: ninePageHandleCheck(formData),
    teenPage: teenPageHandleCheck(formData),
    elevenPage: elevenPageHandleCheck(formData),
    twelvePage: twelvePageHandleCheck(formData),
    thirteenPage: thirteenPageHandleCheck(formData),
    fourteenPage: fourteenPageHandleCheck(formData),
    fifteenPage: fifteenPageHandleCheck(formData),
    sixteenPage: sixteenPageHandleCheck(formData),
    seventeenPage: seventeenPageHandleCheck(formData),
    eighteenPage: eighteenPageHandleCheck(formData),
  };
  const result = {
    twoPage: data.twoPage[0],
    threePage: data.threePage[0],
    fourPage: data.fourPage[0],
    fivePage: data.fivePage[0],
    sixPage: data.sixPage[0],
    sevenPage: data.sevenPage[0],
    eightPage: data.eightPage[0],
    ninePage: data.ninePage[0],
    teenPage: data.teenPage[0],
    elevenPage: data.elevenPage[0],
    twelvePage: data.twelvePage[0],
    thirteenPage: data.thirteenPage[0],
    fourteenPage: data.fourteenPage[0],
    fifteenPage: data.fifteenPage[0],
    sixteenPage: data.sixteenPage[0],
    seventeenPage: data.seventeenPage[0],
    eighteenPage: data.eighteenPage[0],
  };

  let isError = false;
  for (let [key, value] of Object.entries(data)) {
    isError += value[1];
  }
  // console.log(data);
  return [
    _.pickBy(data, function (value, key) {
      return value[1];
    }),
    isError,
  ];
};

const yearsList: any = {
  "2017": [],
  "2018": ["one"],
  "2019": ["one", "two"],
  "2020": ["one", "two", "three"],
  "2021": ["one", "two", "three", "four"],
  "2022": ["one", "two", "three", "four", "five"],
  "2023": ["one", "two", "three", "four", "five", "six"],
};

export { checkSubmitForm, yearsList };
