import NinePageComponent from "./NinePageComponent";

import React, { useState, useEffect } from "react";
import _ from "lodash";
import VoteAPI from "src/helpers/apis/vote/vote";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { useDispatch } from "react-redux";
import { login } from "src/store/actions/user";
import { NinePageTypes } from "gita";
import useUnitSave from "../../../../../hooks/useUnitSave";
import { checkSubmitForm, yearsList } from "src/ts/formSubmit";
import NinePageBlock from "./NinePageBlock";
import useSubmit from "src/hooks/useSubmit";

const NinePageContainer = ({
  voteData,
  isUpdate,
  setisUpdate,
  nextPage,
  companyName,
  serverUpdate,
  setUnitSave,
  unitSave,
  setServerUpdate,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [ninePageData, setNinePageData] = useState<NinePageTypes>();
  const [targetYear, setTargetYear] = useState("2017");
  const [eightNumber, setEightNumber] = useState<any>(false);

  useEffect(() => {
    const {
      vote: { ninePage, twoPage, eightPage },
    }: any = voteData;

    setEightNumber(eightPage.two);

    if (twoPage.info.date1 || twoPage.info.date2) {
      const date =
        Number(twoPage.info.date1) >= Number(twoPage.info.date2)
          ? twoPage.info.date1
          : twoPage.info.date2;
      setTargetYear(date);
    }

    setNinePageData(_.cloneDeep(ninePage));
  }, [voteData]);

  useEffect(() => {
    console.log(ninePageData);
  }, [ninePageData]);

  useEffect(() => {
    if (serverUpdate) {
      console.log("제출");
      VoteAPI.updateVote({
        companyName,
        vote: {
          ...voteData,
          ninePage: {
            ...ninePageData,
          },
        },
      })
        .then((res) => {
          navigate(`/vote/${nextPage}/${companyName}`);
          console.log(res);
        })
        .catch((err) => {
          if (err.response.status < 500) {
            dispatch(login(false));
            messageApi.info(
              "로그인 정보가 만료 됐습니다. 다시 로그인 해 주세요."
            );
            const token = localStorage.getItem("vote_token");
          } else {
            messageApi.error("관리자에게 문의해 주세요.");
          }
        });
    }
  }, [serverUpdate]);

  useUnitSave({
    unitSave,
    companyName,
    vote: {
      ...voteData,
      ninePage: {
        ...ninePageData,
      },
    },
    messageApi,
    setUnitSave,
    setServerUpdate,
    setisUpdate,
  });

  useSubmit({
    companyName,
    setUnitSave,
    setServerUpdate,
    setisUpdate,
    vote: {
      ...voteData.vote,
      ninePage: {
        ...ninePageData,
      },
    },
  });

  if (eightNumber !== "1") {
    return (
      <>
        <NinePageBlock eightNumber={eightNumber} />
      </>
    );
  }

  return (
    <div>
      {contextHolder}
      {ninePageData && (
        <NinePageComponent
          ninePageData={ninePageData}
          setNinePageData={setNinePageData}
          setisUpdate={setisUpdate}
          targetYear={Number(targetYear)}
        />
      )}
    </div>
  );
};

export default NinePageContainer;
