import Reactm, { useEffect, useState } from "react";
import GateComponent from "./GateComponent";
import styles from "./gate.module.css";
import CompanyAPI from "src/helpers/apis/company/company";
import { useLocation } from "react-router-dom";

import { login } from "src/store/actions/user";
import { useDispatch } from "react-redux";
import { Button, message } from "antd";

const GateContainer = ({}: any) => {
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const handleLogin = async () => {
    const pageCompany = location.pathname.split("vote")[1].slice(1);
    const cryptoCompany = pageCompany.slice(pageCompany.indexOf("/") + 1);
    console.log(location.pathname.split("vote"));
    try {
      const { token, company } = await CompanyAPI.loginCompany({
        companyName: cryptoCompany,
        code: password,
      });

      localStorage.setItem("vote_token", token);
      dispatch(login(true));
    } catch (e: any) {
      const err: any = e;
      if (err.response.status === 404) {
        messageApi.warning(
          "발급 받은 주소 및 인증번호가 올바른지 확인해 주세요."
        );
      } else if (err.response.status < 500) {
        messageApi.warning("인증번호가 틀렸습니다.");
      } else {
        messageApi.warning("관리자에게 문의해 주세요.");
      }

      console.log(err);
    }
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <GateComponent
        password={password}
        setPassword={setPassword}
        handleLogin={handleLogin}
      />
    </div>
  );
};

export default GateContainer;
