import ExcelJS from "exceljs";
import axios from "axios";
import {
  TwoPageTypes,
  ThreePageTypes,
  FourPageTypes,
  FivePageTypes,
  SixPageTypes,
  SevenPageTypes,
  EightPageTypes,
  NinePageTypes,
  TeenPageTypes,
  ElevenPageTypes,
  TwelvePageTypes,
  ThirteenPageTypes,
  FourteenPageTypes,
  FifteenPageTypes,
  SixteenPageDataTypes,
  SeventeenPageDataTypes,
  EighteenPageDataTypes,
} from "gita";
import JSZip from "jszip";
import saveAs from "file-saver";
import { autoWidth } from "./operator";

interface VoteTypes {
  twoPage: TwoPageTypes;
  threePage: ThreePageTypes;
  fourPage: FourPageTypes;
  fivePage: FivePageTypes;
  sixPage: SixPageTypes;
  sevenPage: SevenPageTypes;
  eightPage: EightPageTypes;
  ninePage: NinePageTypes;
  teenPage: TeenPageTypes;
  elevenPage: ElevenPageTypes;
  twelvePage: TwelvePageTypes;
  thirteenPage: ThirteenPageTypes;
  fourteenPage: FourteenPageTypes;
  fifteenPage: FifteenPageTypes;
  sixteenPage: SixteenPageDataTypes;
  seventeenPage: SeventeenPageDataTypes;
  eighteenPage: EighteenPageDataTypes;
  companyName?: string;
}

const inputTwoPage = (data: TwoPageTypes, worksheet: any, index: number) => {
  const { info, one, two, three } = data;

  // info
  worksheet.getRow(index).getCell("C").value = info.company;
  worksheet.getRow(index).getCell("D").value = info.projectName1;
  worksheet.getRow(index).getCell("E").value = info.date1;
  worksheet.getRow(index).getCell("F").value = info.projectName2
    ? info.projectName2
    : "";
  worksheet.getRow(index).getCell("G").value = info.date2 ? info.date2 : "";
  worksheet.getRow(index).getCell("H").value = info.responseName;
  worksheet.getRow(index).getCell("I").value = info.department;
  worksheet.getRow(index).getCell("J").value = info.phone;
  worksheet.getRow(index).getCell("K").value = info.email;

  // 1번
  worksheet.getRow(index).getCell("L").value = one.one;
  worksheet.getRow(index).getCell("M").value = one.two.number;
  if (one.two.number === "1") {
    worksheet.getRow(index).getCell("N").value = one.two.year;
  }

  worksheet.getRow(index).getCell("O").value = one.three.number;
  if (one.three.number === "1") {
    worksheet.getRow(index).getCell("P").value = one.three.year;
  }

  worksheet.getRow(index).getCell("Q").value = one.four.number;
  if (one.four.number !== "4") {
    worksheet.getRow(index).getCell("R").value = one.four.year;
  }

  worksheet.getRow(index).getCell("S").value = one.five.number;
  if (one.five.number === "1") {
    worksheet.getRow(index).getCell("T").value = one.five.year;
  }

  // 2번
  worksheet.getRow(index).getCell("U").value = two.one;
  worksheet.getRow(index).getCell("V").value = two.two;
  worksheet.getRow(index).getCell("W").value = two.three;
  worksheet.getRow(index).getCell("X").value = two.four;
  worksheet.getRow(index).getCell("Y").value = two.five;

  // 3번
  worksheet.getRow(index).getCell("Z").value = three.one;
  worksheet.getRow(index).getCell("AA").value = three.two;
  worksheet.getRow(index).getCell("AB").value = three.three;
  worksheet.getRow(index).getCell("AC").value = three.four;
};

// 3페이지
const inputThreePage = (
  data: ThreePageTypes,
  worksheet: any,
  index: number
) => {
  const { four, five } = data;

  // 4번
  worksheet.getRow(index).getCell("AD").value = four;

  // 5번
  worksheet.getRow(index).getCell("AE").value = five.one;
  worksheet.getRow(index).getCell("AF").value = five.two;
};

// 4페이지
const inputFourPage = (data: FourPageTypes, worksheet: any, index: number) => {
  const { six, seven, eight } = data;

  // 6번
  worksheet.getRow(index).getCell("AG").value = six;

  // 7번
  worksheet.getRow(index).getCell("AH").value = seven.one;
  worksheet.getRow(index).getCell("AI").value = seven.two;
  worksheet.getRow(index).getCell("AJ").value = seven.three;

  // 8 번
  worksheet.getRow(index).getCell("AK").value = eight.one;
  worksheet.getRow(index).getCell("AL").value = eight.two;
  worksheet.getRow(index).getCell("AM").value = eight.three;
};

// 5페이지
const inputFivePage = (
  data: FivePageTypes,
  worksheet: any,
  targetYear: number,
  index: number
) => {
  const { one, two, three } = data;

  // 1번
  worksheet.getRow(index).getCell("AN").value = one.one;
  worksheet.getRow(index).getCell("AO").value = one.two;
  worksheet.getRow(index).getCell("AP").value = one.three.number;
  worksheet.getRow(index).getCell("AQ").value = one.three.text
    ? one.three.text
    : "";
  worksheet.getRow(index).getCell("AR").value = one.four;

  // 2번
  worksheet.getRow(index).getCell("AS").value = two.one;

  if (two.one === "1") {
    worksheet.getRow(index).getCell("AT").value = two.two.one;
    worksheet.getRow(index).getCell("AU").value = two.two.two;
    worksheet.getRow(index).getCell("AV").value = two.two.three;
  }

  // 3 번
  if (targetYear <= 2017) {
    worksheet.getRow(index).getCell("AW").value = three.one;
  } else {
    worksheet.getRow(index).getCell("AW").value = 0;
  }
  if (targetYear <= 2018) {
    worksheet.getRow(index).getCell("AX").value = three.two;
  } else {
    worksheet.getRow(index).getCell("AX").value = 0;
  }
  if (targetYear <= 2019) {
    worksheet.getRow(index).getCell("AY").value = three.three;
  } else {
    worksheet.getRow(index).getCell("AY").value = 0;
  }
  if (targetYear <= 2020) {
    worksheet.getRow(index).getCell("AZ").value = three.four;
  } else {
    worksheet.getRow(index).getCell("AZ").value = 0;
  }
  if (targetYear <= 2021) {
    worksheet.getRow(index).getCell("BA").value = three.five;
  } else {
    worksheet.getRow(index).getCell("BA").value = 0;
  }
  if (targetYear <= 2022) {
    worksheet.getRow(index).getCell("BB").value = three.six;
  } else {
    worksheet.getRow(index).getCell("BB").value = 0;
  }
};

// 6페이지
const inputSixPage = (data: SixPageTypes, worksheet: any, index: number) => {
  const { one, two, three, four, five, six, seven, eight } = data;

  // 1번
  worksheet.getRow(index).getCell("BC").value = one;
  worksheet.getRow(index).getCell("BD").value = two;
  worksheet.getRow(index).getCell("BE").value = three;
  worksheet.getRow(index).getCell("BF").value = four;
  worksheet.getRow(index).getCell("BG").value = five;
  worksheet.getRow(index).getCell("BH").value = six;
  worksheet.getRow(index).getCell("BI").value = seven.text ? seven.text : "";
  worksheet.getRow(index).getCell("BJ").value =
    seven.number === false ? 0 : seven.number;
  worksheet.getRow(index).getCell("BK").value = eight;
};

// 7페이지
const inputSevenPage = (
  data: SevenPageTypes,
  worksheet: any,
  index: number
) => {
  const { one } = data;

  const indexList = [
    ["BL", "BM", "BN", "BO", "BP"],
    ["BQ", "BR", "BS", "BT", "BU"],
    ["BV", "BW", "BX", "BY", "BZ"],
    ["CA", "CB", "CC", "CD", "CE"],
    ["CF", "CG", "CH", "CI", "CJ"],
  ];

  // 1번
  for (let i = 0; i < one.length; i++) {
    if (one[i].one) {
      worksheet.getRow(index).getCell(indexList[i][0]).value =
        one[i].one.toString();
      worksheet.getRow(index).getCell(indexList[i][1]).value =
        one[i].two.toString();
      worksheet.getRow(index).getCell(indexList[i][2]).value =
        one[i].three.toString();
      worksheet.getRow(index).getCell(indexList[i][3]).value =
        one[i].four.toString();
      worksheet.getRow(index).getCell(indexList[i][4]).value =
        one[i].five.toString();
    }
  }
};

// 8페이지
const inputEightPage = (
  data: EightPageTypes,
  worksheet: any,
  index: number
) => {
  const { two, twoA, twoB } = data;

  // 2번
  worksheet.getRow(index).getCell("CK").value = two;

  if (two === "1") {
    worksheet.getRow(index).getCell("CL").value = twoA.one;
    worksheet.getRow(index).getCell("CM").value = twoA.two;
  } else if (two === "2") {
    worksheet.getRow(index).getCell("CN").value = twoB.one;
    worksheet.getRow(index).getCell("CO").value = twoB.two;
  }
};

// 9페이지
const inputNinePage = (
  data: NinePageTypes,
  worksheet: any,
  targetYear: number,
  index: number
) => {
  const { one, two, three } = data;

  worksheet.getRow(index).getCell("CP").value = one;

  const yearsList: any = {
    "2017": [],
    "2018": ["one"],
    "2019": ["one", "two"],
    "2020": ["one", "two", "three"],
    "2021": ["one", "two", "three", "four"],
    "2022": ["one", "two", "three", "four"],
  };

  const years: string[] = yearsList[targetYear];

  if (one === "1") {
    const cellIndex: any = {
      one: ["CQ", "CR", "CS", "CT"],
      two: ["CU", "CV", "CW", "CX"],
      three: ["CY", "CZ", "DA", "DB"],
      four: ["DC", "DD", "DE", "DF"],
      five: ["DG", "DH", "DI", "DJ"],
    };
    for (let [key, value] of Object.entries(two)) {
      if (!years.includes(key)) {
        worksheet.getRow(index).getCell(cellIndex[key][0]).value = value.one;
        worksheet.getRow(index).getCell(cellIndex[key][1]).value = value.two;
        worksheet.getRow(index).getCell(cellIndex[key][2]).value = value.three;
        worksheet.getRow(index).getCell(cellIndex[key][3]).value = value.four;
      }
    }
  } else if (one === "2") {
    const cellIndex: any = {
      one: ["DK", "DL", "DM", "DN"],
      two: ["DO", "DP", "DQ", "DR"],
      three: ["DS", "DT", "DU", "DV"],
      four: ["DW", "DX", "DY", "DZ"],
      five: ["EA", "EB", "EC", "ED"],
    };
    for (let [key, value] of Object.entries(three)) {
      if (!years.includes(key)) {
        worksheet.getRow(index).getCell(cellIndex[key][0]).value = value.one;
        worksheet.getRow(index).getCell(cellIndex[key][1]).value = value.two;
        worksheet.getRow(index).getCell(cellIndex[key][2]).value = value.three;
        worksheet.getRow(index).getCell(cellIndex[key][3]).value = value.four;
      }
    }
  }
};

// 10페이지
const inputTeenPage = (
  data: TeenPageTypes,
  worksheet: any,
  targetYear: number,
  index: number
) => {
  const { two, three, four, five, twoOne } = data;

  worksheet.getRow(index).getCell("EE").value = two;

  //
  if (two === "1") {
    const yearsList: any = {
      "2017": [],
      "2018": ["one"],
      "2019": ["one", "two"],
      "2020": ["one", "two", "three"],
      "2021": ["one", "two", "three", "four"],
      "2022": ["one", "two", "three", "four"],
    };

    const years: string[] = yearsList[targetYear];
    const cellIndex: any = {
      one: "EF",
      two: "EG",
      three: "EH",
      four: "EI",
      five: "EJ",
    };
    for (let [key, value] of Object.entries(twoOne)) {
      if (!years.includes(key)) {
        worksheet.getRow(index).getCell(cellIndex[key]).value = value;
        worksheet.getRow(index).getCell(cellIndex[key]).value = value;
        worksheet.getRow(index).getCell(cellIndex[key]).value = value;
        worksheet.getRow(index).getCell(cellIndex[key]).value = value;
      }
    }
  }

  //
  worksheet.getRow(index).getCell("EK").value = three.one;
  worksheet.getRow(index).getCell("EL").value = three.two;
  worksheet.getRow(index).getCell("EM").value = three.three;
  worksheet.getRow(index).getCell("EN").value = three.four;
  worksheet.getRow(index).getCell("EO").value = three.five;
  const yearsList: any = {
    "2017": [],
    "2018": ["one"],
    "2019": ["one", "two"],
    "2020": ["one", "two", "three"],
    "2021": ["one", "two", "three", "four"],
    "2022": ["one", "two", "three", "four", "five"],
  };

  const years: string[] = yearsList[targetYear];
  //
  const cellIndex: any = {
    one: ["EP", "EQ"],
    two: ["ER", "ES"],
    three: ["ET", "EU"],
    four: ["EV", "EW"],
    five: ["EX", "EY"],
    six: ["EZ", "FA"],
    seven: ["FB", "FC"],
  };
  for (let [key, value] of Object.entries(four)) {
    if (!years.includes(key)) {
      worksheet.getRow(index).getCell(cellIndex[key][0]).value = value.one;
      worksheet.getRow(index).getCell(cellIndex[key][1]).value = value.two;
    }
  }

  //
  worksheet.getRow(index).getCell("FD").value = five;
};

// 11페이지
const inputElevenPage = (
  data: ElevenPageTypes,
  worksheet: any,
  targetYear: number,
  index: number
) => {
  const { one, two, three } = data;

  worksheet.getRow(index).getCell("FE").value = one;

  const yearsList: any = {
    "2017": [],
    "2018": ["one"],
    "2019": ["one", "two"],
    "2020": ["one", "two", "three"],
    "2021": ["one", "two", "three", "four"],
    "2022": ["one", "two", "three", "four"],
  };

  const years: string[] = yearsList[targetYear];

  if (one === "1") {
    const cellIndex: any = {
      one: ["FF", "FG", "FH", "FI"],
      two: ["FJ", "FK", "FL", "FM"],
      three: ["FN", "FO", "FP", "FQ"],
      four: ["FR", "FS", "FT", "FU"],
      five: ["FV", "FW", "FX", "FY"],
    };
    for (let [key, value] of Object.entries(two)) {
      if (!years.includes(key)) {
        worksheet.getRow(index).getCell(cellIndex[key][0]).value = value.one;
        worksheet.getRow(index).getCell(cellIndex[key][1]).value = value.two;
        worksheet.getRow(index).getCell(cellIndex[key][2]).value = value.three;
        worksheet.getRow(index).getCell(cellIndex[key][3]).value = value.four;
      }
    }
  } else if (one === "2") {
    const cellIndex: any = {
      one: ["FZ", "GA", "GB", "GC"],
      two: ["GD", "GE", "GF", "GG"],
      three: ["GH", "GI", "GJ", "GK"],
      four: ["GL", "GM", "GN", "GO"],
      five: ["GP", "GQ", "GR", "GS"],
    };
    for (let [key, value] of Object.entries(three)) {
      if (!years.includes(key)) {
        worksheet.getRow(index).getCell(cellIndex[key][0]).value = value.one;
        worksheet.getRow(index).getCell(cellIndex[key][1]).value = value.two;
        worksheet.getRow(index).getCell(cellIndex[key][2]).value = value.three;
        worksheet.getRow(index).getCell(cellIndex[key][3]).value = value.four;
      }
    }
  }
};

// 12페이지
const inputTwelvePage = (
  data: TwelvePageTypes,
  worksheet: any,
  targetYear: number,
  index: number
) => {
  const { two, three, four, five, twoOne } = data;

  worksheet.getRow(index).getCell("GT").value = two;

  //
  if (two === "1") {
    const yearsList: any = {
      "2017": [],
      "2018": ["one"],
      "2019": ["one", "two"],
      "2020": ["one", "two", "three"],
      "2021": ["one", "two", "three", "four"],
      "2022": ["one", "two", "three", "four"],
    };

    const years: string[] = yearsList[targetYear];
    const cellIndex: any = {
      one: "GU",
      two: "GV",
      three: "GW",
      four: "GX",
      five: "GY",
    };
    for (let [key, value] of Object.entries(twoOne)) {
      if (!years.includes(key)) {
        worksheet.getRow(index).getCell(cellIndex[key]).value = value;
        worksheet.getRow(index).getCell(cellIndex[key]).value = value;
        worksheet.getRow(index).getCell(cellIndex[key]).value = value;
        worksheet.getRow(index).getCell(cellIndex[key]).value = value;
      }
    }
  }

  //
  worksheet.getRow(index).getCell("GZ").value = three.one;
  worksheet.getRow(index).getCell("HA").value = three.two;
  worksheet.getRow(index).getCell("HB").value = three.three;
  worksheet.getRow(index).getCell("HC").value = three.four;
  worksheet.getRow(index).getCell("HD").value = three.five;

  const yearsList: any = {
    "2017": [],
    "2018": ["one"],
    "2019": ["one", "two"],
    "2020": ["one", "two", "three"],
    "2021": ["one", "two", "three", "four"],
    "2022": ["one", "two", "three", "four", "five", "six"],
  };

  const years: string[] = yearsList[targetYear];

  //
  const cellIndex: any = {
    one: ["HE", "HF"],
    two: ["HG", "HH"],
    three: ["HI", "HJ"],
    four: ["HK", "HL"],
    five: ["HM", "HN"],
    six: ["HO", "HP"],
    seven: ["HQ", "HR"],
  };
  for (let [key, value] of Object.entries(four)) {
    if (!years.includes(key)) {
      worksheet.getRow(index).getCell(cellIndex[key][0]).value = value.one;
      worksheet.getRow(index).getCell(cellIndex[key][1]).value = value.two;
    }
  }

  //
  worksheet.getRow(index).getCell("HS").value = five;
};

// 13페이지
const inputThirteenPage = (
  data: ThirteenPageTypes,
  worksheet: any,
  index: number,
  targetYear: number
) => {
  const { one, two } = data;

  //
  worksheet.getRow(index).getCell("HT").value = one.one.one;
  worksheet.getRow(index).getCell("HU").value = one.one.two;
  worksheet.getRow(index).getCell("HV").value = one.one.three;

  //
  worksheet.getRow(index).getCell("HW").value = one.two.one;
  worksheet.getRow(index).getCell("HX").value = one.two.two;
  worksheet.getRow(index).getCell("HY").value = one.two.three;
  worksheet.getRow(index).getCell("HZ").value = one.two.four;

  //
  worksheet.getRow(index).getCell("IA").value = one.three.one;
  worksheet.getRow(index).getCell("IB").value = one.three.two;

  //
  // worksheet.getRow(index).getCell("IC").value = two.one.year;
  worksheet.getRow(index).getCell("ID").value = two.one.step;

  // worksheet.getRow(index).getCell("IE").value = two.two.year;
  if (targetYear !== 2022) {
    worksheet.getRow(index).getCell("IF").value = two.two.step;
  }
  //
  worksheet.getRow(index).getCell("IG").value = two.three.step;
};

// 14페이지
const inputFourteenPage = (
  data: FourteenPageTypes,
  worksheet: any,
  index: number
) => {
  const { one, oneOne, oneTwo, oneThree } = data;

  //
  worksheet.getRow(index).getCell("IH").value = one.one;
  worksheet.getRow(index).getCell("II").value = one.two;
  worksheet.getRow(index).getCell("IJ").value = one.three;
  worksheet.getRow(index).getCell("IK").value = one.four;
  worksheet.getRow(index).getCell("IL").value = one.five;
  worksheet.getRow(index).getCell("IM").value = one.six;
  worksheet.getRow(index).getCell("IN").value = one.seven;

  //
  worksheet.getRow(index).getCell("IO").value = oneOne;

  //
  worksheet.getRow(index).getCell("IP").value = oneTwo;

  //
  worksheet.getRow(index).getCell("IQ").value = oneThree;
};

// 15페이지
const inputFifteenPage = (
  data: FifteenPageTypes,
  worksheet: any,
  index: number
) => {
  const { two, three, four, five } = data;

  //
  worksheet.getRow(index).getCell("IR").value = two.one;
  worksheet.getRow(index).getCell("IS").value = two.two;

  //
  worksheet.getRow(index).getCell("IT").value = three.one.one;
  worksheet.getRow(index).getCell("IU").value = three.one.two;
  worksheet.getRow(index).getCell("IV").value = three.one.three
    ? three.one.three
    : "";

  //
  worksheet.getRow(index).getCell("IW").value = three.two.one;
  worksheet.getRow(index).getCell("IX").value = three.two.two;
  worksheet.getRow(index).getCell("IY").value = three.two.three
    ? three.two.three
    : "";

  //
  worksheet.getRow(index).getCell("IZ").value = four.one;

  if (four.one === "1") {
    worksheet.getRow(index).getCell("JA").value = four.two;
    worksheet.getRow(index).getCell("JB").value = four.three;
  }

  //
  worksheet.getRow(index).getCell("JC").value = five.one;
  worksheet.getRow(index).getCell("JD").value = five.two;
};

// 16페이지
const inputSixteenPage = (
  data: SixteenPageDataTypes,
  worksheet: any,
  rowIndex: number
) => {
  const { one, two, three, four, five, six } = data;

  const cellIndex = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

  // 1
  worksheet.getRow(rowIndex).getCell("JE").value = one;

  // 2
  worksheet.getRow(rowIndex).getCell("JF").value = two.number;
  if (two.number === "5") {
    worksheet.getRow(rowIndex).getCell("JG").value = two.text
      ? `${two.text}`
      : "";
  }

  // 3
  worksheet.getRow(rowIndex).getCell("JH").value = three.number;
  if (three.number === "5") {
    worksheet.getRow(rowIndex).getCell("JI").value = three.text
      ? `${three.text}`
      : "";
  }

  // 4
  const fourCol = ["JJ", "JK", "JL", "JM", "JN", "JO", "JP", "JQ", "JR", "JS"];
  let index = 0;
  for (let i = 0; i < four.length; i++) {
    if (four[i].number) {
      worksheet.getRow(rowIndex).getCell(fourCol[index]).value = i + 1;
      index += 1;

      if (i === 8) {
        if (four[i].text) {
          worksheet.getRow(rowIndex).getCell("JS").value = `${four[i].text}`;
        }
      }
    }
  }

  // 5
  worksheet.getRow(rowIndex).getCell("JT").value = five;

  // 6
  worksheet.getRow(rowIndex).getCell("JU").value = six;
};

// 17페이지
const inputSeventeenPage = (
  data: SeventeenPageDataTypes,
  worksheet: any,
  rowIndex: number
) => {
  const { sixOne, seven, nine, ten, eight } = data;

  // 6-1
  if (sixOne) {
    worksheet.getRow(rowIndex).getCell("JV").value = sixOne;
  }

  // 7
  let index = 0;
  const sevenCol = ["JW", "JX", "JY", "JZ", "KA", "KB"];
  for (let i = 0; i < seven.length; i++) {
    if (seven[i].number) {
      worksheet.getRow(rowIndex).getCell(sevenCol[index]).value = i + 1;

      index += 1;
      if (i === 4) {
        worksheet.getRow(rowIndex).getCell("KB").value = `${seven[i].text}`;
      }
    }
  }

  // 8
  worksheet.getRow(rowIndex).getCell("KC").value = eight;

  // 9
  worksheet.getRow(rowIndex).getCell("KD").value = nine.one.number;
  worksheet.getRow(rowIndex).getCell("KE").value = nine.two.number;

  if (nine.one.number === "6") {
    worksheet.getRow(rowIndex).getCell("KF").value = `${nine.one.text}`;
  }

  if (nine.two.number === "6") {
    worksheet.getRow(rowIndex).getCell("KF").value = `${nine.two.text}`;
  }

  // 10
  worksheet.getRow(rowIndex).getCell("KG").value = ten.one.number;
  worksheet.getRow(rowIndex).getCell("KH").value = ten.two.number;
  worksheet.getRow(rowIndex).getCell("KI").value = ten.three.number;

  if (ten.one.number === "8") {
    worksheet.getRow(rowIndex).getCell("KJ").value = ten.one.text;
  }

  if (ten.two.number === "8") {
    worksheet.getRow(rowIndex).getCell("KJ").value = ten.two.text;
  }

  if (ten.three.number === "8") {
    worksheet.getRow(rowIndex).getCell("KJ").value = ten.three.text;
  }
};

// 18페이지
const inputEighteenPage = (
  data: EighteenPageDataTypes,
  worksheet: any,
  index: number
) => {
  const { eleven, twelve } = data;

  //
  worksheet.getRow(index).getCell("KK").value = eleven.elevenOne;
  worksheet.getRow(index).getCell("KL").value = eleven.elevenTwo;
  worksheet.getRow(index).getCell("KM").value = eleven.elevenThree;
  worksheet.getRow(index).getCell("KN").value = eleven.elevenFour;
  worksheet.getRow(index).getCell("KO").value = eleven.elevenFive;
  worksheet.getRow(index).getCell("KP").value = eleven.elevenSix;
  worksheet.getRow(index).getCell("KQ").value = eleven.elevenSeven;
  worksheet.getRow(index).getCell("KR").value = eleven.elevenEight;

  if (eleven.elevenNine.text) {
    worksheet.getRow(index).getCell("KS").value = eleven.elevenNine.text;
  }
  if (eleven.elevenNine.number) {
    worksheet.getRow(index).getCell("KT").value = eleven.elevenNine.number;
  }

  //
  worksheet.getRow(index).getCell("KU").value = twelve ? twelve : "";
};

const voteListDownload = async (
  worksheet: any,
  vote: VoteTypes,
  index: number,
  companyName: string
) => {
  const targetYear = Number(
    Number(vote.twoPage.info.date1) >= Number(vote.twoPage.info.date2)
      ? vote.twoPage.info.date1
      : vote.twoPage.info.date2
  );

  worksheet.getRow(index).getCell("A").value = index - 1;
  worksheet.getRow(index).getCell("B").value = companyName;

  inputTwoPage(vote.twoPage, worksheet, index);
  inputThreePage(vote.threePage, worksheet, index);
  inputFourPage(vote.fourPage, worksheet, index);
  inputFivePage(vote.fivePage, worksheet, targetYear, index);
  inputSixPage(vote.sixPage, worksheet, index);
  inputSevenPage(vote.sevenPage, worksheet, index);
  inputEightPage(vote.eightPage, worksheet, index);
  if (vote.eightPage.two === "1") {
    inputNinePage(vote.ninePage, worksheet, targetYear, index);
    inputTeenPage(vote.teenPage, worksheet, targetYear, index);
  } else if (vote.eightPage.two === "2") {
    inputElevenPage(vote.elevenPage, worksheet, targetYear, index);
    inputTwelvePage(vote.twelvePage, worksheet, targetYear, index);
  }

  inputThirteenPage(vote.thirteenPage, worksheet, index, targetYear);
  inputFourteenPage(vote.fourteenPage, worksheet, index);
  inputFifteenPage(vote.fifteenPage, worksheet, index);
  inputSixteenPage(vote.sixteenPage, worksheet, index);
  inputSeventeenPage(vote.seventeenPage, worksheet, index);
  inputEighteenPage(vote.eighteenPage, worksheet, index);
};

const rowZipDesignFile = async (voteList: any) => {
  const zip = new JSZip();

  const res = await axios({
    url: "/row_data.xlsx",
    responseType: "arraybuffer",
  });

  let workbook = new ExcelJS.Workbook();
  workbook = await workbook.xlsx.load(res.data);

  let worksheet = workbook.getWorksheet("Sheet1");

  const dataList = await Promise.all(
    voteList.map((item: any, index: number) => {
      return {
        file: voteListDownload(worksheet, item, index + 2, item.companyName),
        companyName: item.companyName,
      };
    })
  );

  await Promise.all([...dataList]);
  // await autoWidth(worksheet);
  const uint8Array = await workbook.xlsx.writeBuffer();
  const blob = new Blob([uint8Array], { type: "application/octet-binary" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `total.xlsx`;
  a.click();
  a.remove();
};
export { rowZipDesignFile };
