import ExcelJS from "exceljs";
import axios from "axios";
import {
  TwoPageTypes,
  ThreePageTypes,
  FourPageTypes,
  FivePageTypes,
  SixPageTypes,
  SevenPageTypes,
  EightPageTypes,
  NinePageTypes,
  TeenPageTypes,
  ElevenPageTypes,
  TwelvePageTypes,
  ThirteenPageTypes,
  FourteenPageTypes,
  FifteenPageTypes,
  SixteenPageDataTypes,
  SeventeenPageDataTypes,
  EighteenPageDataTypes,
} from "gita";
import JSZip from "jszip";
import saveAs from "file-saver";
import { autoWidth } from "./operator";

interface VoteTypes {
  twoPage: TwoPageTypes;
  threePage: ThreePageTypes;
  fourPage: FourPageTypes;
  fivePage: FivePageTypes;
  sixPage: SixPageTypes;
  sevenPage: SevenPageTypes;
  eightPage: EightPageTypes;
  ninePage: NinePageTypes;
  teenPage: TeenPageTypes;
  elevenPage: ElevenPageTypes;
  twelvePage: TwelvePageTypes;
  thirteenPage: ThirteenPageTypes;
  fourteenPage: FourteenPageTypes;
  fifteenPage: FifteenPageTypes;
  sixteenPage: SixteenPageDataTypes;
  seventeenPage: SeventeenPageDataTypes;
  eighteenPage: EighteenPageDataTypes;
  companyName?: string;
}

const inputTwoPage = (data: TwoPageTypes, worksheet: any) => {
  const { info, one, two, three } = data;

  // info
  worksheet.getRow(2).getCell("B").value = info.company;
  worksheet.getRow(3).getCell("B").value = info.projectName1;
  worksheet.getRow(3).getCell("D").value = info.date1;
  worksheet.getRow(4).getCell("B").value = info.projectName2
    ? info.projectName2
    : "";
  worksheet.getRow(4).getCell("D").value = info.date2 ? info.date2 : "";
  worksheet.getRow(5).getCell("B").value = info.responseName;
  worksheet.getRow(5).getCell("D").value = info.department;
  worksheet.getRow(6).getCell("B").value = info.phone;
  worksheet.getRow(6).getCell("D").value = info.email;

  // 1번
  worksheet.getRow(11).getCell("A").value = one.one;
  worksheet.getRow(11).getCell("B").value = one.two.number;
  worksheet.getRow(11).getCell("C").value = one.three.number;
  worksheet.getRow(11).getCell("D").value = one.four.number;
  worksheet.getRow(11).getCell("E").value = one.five.number;

  if (one.two.number === "1") {
    worksheet.getRow(13).getCell("B").value = one.two.year;
  }
  if (one.three.number === "1") {
    worksheet.getRow(13).getCell("C").value = one.three.year;
  }
  if (one.four.number !== "4") {
    worksheet.getRow(13).getCell("D").value = one.four.year;
  }
  if (one.five.number === "1") {
    worksheet.getRow(13).getCell("E").value = one.five.year;
  }

  // 2번
  worksheet.getRow(18).getCell("A").value = two.one;

  worksheet.getRow(19).getCell("B").value = two.two;
  worksheet.getRow(19).getCell("C").value = two.three;
  worksheet.getRow(19).getCell("D").value = two.four;
  worksheet.getRow(19).getCell("E").value = two.five;

  // 3번
  worksheet.getRow(23).getCell("B").value = three.one;
  worksheet.getRow(24).getCell("B").value = three.two;
  worksheet.getRow(25).getCell("B").value = three.three;
  worksheet.getRow(26).getCell("B").value = three.four;
  worksheet.getRow(27).getCell("B").value = 100;
};

// 3페이지
const inputThreePage = (data: ThreePageTypes, worksheet: any) => {
  const { four, five } = data;

  // 4번
  worksheet.getRow(31).getCell("A").value = four;

  // 5번
  worksheet.getRow(36).getCell("A").value = five.one;
  worksheet.getRow(36).getCell("B").value = five.two;
};

// 4페이지
const inputFourPage = (data: FourPageTypes, worksheet: any) => {
  const { six, seven, eight } = data;

  // 6번
  worksheet.getRow(40).getCell("A").value = six;

  // 7번
  worksheet.getRow(44).getCell("A").value = seven.one;
  worksheet.getRow(45).getCell("A").value = seven.two;
  worksheet.getRow(46).getCell("A").value = seven.three;

  // 8 번
  worksheet.getRow(50).getCell("A").value = eight.one;
  worksheet.getRow(51).getCell("A").value = eight.two;
  worksheet.getRow(52).getCell("A").value = eight.three;
};

// 5페이지
const inputFivePage = (data: FivePageTypes, worksheet: any) => {
  const { one, two, three } = data;

  // 1번
  worksheet.getRow(56).getCell("B").value = one.one;
  worksheet.getRow(57).getCell("B").value = one.two;
  worksheet.getRow(58).getCell("B").value = one.three.number;
  worksheet.getRow(58).getCell("D").value = one.three.text
    ? one.three.text
    : "";
  worksheet.getRow(59).getCell("B").value = one.four;

  // 2번
  worksheet.getRow(63).getCell("A").value = two.one;

  if (two.one === "1") {
    worksheet.getRow(67).getCell("B").value = two.two.one;
    worksheet.getRow(68).getCell("B").value = two.two.two;
    worksheet.getRow(69).getCell("B").value = two.two.three;
  }

  // 3 번
  worksheet.getRow(74).getCell("A").value = three.one;
  worksheet.getRow(74).getCell("B").value = three.two;
  worksheet.getRow(74).getCell("C").value = three.three;
  worksheet.getRow(74).getCell("D").value = three.four;
  worksheet.getRow(74).getCell("E").value = three.five;
  worksheet.getRow(74).getCell("F").value = three.six;
};

// 6페이지
const inputSixPage = (data: SixPageTypes, worksheet: any) => {
  const { one, two, three, four, five, six, seven, eight } = data;

  // 1번
  worksheet.getRow(78).getCell("C").value = one;
  worksheet.getRow(79).getCell("C").value = two;
  worksheet.getRow(80).getCell("C").value = three;
  worksheet.getRow(81).getCell("C").value = four;
  worksheet.getRow(82).getCell("C").value = five;
  worksheet.getRow(83).getCell("C").value = six;
  worksheet.getRow(84).getCell("B").value = seven.text ? seven.text : "";
  worksheet.getRow(84).getCell("C").value =
    seven.number === false ? "0" : seven.number;
  worksheet.getRow(85).getCell("C").value = eight;
};

// 7페이지
const inputSevenPage = (data: SevenPageTypes, worksheet: any) => {
  const { one } = data;

  // 1번
  for (let i = 0; i < one.length; i++) {
    if (one[i].one) {
      worksheet.getRow(90 + i).getCell("B").value = one[i].one.toString();
      worksheet.getRow(90 + i).getCell("C").value = one[i].two.toString();
      worksheet.getRow(90 + i).getCell("D").value = one[i].three.toString();
      worksheet.getRow(90 + i).getCell("E").value = one[i].four.toString();
      worksheet.getRow(90 + i).getCell("F").value = one[i].five.toString();
    }
  }
};

// 8페이지
const inputEightPage = (data: EightPageTypes, worksheet: any) => {
  const { two, twoA, twoB } = data;

  // 2번
  worksheet.getRow(98).getCell("A").value = two;

  if (two === "1") {
    worksheet.getRow(103).getCell("A").value = twoA.one;
    worksheet.getRow(103).getCell("B").value = twoA.two;
  } else if (two === "2") {
    worksheet.getRow(108).getCell("A").value = twoB.one;
    worksheet.getRow(108).getCell("B").value = twoB.two;
  }
};

// 9페이지
const inputNinePage = (
  data: NinePageTypes,
  worksheet: any,
  targetYear: number
) => {
  const { one, two, three } = data;

  worksheet.getRow(112).getCell("A").value = one;

  const yearsList: any = {
    "2017": [],
    "2018": ["one"],
    "2019": ["one", "two"],
    "2020": ["one", "two", "three"],
    "2021": ["one", "two", "three", "four"],
    "2022": ["one", "two", "three", "four"],
  };

  const years: string[] = yearsList[targetYear];
  if (one === "1") {
    const cellIndex: any = {
      one: 118,
      two: 119,
      three: 120,
      four: 121,
      five: 122,
    };
    for (let [key, value] of Object.entries(two)) {
      if (!years.includes(key)) {
        worksheet.getRow(cellIndex[key]).getCell("B").value = value.one;
        worksheet.getRow(cellIndex[key]).getCell("C").value = value.two;
        worksheet.getRow(cellIndex[key]).getCell("D").value = value.three;
        worksheet.getRow(cellIndex[key]).getCell("E").value = value.four;
      }
    }
  } else if (one === "2") {
    const cellIndex: any = {
      one: 128,
      two: 129,
      three: 130,
      four: 131,
      five: 132,
    };
    for (let [key, value] of Object.entries(three)) {
      if (!years.includes(key)) {
        worksheet.getRow(cellIndex[key]).getCell("B").value = value.one;
        worksheet.getRow(cellIndex[key]).getCell("C").value = value.two;
        worksheet.getRow(cellIndex[key]).getCell("D").value = value.three;
        worksheet.getRow(cellIndex[key]).getCell("E").value = value.four;
      }
    }
  }
};

// 10페이지
const inputTeenPage = (
  data: TeenPageTypes,
  worksheet: any,
  targetYear: number
) => {
  const { two, three, four, five, twoOne } = data;

  worksheet.getRow(136).getCell("A").value = two;

  //
  if (two === "1") {
    const yearsList: any = {
      "2017": [],
      "2018": ["one"],
      "2019": ["one", "two"],
      "2020": ["one", "two", "three"],
      "2021": ["one", "two", "three", "four"],
      "2022": ["one", "two", "three", "four"],
    };

    const years: string[] = yearsList[targetYear];
    const cellIndex: any = {
      one: 141,
      two: 142,
      three: 143,
      four: 144,
      five: 145,
    };
    for (let [key, value] of Object.entries(twoOne)) {
      if (!years.includes(key)) {
        worksheet.getRow(cellIndex[key]).getCell("B").value = value;
        worksheet.getRow(cellIndex[key]).getCell("B").value = value;
        worksheet.getRow(cellIndex[key]).getCell("B").value = value;
        worksheet.getRow(cellIndex[key]).getCell("B").value = value;
      }
    }
  }

  //
  worksheet.getRow(150).getCell("A").value = three.one;
  worksheet.getRow(151).getCell("B").value = three.two;
  worksheet.getRow(151).getCell("C").value = three.three;
  worksheet.getRow(151).getCell("D").value = three.four;
  worksheet.getRow(151).getCell("E").value = three.five;

  //

  const yearsList: any = {
    "2017": [],
    "2018": ["one"],
    "2019": ["one", "two"],
    "2020": ["one", "two", "three"],
    "2021": ["one", "two", "three", "four"],
    "2022": ["one", "two", "three", "four", "five"],
  };

  const years: string[] = yearsList[targetYear];
  const cellIndex: any = {
    one: "B",
    two: "C",
    three: "D",
    four: "E",
    five: "F",
    six: "G",
    seven: "H",
  };
  for (let [key, value] of Object.entries(four)) {
    if (!years.includes(key)) {
      worksheet.getRow(156).getCell(cellIndex[key]).value = value.one;
      worksheet.getRow(157).getCell(cellIndex[key]).value = value.two;
    }
  }

  //
  worksheet.getRow(162).getCell("B").value = five;
};

// 11페이지
const inputElevenPage = (
  data: ElevenPageTypes,
  worksheet: any,
  targetYear: number
) => {
  const { one, two, three } = data;

  worksheet.getRow(166).getCell("A").value = one;

  const yearsList: any = {
    "2017": [],
    "2018": ["one"],
    "2019": ["one", "two"],
    "2020": ["one", "two", "three"],
    "2021": ["one", "two", "three", "four"],
    "2022": ["one", "two", "three", "four"],
  };

  const years: string[] = yearsList[targetYear];

  if (one === "1") {
    const cellIndex: any = {
      one: 172,
      two: 173,
      three: 174,
      four: 175,
      five: 176,
    };
    for (let [key, value] of Object.entries(two)) {
      if (!years.includes(key)) {
        worksheet.getRow(cellIndex[key]).getCell("B").value = value.one;
        worksheet.getRow(cellIndex[key]).getCell("C").value = value.two;
        worksheet.getRow(cellIndex[key]).getCell("D").value = value.three;
        worksheet.getRow(cellIndex[key]).getCell("E").value = value.four;
      }
    }
  } else if (one === "2") {
    const cellIndex: any = {
      one: 182,
      two: 183,
      three: 184,
      four: 185,
      five: 186,
    };
    for (let [key, value] of Object.entries(three)) {
      if (!years.includes(key)) {
        worksheet.getRow(cellIndex[key]).getCell("B").value = value.one;
        worksheet.getRow(cellIndex[key]).getCell("C").value = value.two;
        worksheet.getRow(cellIndex[key]).getCell("D").value = value.three;
        worksheet.getRow(cellIndex[key]).getCell("E").value = value.four;
      }
    }
  }
};

// 12페이지
const inputTwelvePage = (
  data: TwelvePageTypes,
  worksheet: any,
  targetYear: number
) => {
  const { two, three, four, five, twoOne } = data;

  worksheet.getRow(190).getCell("A").value = two;

  //
  if (two === "1") {
    const yearsList: any = {
      "2017": [],
      "2018": ["one"],
      "2019": ["one", "two"],
      "2020": ["one", "two", "three"],
      "2021": ["one", "two", "three", "four"],
      "2022": ["one", "two", "three", "four"],
    };

    const years: string[] = yearsList[targetYear];
    const cellIndex: any = {
      one: 195,
      two: 196,
      three: 197,
      four: 198,
      five: 199,
    };
    for (let [key, value] of Object.entries(twoOne)) {
      if (!years.includes(key)) {
        worksheet.getRow(cellIndex[key]).getCell("B").value = value;
        worksheet.getRow(cellIndex[key]).getCell("B").value = value;
        worksheet.getRow(cellIndex[key]).getCell("B").value = value;
        worksheet.getRow(cellIndex[key]).getCell("B").value = value;
      }
    }
  }

  //
  worksheet.getRow(204).getCell("A").value = three.one;
  worksheet.getRow(205).getCell("B").value = three.two;
  worksheet.getRow(205).getCell("C").value = three.three;
  worksheet.getRow(205).getCell("D").value = three.four;
  worksheet.getRow(205).getCell("E").value = three.five;
  const yearsList: any = {
    "2017": [],
    "2018": ["one"],
    "2019": ["one", "two"],
    "2020": ["one", "two", "three"],
    "2021": ["one", "two", "three", "four"],
    "2022": ["one", "two", "three", "four", "five", "six"],
  };

  const years: string[] = yearsList[targetYear];
  //
  const cellIndex: any = {
    one: "B",
    two: "C",
    three: "D",
    four: "E",
    five: "F",
    six: "G",
    seven: "H",
  };
  for (let [key, value] of Object.entries(four)) {
    if (!years.includes(key)) {
      worksheet.getRow(210).getCell(cellIndex[key]).value = value.one;
      worksheet.getRow(211).getCell(cellIndex[key]).value = value.two;
    }
  }

  //
  worksheet.getRow(216).getCell("B").value = five;
};

// 13페이지
const inputThirteenPage = (data: ThirteenPageTypes, worksheet: any) => {
  const { one, two } = data;

  //
  worksheet.getRow(220).getCell("B").value = one.one.one;
  worksheet.getRow(221).getCell("B").value = one.one.two;
  worksheet.getRow(222).getCell("B").value = one.one.three;

  //
  worksheet.getRow(226).getCell("B").value = one.two.one;
  worksheet.getRow(227).getCell("B").value = one.two.two;
  worksheet.getRow(228).getCell("B").value = one.two.three;
  worksheet.getRow(229).getCell("B").value = one.two.four;

  //
  worksheet.getRow(233).getCell("B").value = one.three.one;
  worksheet.getRow(234).getCell("B").value = one.three.two;

  //
  worksheet.getRow(238).getCell("B").value = two.one.year;
  worksheet.getRow(238).getCell("D").value = two.two.year;

  //
  worksheet.getRow(239).getCell("B").value = two.one.step;
  worksheet.getRow(239).getCell("D").value = two.two.step;
  worksheet.getRow(239).getCell("F").value = two.three.step;
};

// 14페이지
const inputFourteenPage = (data: FourteenPageTypes, worksheet: any) => {
  const { one, oneOne, oneTwo, oneThree } = data;

  //
  worksheet.getRow(243).getCell("A").value = one.one;
  worksheet.getRow(244).getCell("A").value = one.two;
  worksheet.getRow(245).getCell("A").value = one.three;
  worksheet.getRow(246).getCell("A").value = one.four;
  worksheet.getRow(247).getCell("A").value = one.five;
  worksheet.getRow(248).getCell("A").value = one.six;
  worksheet.getRow(249).getCell("A").value = one.seven;

  //
  worksheet.getRow(253).getCell("A").value = oneOne;

  //
  worksheet.getRow(257).getCell("A").value = oneTwo;

  //
  worksheet.getRow(261).getCell("A").value = oneThree;
};

// 15페이지
const inputFifteenPage = (data: FifteenPageTypes, worksheet: any) => {
  const { two, three, four, five } = data;

  //
  worksheet.getRow(265).getCell("B").value = two.one;
  worksheet.getRow(266).getCell("B").value = two.two;

  //
  worksheet.getRow(270).getCell("B").value = three.one.one;
  worksheet.getRow(271).getCell("B").value = three.one.two;
  worksheet.getRow(272).getCell("B").value = three.one.three
    ? three.one.three
    : "";

  //
  worksheet.getRow(276).getCell("B").value = three.two.one;
  worksheet.getRow(277).getCell("B").value = three.two.two;
  worksheet.getRow(278).getCell("B").value = three.two.three
    ? three.two.three
    : "";

  //
  worksheet.getRow(282).getCell("A").value = four.one;

  if (four.one === "1") {
    worksheet.getRow(286).getCell("A").value = four.two;
  } else if (four.one === "2") {
    worksheet.getRow(290).getCell("A").value = four.three;
  }

  //
  worksheet.getRow(294).getCell("B").value = five.one;
  worksheet.getRow(295).getCell("B").value = five.two;
};

// 16페이지
const inputSixteenPage = (data: SixteenPageDataTypes, worksheet: any) => {
  const { one, two, three, four, five, six } = data;

  const cellIndex = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

  //
  worksheet.getRow(299).getCell("A").value = one;

  //
  worksheet.getRow(303).getCell("A").value = two.number;
  if (two.number === "5") {
    worksheet.getRow(303).getCell("B").value = two.text ? two.text : "";
  }

  //
  worksheet.getRow(307).getCell("A").value = three.number;
  if (three.number === "5") {
    worksheet.getRow(307).getCell("B").value = three.text ? three.text : "";
  }

  //
  let index = 0;
  for (let i = 0; i < four.length; i++) {
    if (four[i].number) {
      worksheet.getRow(311).getCell(cellIndex[index]).value = i + 1;
      index += 1;
      if (i === 8) {
        worksheet.getRow(311).getCell(cellIndex[index]).value = four[i].text;
      }
    }
  }

  //
  worksheet.getRow(314).getCell("A").value = five;

  //
  worksheet.getRow(318).getCell("A").value = six;
};

// 17페이지
const inputSeventeenPage = (data: SeventeenPageDataTypes, worksheet: any) => {
  const { sixOne, seven, nine, ten, eight } = data;

  const cellIndex = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

  //
  if (sixOne) {
    worksheet.getRow(322).getCell("A").value = sixOne;
  }

  //
  let index = 0;
  for (let i = 0; i < seven.length; i++) {
    if (seven[i].number) {
      worksheet.getRow(325).getCell(cellIndex[index]).value = i + 1;
      index += 1;
      if (i === 4) {
        worksheet.getRow(325).getCell(cellIndex[index]).value = seven[i].text;
      }
    }
  }

  //
  worksheet.getRow(329).getCell("A").value = eight;

  //
  worksheet.getRow(333).getCell("A").value = nine.one.number;
  if (nine.one.number === "6") {
    worksheet.getRow(333).getCell("C").value = nine.one.text;
  }

  worksheet.getRow(333).getCell("B").value = nine.two.number;
  if (nine.two.number === "6") {
    worksheet.getRow(333).getCell("C").value = nine.two.text;
  }

  //
  worksheet.getRow(337).getCell("A").value = ten.one.number;
  if (ten.one.number === "8") {
    worksheet.getRow(337).getCell("D").value = ten.one.text;
  }

  worksheet.getRow(337).getCell("B").value = ten.two.number;
  if (ten.two.number === "8") {
    worksheet.getRow(337).getCell("D").value = ten.two.text;
  }

  worksheet.getRow(337).getCell("C").value = ten.three.number;
  if (ten.three.number === "8") {
    worksheet.getRow(337).getCell("D").value = ten.three.text;
  }
};

// 18페이지
const inputEighteenPage = (data: EighteenPageDataTypes, worksheet: any) => {
  const { eleven, twelve } = data;

  //
  worksheet.getRow(341).getCell("B").value = eleven.elevenOne;
  worksheet.getRow(342).getCell("B").value = eleven.elevenTwo;
  worksheet.getRow(343).getCell("B").value = eleven.elevenThree;
  worksheet.getRow(344).getCell("B").value = eleven.elevenFour;
  worksheet.getRow(345).getCell("B").value = eleven.elevenFive;
  worksheet.getRow(346).getCell("B").value = eleven.elevenSix;
  worksheet.getRow(347).getCell("B").value = eleven.elevenSeven;
  worksheet.getRow(348).getCell("B").value = eleven.elevenEight;
  worksheet.getRow(349).getCell("B").value = eleven.elevenNine.text;
  worksheet.getRow(349).getCell("C").value = eleven.elevenNine.number;

  //
  worksheet.getRow(353).getCell("A").value = twelve ? twelve : "";
};

const handleClientDownload = async (vote: VoteTypes, companyName: any) => {
  const res = await axios({
    url: "/vote_form.xlsx",
    responseType: "arraybuffer",
  });

  let workbook = new ExcelJS.Workbook();
  workbook = await workbook.xlsx.load(res.data);

  let worksheet = workbook.getWorksheet("Sheet1");

  const targetYear = Number(
    Number(vote.twoPage.info.date1) >= Number(vote.twoPage.info.date2)
      ? vote.twoPage.info.date1
      : vote.twoPage.info.date2
  );

  inputTwoPage(vote.twoPage, worksheet);
  inputThreePage(vote.threePage, worksheet);
  inputFourPage(vote.fourPage, worksheet);
  inputFivePage(vote.fivePage, worksheet);
  inputSixPage(vote.sixPage, worksheet);
  inputSevenPage(vote.sevenPage, worksheet);
  inputEightPage(vote.eightPage, worksheet);
  if (vote.eightPage.two === "1") {
    inputNinePage(vote.ninePage, worksheet, targetYear);
    inputTeenPage(vote.teenPage, worksheet, targetYear);
  } else if (vote.eightPage.two === "2") {
    inputElevenPage(vote.elevenPage, worksheet, targetYear);
    inputTwelvePage(vote.twelvePage, worksheet, targetYear);
  }

  inputThirteenPage(vote.thirteenPage, worksheet);
  inputFourteenPage(vote.fourteenPage, worksheet);
  inputFifteenPage(vote.fifteenPage, worksheet);
  inputSixteenPage(vote.sixteenPage, worksheet);
  inputSeventeenPage(vote.seventeenPage, worksheet);
  inputEighteenPage(vote.eighteenPage, worksheet);

  const uint8Array = await workbook.xlsx.writeBuffer();
  const blob = new Blob([uint8Array], { type: "application/octet-binary" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${companyName}.xlsx`;
  a.click();
  a.remove();
};

const voteListDownload = async (vote: VoteTypes) => {
  const res = await axios({
    url: "/vote_form.xlsx",
    responseType: "arraybuffer",
  });

  let workbook = new ExcelJS.Workbook();
  workbook = await workbook.xlsx.load(res.data);

  let worksheet = workbook.getWorksheet("Sheet1");

  const targetYear = Number(
    Number(vote.twoPage.info.date1) >= Number(vote.twoPage.info.date2)
      ? vote.twoPage.info.date1
      : vote.twoPage.info.date2
  );

  inputTwoPage(vote.twoPage, worksheet);
  inputThreePage(vote.threePage, worksheet);
  inputFourPage(vote.fourPage, worksheet);
  inputFivePage(vote.fivePage, worksheet);
  inputSixPage(vote.sixPage, worksheet);
  inputSevenPage(vote.sevenPage, worksheet);
  inputEightPage(vote.eightPage, worksheet);
  if (vote.eightPage.two === "1") {
    inputNinePage(vote.ninePage, worksheet, targetYear);
    inputTeenPage(vote.teenPage, worksheet, targetYear);
  } else if (vote.eightPage.two === "2") {
    inputElevenPage(vote.elevenPage, worksheet, targetYear);
    inputTwelvePage(vote.twelvePage, worksheet, targetYear);
  }

  inputThirteenPage(vote.thirteenPage, worksheet);
  inputFourteenPage(vote.fourteenPage, worksheet);
  inputFifteenPage(vote.fifteenPage, worksheet);
  inputSixteenPage(vote.sixteenPage, worksheet);
  inputSeventeenPage(vote.seventeenPage, worksheet);
  inputEighteenPage(vote.eighteenPage, worksheet);

  const uint8Array = await workbook.xlsx.writeBuffer();
  const blob = new Blob([uint8Array], { type: "application/octet-binary" });
  return blob;
};

const zipDesignFile = async (voteList: any) => {
  const zip = new JSZip();

  const dataList = await Promise.all(
    voteList.map((item: any) => {
      return {
        file: voteListDownload(item),
        companyName: item.companyName,
      };
    })
  );

  await Promise.all(
    dataList.map(async (item: any) => {
      zip.file(`${item.companyName}.xlsx`, item.file, {
        binary: true,
      });
    })
  );

  zip.generateAsync({ type: "blob" }).then((blob) => {
    saveAs(blob, `Vote.zip`);
  });
};
export { handleClientDownload, zipDesignFile };
