import React from "react";
import Input from "antd/es/input/Input";

import { TwoPageTypes } from "gita";
import { changeNumber, autoHypenPhone } from "src/ts/operator";

interface TwoPageComponentProps {
  twoPageData: TwoPageTypes;
  setTwoPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const TwoPageComponent = ({
  twoPageData,
  setTwoPageData,
  setisUpdate,
}: TwoPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "347mm" }}>
        <div
          className='hpN'
          style={{
            left: "99.27mm",
            top: "332mm",
            width: "11.46mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 2 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps47'
              style={{
                lineHeight: "4.44mm",
                whiteSpace: "nowrap",
                left: "2.64mm",
                top: "-0.26mm",
                height: "5.29mm",
                width: "143.83mm",
              }}
            >
              <span className='hrt cs142'>&lt;과제정보&gt;&nbsp;</span>
            </div>
            <div
              className='hls ps106'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "9.33mm",
                height: "3.88mm",
                width: "147mm",
              }}
            >
              <span className='hrt cs137'>
                ※ 귀사가 수행한 융자사업 과제에 대하여 기입하여 주십시오.
              </span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "47.68mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "15.35mm",
                height: "47.68mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.49mm",
                  width: "154.27mm",
                  top: "0.49mm",
                  height: "47.68mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 159.27 52.68'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "159.27mm",
                    height: "52.68mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_10'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_10)'
                    d='M0,0L23.68,0L23.68,8.84L0,8.84L0,0Z '
                  />
                  <defs>
                    <pattern
                      id='w_11'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_11)'
                    d='M0,8.84L23.68,8.84L23.68,17.68L0,17.68L0,8.84Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M0,17.68L23.68,17.68L23.68,27.64L0,27.64L0,17.68Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M0,27.64L23.68,27.64L23.68,37.61L0,37.61L0,27.64Z '
                  />
                  <path
                    d='M0.20,27.46 L23.68,27.46'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M0.20,27.83 L23.68,27.83'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.68,27.46 L78.74,27.46'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M23.68,27.83 L78.74,27.83'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    fill='url(#w_11)'
                    d='M78.74,27.64L97.20,27.64L97.20,37.61L78.74,37.61L78.74,27.64Z '
                  />
                  <path
                    d='M78.74,27.46 L97.20,27.46'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M78.74,27.83 L97.20,27.83'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M97.20,27.46 L153.09,27.46'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M97.20,27.83 L153.09,27.83'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    fill='url(#w_11)'
                    d='M0,37.61L23.68,37.61L23.68,46.70L0,46.70L0,37.61Z '
                  />
                  <path
                    fill='url(#w_10)'
                    d='M78.74,37.61L97.20,37.61L97.20,46.70L78.74,46.70L78.74,37.61Z '
                  />
                  <path
                    d='M0,0 L0,46.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M23.68,0 L23.68,46.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M78.74,8.84 L78.74,46.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M97.20,8.84 L97.20,46.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M153.29,0 L153.29,46.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L153.49,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,8.84 L153.49,8.84'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,17.68 L153.49,17.68'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,27.64 L153.49,27.64'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,37.61 L153.49,37.61'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,46.70 L153.49,46.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M153.29,0 L153.29,46.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,46.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,46.70 L153.49,46.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L153.49,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "23.68mm",
                    height: "8.84mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.16mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.69mm",
                        }}
                      >
                        <span className='hrt cs6'>▪기업명</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.68mm",
                    top: "0mm",
                    width: "129.6mm",
                    height: "8.84mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.81mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "3.43mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.32mm",
                          height: "4.23mm",
                          width: "128.61mm",
                        }}
                      >
                        <span className='hrt cs6'>
                          &nbsp;
                          <Input
                            style={{
                              width: "90%",
                            }}
                            type='text'
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                info: {
                                  ...twoPageData.info,
                                  company: e.target.value,
                                },
                              });
                            }}
                            value={twoPageData.info.company}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "8.84mm",
                    width: "23.68mm",
                    height: "8.84mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.16mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.69mm",
                        }}
                      >
                        <span className='hrt cs6'>▪과제명(1)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.68mm",
                    top: "8.84mm",
                    width: "55.06mm",
                    height: "8.84mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.49mm", top: "0.3mm" }}>
                    <div className='hcI' style={{ top: "1.99mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.5mm",
                          height: "3.88mm",
                          width: "54.06mm",
                        }}
                      >
                        <span className='hrt cs6'>
                          &nbsp;
                          <Input
                            style={{
                              width: "95%",
                            }}
                            type='text'
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                info: {
                                  ...twoPageData.info,
                                  projectName1: e.target.value,
                                },
                              });
                            }}
                            value={twoPageData.info.projectName1}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "78.74mm",
                    top: "8.84mm",
                    width: "18.46mm",
                    height: "8.84mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.16mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs157'>▪선정년도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "97.2mm",
                    top: "8.84mm",
                    width: "56.08mm",
                    height: "8.84mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.16mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "55.09mm",
                        }}
                      >
                        <span className='hrt cs157'>
                          &nbsp;
                          <select
                            onChange={(e) => {
                              setisUpdate(true);

                              setTwoPageData({
                                ...twoPageData,
                                info: {
                                  ...twoPageData.info,
                                  date1: e.target.value,
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.info.date1 === "boolean"
                                ? ""
                                : twoPageData.info.date1
                            }
                          >
                            <option value={""}>선택</option>
                            {new Array(6).fill(undefined).map((item, index) => {
                              const year = index + 2017;
                              return (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "17.68mm",
                    width: "23.68mm",
                    height: "9.96mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.72mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.69mm",
                        }}
                      >
                        <span className='hrt cs6'>▪과제명(2)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.68mm",
                    top: "17.68mm",
                    width: "55.06mm",
                    height: "9.96mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.49mm", top: "0mm" }}>
                    <div className='hcI' style={{ top: "2.55mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "3.1mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.19mm",
                          height: "3.88mm",
                          width: "54.06mm",
                        }}
                      >
                        <span className='hrt cs6'>
                          &nbsp;
                          <Input
                            style={{
                              width: "95%",
                            }}
                            type='text'
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                info: {
                                  ...twoPageData.info,
                                  projectName2: e.target.value,
                                },
                              });
                            }}
                            value={twoPageData.info.projectName2}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "78.74mm",
                    top: "17.68mm",
                    width: "18.46mm",
                    height: "9.96mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.72mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs157'>▪선정년도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "97.2mm",
                    top: "17.68mm",
                    width: "56.08mm",
                    height: "9.96mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.72mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "55.09mm",
                        }}
                      >
                        <span className='hrt cs157'>
                          &nbsp;
                          <select
                            name=''
                            id=''
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                info: {
                                  ...twoPageData.info,
                                  date2: e.target.value,
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.info.date2 === "boolean"
                                ? ""
                                : twoPageData.info.date2
                            }
                          >
                            <option value={""}>선택</option>
                            {new Array(6).fill(undefined).map((item, index) => {
                              const year = index + 2017;
                              return (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "27.64mm",
                    width: "23.68mm",
                    height: "9.96mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.72mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.69mm",
                        }}
                      >
                        <span className='hrt cs6'>▪응답자 성함</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.68mm",
                    top: "27.64mm",
                    width: "55.06mm",
                    height: "9.96mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.08mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "54.06mm",
                        }}
                      >
                        <span className='hrt cs6'>
                          &nbsp;
                          <Input
                            style={{
                              width: "95%",
                            }}
                            type='text'
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                info: {
                                  ...twoPageData.info,
                                  responseName: e.target.value,
                                },
                              });
                            }}
                            value={twoPageData.info.responseName}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "78.74mm",
                    top: "27.64mm",
                    width: "18.46mm",
                    height: "9.96mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.72mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs6'>▪부서</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "97.2mm",
                    top: "27.64mm",
                    width: "56.08mm",
                    height: "9.96mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.08mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "55.09mm",
                        }}
                      >
                        <span className='hrt cs6'>
                          &nbsp;
                          <Input
                            style={{
                              width: "95%",
                            }}
                            type='text'
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                info: {
                                  ...twoPageData.info,
                                  department: e.target.value,
                                },
                              });
                            }}
                            value={twoPageData.info.department}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "37.61mm",
                    width: "23.68mm",
                    height: "9.09mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "22.69mm",
                        }}
                      >
                        <span className='hrt cs6'>▪연락처</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "23.68mm",
                    top: "36.61mm",
                    width: "55.06mm",
                    height: "9.09mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.64mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "54.06mm",
                        }}
                      >
                        <span className='hrt cs6'>
                          &nbsp;
                          <Input
                            style={{
                              width: "95%",
                            }}
                            type='text'
                            placeholder='010-xxxx-xxxx'
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                info: {
                                  ...twoPageData.info,
                                  phone: autoHypenPhone(e.target.value),
                                },
                              });
                            }}
                            value={twoPageData.info.phone}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "78.74mm",
                    top: "37.61mm",
                    width: "18.46mm",
                    height: "9.09mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "2.29mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.47mm",
                        }}
                      >
                        <span className='hrt cs6'>▪E-mail</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "97.2mm",
                    top: "37.61mm",
                    width: "56.08mm",
                    height: "9.09mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.64mm" }}>
                      <div
                        className='hls ps81'
                        style={{
                          lineHeight: "2.17mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.14mm",
                          height: "2.82mm",
                          width: "55.09mm",
                        }}
                      >
                        <span className='hrt cs6'>
                          &nbsp;
                          <Input
                            style={{
                              width: "95%",
                            }}
                            type='email'
                            placeholder='example@example.com'
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                info: {
                                  ...twoPageData.info,
                                  email: e.target.value,
                                },
                              });
                            }}
                            value={twoPageData.info.email}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps65'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "2.91mm",
                top: "65.61mm",
                height: "3mm",
                width: "144.09mm",
              }}
            >
              <span className='hrt cs117'>※ 본 사업에서 지원받은</span>
              <span className='hrt cs118'>
                &nbsp;모든 과제의 과제명, 선정년도를 기입해 주십시오
              </span>
            </div>
            <div
              className='hls ps87'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "69.43mm",
                height: "4.59mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps87'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "79.31mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>Ⅰ. 기업 일반 현황</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "89.22mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>1. 귀사의&nbsp;</span>
              <span className='hrt cs9'>기업현황</span>
              <span className='hrt cs1'>을 기재하여 주십시오.</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "46.54mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "96.02mm",
                height: "46.54mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "159.7mm",
                  top: "0.5mm",
                  height: "46.54mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 164.70 51.54'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "164.7mm",
                    height: "51.54mm",
                  }}
                >
                  <path
                    fill='url(#w_11)'
                    d='M0,0L21.34,0L21.34,12.07L0,12.07L0,0Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M21.34,0L49.32,0L49.32,12.07L21.34,12.07L21.34,0Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M49.32,0L87.93,0L87.93,12.07L49.32,12.07L49.32,0Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M87.93,0L124.30,0L124.30,12.07L87.93,12.07L87.93,0Z '
                  />
                  <path
                    fill='url(#w_10)'
                    d='M124.30,0L158.71,0L158.71,12.07L124.30,12.07L124.30,0Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M0,34.82L21.34,34.82L21.34,45.54L0,45.54L0,34.82Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M21.34,34.82L31,34.82L31,45.54L21.34,45.54L21.34,34.82Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M49.32,34.82L63.26,34.82L63.26,45.54L49.32,45.54L49.32,34.82Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M87.93,34.82L98.65,34.82L98.65,45.54L87.93,45.54L87.93,34.82Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M124.30,34.82L136.18,34.82L136.18,45.54L124.30,45.54L124.30,34.82Z '
                  />
                  <path
                    d='M0,0 L0,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M21.34,0 L21.34,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M31,34.82 L31,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M49.32,0 L49.32,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M63.26,34.82 L63.26,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M87.93,0 L87.93,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M98.65,34.82 L98.65,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M124.30,0 L124.30,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M136.18,34.82 L136.18,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M158.71,0 L158.71,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L158.91,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,12.07 L158.91,12.07'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,34.82 L158.91,34.82'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,45.54 L158.91,45.54'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M158.71,0 L158.71,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,45.55'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,45.54 L158.91,45.54'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L158.91,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "21.34mm",
                    height: "12.07mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.48mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.35mm",
                        }}
                      >
                        <span className='hrt cs151'>기업설립</span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "20.35mm",
                        }}
                      >
                        <span className='hrt cs151'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "21.34mm",
                    top: "0mm",
                    width: "27.98mm",
                    height: "12.07mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "3.77mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.98mm",
                        }}
                      >
                        <span className='hrt cs159'>벤처기업</span>
                        <span className='hrt cs129'>&nbsp;등록여부</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "49.32mm",
                    top: "0mm",
                    width: "38.6mm",
                    height: "12.07mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "3.77mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "37.61mm",
                        }}
                      >
                        <span className='hrt cs159'>&nbsp;Innobiz기업</span>
                        <span className='hrt cs129'>&nbsp;등록여부</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "87.93mm",
                    top: "0mm",
                    width: "36.37mm",
                    height: "12.07mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "3.77mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.38mm",
                        }}
                      >
                        <span className='hrt cs129'>상장기업 여부</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "124.3mm",
                    top: "0mm",
                    width: "34.41mm",
                    height: "12.07mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "3.77mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "33.4mm",
                        }}
                      >
                        <span className='hrt cs129'>
                          공인 부설연구소 유무&nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "12.07mm",
                    width: "21.34mm",
                    height: "22.74mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "9.11mm" }}>
                      <div
                        className='hls ps99'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.35mm",
                        }}
                      >
                        <span className='hrt cs144 '>
                          <select
                            name=''
                            id=''
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                one: {
                                  ...twoPageData.one,
                                  one: e.target.value,
                                },
                              });
                            }}
                            value={twoPageData.one.one}
                          >
                            <option value={""}>선택</option>

                            {new Array(124)
                              .fill(undefined)
                              .map((item, index) => {
                                const year = index + 1900;
                                return (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                );
                              })}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "21.34mm",
                    top: "12.07mm",
                    width: "27.98mm",
                    height: "22.74mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "6.64mm" }}>
                      <div
                        className='hls ps99'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.98mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          <label>
                            <input
                              type='radio'
                              onChange={(e) => {
                                setisUpdate(true);
                                setTwoPageData({
                                  ...twoPageData,
                                  one: {
                                    ...twoPageData.one,
                                    two: {
                                      ...twoPageData.one.two,
                                      number: "1",
                                    },
                                  },
                                });
                              }}
                              checked={twoPageData.one.two.number === "1"}
                              name='register_venture'
                            />{" "}
                            등 &nbsp;록 &nbsp;
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps99'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.76mm",
                          height: "3.53mm",
                          width: "26.98mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          <label>
                            <input
                              type='radio'
                              onChange={(e) => {
                                setisUpdate(true);
                                setTwoPageData({
                                  ...twoPageData,
                                  one: {
                                    ...twoPageData.one,
                                    two: {
                                      ...twoPageData.one.two,
                                      number: "2",
                                    },
                                  },
                                });
                              }}
                              checked={twoPageData.one.two.number === "2"}
                              name='register_venture'
                            />
                            &nbsp;미등록
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "49.32mm",
                    top: "12.07mm",
                    width: "38.6mm",
                    height: "22.74mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "6.64mm" }}>
                      <div
                        className='hls ps99'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "37.61mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          <label>
                            <input
                              type='radio'
                              onChange={(e) => {
                                setisUpdate(true);
                                setTwoPageData({
                                  ...twoPageData,
                                  one: {
                                    ...twoPageData.one,
                                    three: {
                                      ...twoPageData.one.three,
                                      number: "1",
                                    },
                                  },
                                });
                              }}
                              checked={twoPageData.one.three.number === "1"}
                              name='register_innobiz'
                            />{" "}
                            등 &nbsp;록 &nbsp;
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps99'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.76mm",
                          height: "3.53mm",
                          width: "37.61mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          <label>
                            <input
                              type='radio'
                              onChange={(e) => {
                                setisUpdate(true);
                                setTwoPageData({
                                  ...twoPageData,
                                  one: {
                                    ...twoPageData.one,
                                    three: {
                                      ...twoPageData.one.three,
                                      number: "2",
                                    },
                                  },
                                });
                              }}
                              checked={twoPageData.one.three.number === "2"}
                              name='register_innobiz'
                            />
                            &nbsp; 미등록
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "87.93mm",
                    top: "12.07mm",
                    width: "36.37mm",
                    height: "22.74mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.7mm" }}>
                      <div
                        className='hls ps100'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "35.38mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <label>
                            <input
                              type='radio'
                              onChange={(e) => {
                                setisUpdate(true);
                                setTwoPageData({
                                  ...twoPageData,
                                  one: {
                                    ...twoPageData.one,
                                    four: {
                                      ...twoPageData.one.four,
                                      number: "1",
                                    },
                                  },
                                });
                              }}
                              checked={twoPageData.one.four.number === "1"}
                              name='market'
                            />
                            &nbsp;&nbsp;거래소 &nbsp;&nbsp;
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps100'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.76mm",
                          height: "3.53mm",
                          width: "35.38mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <label>
                            <input
                              type='radio'
                              onChange={(e) => {
                                setisUpdate(true);
                                setTwoPageData({
                                  ...twoPageData,
                                  one: {
                                    ...twoPageData.one,
                                    four: {
                                      ...twoPageData.one.four,
                                      number: "2",
                                    },
                                  },
                                });
                              }}
                              checked={twoPageData.one.four.number === "2"}
                              name='market'
                            />
                            &nbsp;&nbsp;코스닥&nbsp;
                          </label>
                        </span>
                        <span
                          className='htC'
                          style={{
                            left: "0.88mm",
                            width: "4.67mm",
                            height: "100%",
                          }}
                        />
                      </div>
                      <div
                        className='hls ps100'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "9.7mm",
                          height: "3.53mm",
                          width: "35.38mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <label>
                            <input
                              type='radio'
                              onChange={(e) => {
                                setisUpdate(true);
                                setTwoPageData({
                                  ...twoPageData,
                                  one: {
                                    ...twoPageData.one,
                                    four: {
                                      ...twoPageData.one.four,
                                      number: "3",
                                    },
                                  },
                                });
                              }}
                              checked={twoPageData.one.four.number === "3"}
                              name='market'
                            />
                            &nbsp;&nbsp;제3시장 &nbsp;
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps100'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "14.64mm",
                          height: "3.53mm",
                          width: "35.38mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <label>
                            <input
                              type='radio'
                              onChange={(e) => {
                                setisUpdate(true);
                                setTwoPageData({
                                  ...twoPageData,
                                  one: {
                                    ...twoPageData.one,
                                    four: {
                                      ...twoPageData.one.four,
                                      number: "4",
                                    },
                                  },
                                });
                              }}
                              checked={twoPageData.one.four.number === "4"}
                              name='market'
                            />
                            &nbsp;&nbsp;해당없음&nbsp;
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "124.3mm",
                    top: "12.07mm",
                    width: "34.41mm",
                    height: "22.74mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "6.64mm" }}>
                      <div
                        className='hls ps99'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "33.4mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          <label>
                            <input
                              type='radio'
                              onChange={(e) => {
                                setisUpdate(true);
                                setTwoPageData({
                                  ...twoPageData,
                                  one: {
                                    ...twoPageData.one,
                                    five: {
                                      ...twoPageData.one.five,
                                      number: "1",
                                    },
                                  },
                                });
                              }}
                              checked={twoPageData.one.five.number === "1"}
                              name='yesorno_lab'
                            />{" "}
                            &nbsp;&nbsp;유
                          </label>
                        </span>
                      </div>
                      <div
                        className='hls ps99'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.76mm",
                          height: "3.53mm",
                          width: "33.4mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          <label>
                            <input
                              type='radio'
                              onChange={(e) => {
                                setisUpdate(true);
                                setTwoPageData({
                                  ...twoPageData,
                                  one: {
                                    ...twoPageData.one,
                                    five: {
                                      ...twoPageData.one.five,
                                      number: "2",
                                    },
                                  },
                                });
                              }}
                              checked={twoPageData.one.five.number === "2"}
                              name='yesorno_lab'
                            />{" "}
                            &nbsp;&nbsp;무
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "34.82mm",
                    width: "21.34mm",
                    height: "10.73mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "3.1mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "20.35mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "21.34mm",
                    top: "34.82mm",
                    width: "9.66mm",
                    height: "10.73mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.81mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "8.65mm",
                        }}
                      >
                        <span className='hrt cs162'>등록</span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "8.65mm",
                        }}
                      >
                        <span className='hrt cs162'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "31mm",
                    top: "34.82mm",
                    width: "18.32mm",
                    height: "10.73mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "3.1mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "17.33mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          <select
                            name=''
                            id=''
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                one: {
                                  ...twoPageData.one,
                                  two: {
                                    ...twoPageData.one.two,
                                    year: e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.one.two.year === "string"
                                ? twoPageData.one.two.year
                                : ""
                            }
                            disabled={twoPageData.one.two.number !== "1"}
                          >
                            <option value={""}>선택</option>

                            {new Array(124)
                              .fill(undefined)
                              .map((item, index) => {
                                const year = index + 1900;
                                return (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                );
                              })}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "49.32mm",
                    top: "34.82mm",
                    width: "13.94mm",
                    height: "10.73mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.81mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "12.94mm",
                        }}
                      >
                        <span className='hrt cs163'>등록</span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "12.94mm",
                        }}
                      >
                        <span className='hrt cs163'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "63.26mm",
                    top: "34.82mm",
                    width: "24.67mm",
                    height: "10.73mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "3.1mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.66mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          <select
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                one: {
                                  ...twoPageData.one,
                                  three: {
                                    ...twoPageData.one.three,
                                    year: e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.one.three.year === "string"
                                ? twoPageData.one.three.year
                                : ""
                            }
                            disabled={twoPageData.one.three.number !== "1"}
                            name=''
                            id=''
                          >
                            <option value={""}>선택</option>

                            {new Array(124)
                              .fill(undefined)
                              .map((item, index) => {
                                const year = index + 1900;
                                return (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                );
                              })}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "87.93mm",
                    top: "34.82mm",
                    width: "10.72mm",
                    height: "10.73mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.81mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "9.72mm",
                        }}
                      >
                        <span className='hrt cs162'>상장</span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "9.72mm",
                        }}
                      >
                        <span className='hrt cs162'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "98.65mm",
                    top: "34.82mm",
                    width: "25.65mm",
                    height: "10.73mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "3.1mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.65mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          <select
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                one: {
                                  ...twoPageData.one,
                                  four: {
                                    ...twoPageData.one.four,
                                    year: e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.one.four.year === "string"
                                ? twoPageData.one.four.year
                                : ""
                            }
                            disabled={
                              !["1", "2", "3"].includes(
                                twoPageData.one.four.number.toString()
                              )
                            }
                          >
                            <option value={""}>선택</option>

                            {new Array(124)
                              .fill(undefined)
                              .map((item, index) => {
                                const year = index + 1900;
                                return (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                );
                              })}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "124.3mm",
                    top: "34.82mm",
                    width: "11.88mm",
                    height: "10.73mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.81mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "10.88mm",
                        }}
                      >
                        <span className='hrt cs162'>설립</span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "4.41mm",
                          height: "3.53mm",
                          width: "10.88mm",
                        }}
                      >
                        <span className='hrt cs162'>연도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "136.18mm",
                    top: "34.82mm",
                    width: "22.53mm",
                    height: "10.73mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "3.1mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "21.53mm",
                        }}
                      >
                        <span className='hrt cs131'>
                          <select
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                one: {
                                  ...twoPageData.one,
                                  five: {
                                    ...twoPageData.one.five,
                                    year: e.target.value,
                                  },
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.one.five.year === "string"
                                ? twoPageData.one.five.year
                                : ""
                            }
                            disabled={twoPageData.one.five.number !== "1"}
                          >
                            <option value={""}>선택</option>

                            {new Array(124)
                              .fill(undefined)
                              .map((item, index) => {
                                const year = index + 1900;
                                return (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                );
                              })}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "1.59mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "145.18mm",
                height: "2.12mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps20'
              style={{
                lineHeight: "1.59mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "148.78mm",
                height: "2.12mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "148.59mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>
                2. 귀사의 2022년 12월 말 기준 전체&nbsp;
              </span>
              <span className='hrt cs9'>종업원 수</span>
              <span className='hrt cs1'>를 기재해 주시기 바랍니다.&nbsp;</span>
              <div
                style={{
                  marginTop: "5px",
                }}
              >
                <span
                  className='hrt cs1'
                  style={{
                    fontSize: "0.9rem",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(해당 종업원 수가 없으면 0을
                  기입)
                </span>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "30.25mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "162.08mm",
                height: "30.25mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "155.74mm",
                  top: "0.5mm",
                  height: "30.25mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 160.74 35.25'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "160.74mm",
                    height: "35.25mm",
                  }}
                >
                  <path
                    fill='url(#w_11)'
                    d='M0,0L24.19,0L24.19,19.58L0,19.58L0,0Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M24.19,0L51.22,0L51.22,19.58L24.19,19.58L24.19,0Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M51.22,0L154.75,0L154.75,5.28L51.22,5.28L51.22,0Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M51.22,5.28L102.98,5.28L102.98,14.17L51.22,14.17L51.22,5.28Z '
                  />
                  <path
                    fill='url(#w_10)'
                    d='M102.98,5.28L154.75,5.28L154.75,14.17L102.98,14.17L102.98,5.28Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M51.22,14.17L77.10,14.17L77.10,19.58L51.22,19.58L51.22,14.17Z '
                  />
                  <path
                    fill='url(#w_11)'
                    d='M77.10,14.17L102.98,14.17L102.98,19.58L77.10,19.58L77.10,14.17Z '
                  />
                  <path
                    fill='url(#w_10)'
                    d='M102.98,14.17L128.86,14.17L128.86,19.58L102.98,19.58L102.98,14.17Z '
                  />
                  <path
                    fill='url(#w_10)'
                    d='M128.86,14.17L154.75,14.17L154.75,19.58L128.86,19.58L128.86,14.17Z '
                  />
                  <path
                    d='M0,0 L0,29.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M24.19,0 L24.19,29.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.19,19.58 L24.19,29.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M51.22,5.28 L51.22,29.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M77.10,14.17 L77.10,19.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M77.10,19.58 L77.10,29.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M102.98,5.28 L102.98,19.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M102.98,19.58 L102.98,29.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M128.86,14.17 L128.86,29.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M154.75,0 L154.75,29.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L155,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M51.17,5.28 L155,5.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M51.15,14.17 L155,14.17'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.25,19.58 L51.29,19.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M24.12,19.58 L51.30,19.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M51.15,19.58 L128.94,19.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M102.91,19.58 L155,19.58'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,29.25 L155,29.25'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M154.75,0 L154.75,29.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M0,0 L0,29.26'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,29.25 L155,29.25'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L155,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "24.19mm",
                    height: "19.58mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "7.52mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.18mm",
                        }}
                      >
                        <span className='hrt cs129'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.19mm",
                    top: "0mm",
                    width: "27.03mm",
                    height: "19.58mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "5.76mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.03mm",
                        }}
                      >
                        <span className='hrt cs129'>전체 종업원수</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "26.03mm",
                        }}
                      >
                        <span className='hrt cs129'>(A=B+C)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.22mm",
                    top: "0mm",
                    width: "103.53mm",
                    height: "5.28mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.38mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "102.53mm",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.22mm",
                    top: "5.28mm",
                    width: "51.76mm",
                    height: "8.89mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.18mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.76mm",
                        }}
                      >
                        <span className='hrt cs129'>
                          연구개발(R&amp;D)인력(B)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "102.98mm",
                    top: "5.28mm",
                    width: "51.77mm",
                    height: "8.89mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.42mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "50.77mm",
                        }}
                      >
                        <span className='hrt cs40'>
                          연구개발 외(비R&amp;D)인력
                        </span>
                        <span className='hrt cs129'>(C)</span>
                      </div>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.35mm",
                          height: "3.53mm",
                          width: "50.77mm",
                        }}
                      >
                        <span className='hrt cs129'>
                          (생산, 영업, 마케팅, 인사 등)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.22mm",
                    top: "14.17mm",
                    width: "25.88mm",
                    height: "5.4mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.44mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.88mm",
                        }}
                      >
                        <span className='hrt cs129'>정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "77.1mm",
                    top: "14.17mm",
                    width: "25.88mm",
                    height: "5.4mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.44mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.88mm",
                        }}
                      >
                        <span className='hrt cs129'>비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "102.98mm",
                    top: "14.17mm",
                    width: "25.88mm",
                    height: "5.4mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.44mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.88mm",
                        }}
                      >
                        <span className='hrt cs40'>정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "128.86mm",
                    top: "14.17mm",
                    width: "25.89mm",
                    height: "5.4mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "0.44mm" }}>
                      <div
                        className='hls ps23'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.89mm",
                        }}
                      >
                        <span className='hrt cs40'>비정규직</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "19.88mm",
                    width: "24.19mm",
                    height: "9.68mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.58mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.18mm",
                        }}
                      >
                        <span className='hrt cs131'>2022년</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "24.19mm",
                    top: "18.88mm",
                    width: "27.03mm",
                    height: "9.68mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.58mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.03mm",
                        }}
                      >
                        <span className='hrt cs133'>
                          <Input
                            disabled={true}
                            type='text'
                            style={{
                              width: "60%",
                              padding: "0px 4px",
                              textAlign: "right",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                two: {
                                  ...twoPageData.two,
                                  one:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.two.one === "number"
                                ? twoPageData.two.one
                                : ""
                            }
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "51.22mm",
                    top: "18.88mm",
                    width: "25.88mm",
                    height: "9.68mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.58mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.88mm",
                        }}
                      >
                        <span className='hrt cs133'>
                          <Input
                            type='text'
                            style={{
                              width: "60%",
                              padding: "0px 4px",
                              textAlign: "right",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);

                              const data = changeNumber(e.target.value);
                              const { three, four, five } = twoPageData.two;

                              const newData =
                                Number(data) +
                                Number(three) +
                                Number(four) +
                                Number(five);

                              setTwoPageData({
                                ...twoPageData,
                                two: {
                                  ...twoPageData.two,
                                  two:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                  one: newData,
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.two.two === "number"
                                ? twoPageData.two.two
                                : ""
                            }
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "77.1mm",
                    top: "18.88mm",
                    width: "25.88mm",
                    height: "9.68mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.58mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.88mm",
                        }}
                      >
                        <span className='hrt cs133'>
                          <Input
                            type='text'
                            style={{
                              width: "60%",
                              padding: "0px 4px",
                              textAlign: "right",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);
                              const data = changeNumber(e.target.value);
                              const { two, four, five } = twoPageData.two;

                              const newData =
                                Number(data) +
                                Number(two) +
                                Number(four) +
                                Number(five);

                              setTwoPageData({
                                ...twoPageData,
                                two: {
                                  ...twoPageData.two,
                                  three:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                  one: newData,
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.two.three === "number"
                                ? twoPageData.two.three
                                : ""
                            }
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "102.98mm",
                    top: "18.88mm",
                    width: "25.88mm",
                    height: "9.68mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.58mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.88mm",
                        }}
                      >
                        <span className='hrt cs133'>
                          <Input
                            type='text'
                            style={{
                              width: "60%",
                              padding: "0px 4px",
                              textAlign: "right",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);

                              const data = changeNumber(e.target.value);
                              const { two, three, five } = twoPageData.two;

                              const newData =
                                Number(data) +
                                Number(two) +
                                Number(three) +
                                Number(five);

                              setTwoPageData({
                                ...twoPageData,
                                two: {
                                  ...twoPageData.two,
                                  four:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                  one: newData,
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.two.four === "number"
                                ? twoPageData.two.four
                                : ""
                            }
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "128.86mm",
                    top: "18.88mm",
                    width: "25.89mm",
                    height: "9.68mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "2.58mm" }}>
                      <div
                        className='hls ps6'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.89mm",
                        }}
                      >
                        <span className='hrt cs133'>
                          <Input
                            type='text'
                            style={{
                              width: "60%",
                              padding: "0px 4px",
                              textAlign: "right",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);

                              const data = changeNumber(e.target.value);
                              const { two, three, four } = twoPageData.two;

                              const newData =
                                Number(data) +
                                Number(two) +
                                Number(three) +
                                Number(four);

                              setTwoPageData({
                                ...twoPageData,
                                two: {
                                  ...twoPageData.two,
                                  five:
                                    changeNumber(e.target.value) === ""
                                      ? false
                                      : changeNumber(e.target.value),
                                  one: newData,
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.two.five === "number"
                                ? twoPageData.two.five
                                : ""
                            }
                          />
                          &nbsp;명
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps4'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "194.9mm",
                height: "3mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs5'>
                &nbsp;※ 2022년 12월 말 상시 근로자 수 기준
              </span>
            </div>
            <div
              className='hls ps4'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "195.81mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps4'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "199.71mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "203.57mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>3. 귀사의&nbsp;</span>
              <span className='hrt cs11'>2022년도&nbsp;</span>
              <span className='hrt cs9'>신규 자금조달 비중</span>
              <span className='hrt cs1'>을 기재하여 주십시오.</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "50.94mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "210.36mm",
                height: "50.94mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "1mm",
                  width: "149.75mm",
                  top: "1mm",
                  height: "50.94mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.75 55.94'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.75mm",
                    height: "55.94mm",
                  }}
                >
                  <path
                    fill='url(#w_10)'
                    d='M0,0L43.39,0L43.39,6.65L0,6.65L0,0Z '
                  />
                  <path
                    fill='url(#w_10)'
                    d='M43.39,0L147.74,0L147.74,6.65L43.39,6.65L43.39,0Z '
                  />
                  <path
                    d='M0,0 L0,48.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M43.39,0 L43.39,48.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M147.74,0 L147.74,48.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.95,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,6.65 L147.95,6.65'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,15.88 L147.95,15.88'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,25.11 L147.95,25.11'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,33.05 L147.95,33.05'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,40.99 L147.95,40.99'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M-0.20,48.94 L147.95,48.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M147.74,0 L147.74,48.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,48.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,48.94 L147.95,48.94'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L147.95,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "43.39mm",
                    height: "6.65mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.07mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "42.4mm",
                        }}
                      >
                        <span className='hrt cs164'>구분</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "43.39mm",
                    top: "0mm",
                    width: "104.35mm",
                    height: "6.65mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.07mm" }}>
                      <div
                        className='hls ps3'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "103.36mm",
                        }}
                      >
                        <span className='hrt cs164'>2022년도</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "6.65mm",
                    width: "43.39mm",
                    height: "9.23mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.36mm" }}>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "42.4mm",
                        }}
                      >
                        <span className='hrt cs165'>&nbsp;자기자금(a)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "43.39mm",
                    top: "6.65mm",
                    width: "104.35mm",
                    height: "9.23mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.36mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "103.36mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          <Input
                            onKeyDown={(e) =>
                              e.keyCode === 69 && e.preventDefault()
                            }
                            type='number'
                            style={{
                              width: "40%",
                              padding: "0px 4px",
                              textAlign: "right",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                three: {
                                  ...twoPageData.three,
                                  one:
                                    e.target.value === ""
                                      ? false
                                      : Number(e.target.value) > 100
                                      ? 100
                                      : e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.three.one === "string"
                                ? twoPageData.three.one
                                : ""
                            }
                          />
                          &nbsp;%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "15.88mm",
                    width: "43.39mm",
                    height: "9.23mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.36mm" }}>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "42.4mm",
                        }}
                      >
                        <span className='hrt cs165'>&nbsp;정부출연금(b)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "43.39mm",
                    top: "15.88mm",
                    width: "104.35mm",
                    height: "9.23mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.36mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "103.36mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          <Input
                            onKeyDown={(e) =>
                              e.keyCode === 69 && e.preventDefault()
                            }
                            type='number'
                            style={{
                              width: "40%",
                              padding: "0px 4px",
                              textAlign: "right",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                three: {
                                  ...twoPageData.three,
                                  two:
                                    e.target.value === ""
                                      ? false
                                      : Number(e.target.value) > 100
                                      ? 100
                                      : e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.three.two === "string"
                                ? twoPageData.three.two
                                : ""
                            }
                          />
                          &nbsp;%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "25.11mm",
                    width: "43.39mm",
                    height: "7.94mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.71mm" }}>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "42.4mm",
                        }}
                      >
                        <span className='hrt cs165'>&nbsp;융자금(c)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "43.39mm",
                    top: "25.11mm",
                    width: "104.35mm",
                    height: "7.94mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.49mm", top: "0mm" }}>
                    <div className='hcI' style={{ top: "1.71mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "103.36mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          <Input
                            onKeyDown={(e) =>
                              e.keyCode === 69 && e.preventDefault()
                            }
                            type='number'
                            style={{
                              width: "40%",
                              padding: "0px 4px",
                              textAlign: "right",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                three: {
                                  ...twoPageData.three,
                                  three:
                                    e.target.value === ""
                                      ? false
                                      : Number(e.target.value) > 100
                                      ? 100
                                      : e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.three.three === "string"
                                ? twoPageData.three.three
                                : ""
                            }
                          />
                          &nbsp;%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "33.05mm",
                    width: "43.39mm",
                    height: "7.94mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.71mm" }}>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "42.4mm",
                        }}
                      >
                        <span className='hrt cs165'>&nbsp;투자유치금(d)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "43.39mm",
                    top: "33.05mm",
                    width: "104.35mm",
                    height: "7.94mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.49mm", top: "0mm" }}>
                    <div className='hcI' style={{ top: "1.71mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "103.36mm",
                        }}
                      >
                        <span className='hrt cs165'>
                          <Input
                            onKeyDown={(e) =>
                              e.keyCode === 69 && e.preventDefault()
                            }
                            type='number'
                            style={{
                              width: "40%",
                              padding: "0px 4px",
                              textAlign: "right",
                            }}
                            onChange={(e) => {
                              setisUpdate(true);
                              setTwoPageData({
                                ...twoPageData,
                                three: {
                                  ...twoPageData.three,
                                  four:
                                    e.target.value === ""
                                      ? false
                                      : Number(e.target.value) > 100
                                      ? 100
                                      : e.target.value === ""
                                      ? false
                                      : e.target.value,
                                },
                              });
                            }}
                            value={
                              typeof twoPageData.three.four === "string"
                                ? twoPageData.three.four
                                : ""
                            }
                          />
                          &nbsp;%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "40.99mm",
                    width: "43.39mm",
                    height: "7.94mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.71mm" }}>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "42.4mm",
                        }}
                      >
                        <span className='hrt cs165'>&nbsp;계(a+b+c+d)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "43.39mm",
                    top: "40.99mm",
                    width: "104.35mm",
                    height: "7.94mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "1.71mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "103.36mm",
                        }}
                      >
                        <span className='hrt cs165'>100%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "263.84mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "263.84mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoPageComponent;
