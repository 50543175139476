import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

const initState: any = {
  isLogin: false,
  isLoading: false,
  isValidCheck: 0,
  isError: 0,
  checkResult: {},
  messageApi: false,
  targetPage: -1,
};

const user = createSlice({
  name: "userInfo",
  initialState: { ...initState },
  reducers: {
    setLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    login(state, action) {
      return { ...state, isLogin: action.payload };
    },
    logout(state, action) {
      localStorage.removeItem("mes_user");
      return {
        ...state,
        isLogin: false,
      };
    },
    // 제출하기 전에 다 작성했는지 체크
    setIsValidCheck(state, action) {
      return {
        ...state,
        isValidCheck: action.payload
          ? state.isValidCheck + action.payload
          : false,
      };
    },
    // 작성 안됐으면 에러
    setIsError(state, action) {
      const checkResult = action.payload.checkResult;
      Object.keys(checkResult).forEach((item) => {
        checkResult[item] = checkResult[item][0];
      });
      return {
        ...state,
        isError: action.payload.checked
          ? state.isError + action.payload.checked
          : false,
        checkResult: checkResult,
      };
    },
    setMessageApi(state, action: PayloadAction<any>) {
      return {
        ...state,
        messageApi: action.payload,
      };
    },
    setTargetPage(state, action: PayloadAction<any>) {
      return {
        ...state,
        targetPage: action.payload,
      };
    },
  },
});

export const action = user.actions;
export default user.reducer;
