import { Input } from "antd";
import React from "react";
import { changeNumber } from "src/ts/operator";

import { SevenPageTypes } from "gita";
import _ from "lodash";

interface SevenPageComponentProps {
  sevenPageData: SevenPageTypes;
  setSevenPageData: React.Dispatch<React.SetStateAction<any>>;
  setisUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const SevenPageComponent = ({
  sevenPageData,
  setSevenPageData,
  setisUpdate,
}: SevenPageComponentProps) => {
  return (
    <div>
      <div className='hpa' style={{ width: "210mm", height: "297mm" }}>
        <div
          className='hpN'
          style={{
            left: "99.27mm",
            top: "282mm",
            width: "11.46mm",
            height: "3.53mm",
          }}
        >
          <span className='hrt cs0'>- 7 -</span>
        </div>
        <div className='hcD' style={{ left: "30mm", top: "35mm" }}>
          <div className='hcI'>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.76mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "1.25mm",
                height: "4.59mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs15'>Ⅳ. Output (산출)</span>
              <span className='hrt cs2'>&nbsp;</span>
            </div>
            <div
              className='hls ps7'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "10.11mm",
                height: "3.88mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps0'
              style={{
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "16.32mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs1'>1. 귀사가&nbsp;</span>
              <span className='hrt cs152'>
                본 융자사업과제를 수행하여&nbsp;
              </span>
              <span className='hrt cs9'>
                2022년에 산출한 지식재산권 세부 현황을 기재
              </span>
            </div>
            <div
              className='hls ps0'
              style={{
                paddingLeft: "4.94mm",
                lineHeight: "3.1mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "22.92mm",
                height: "3.88mm",
                width: "150mm",
              }}
            >
              <span className='hrt cs119'>하여 주시기 바랍니다.</span>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "42.59mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "29.72mm",
                height: "42.59mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.5mm",
                  width: "149.05mm",
                  top: "0.5mm",
                  height: "42.59mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 154.05 47.59'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "154.05mm",
                    height: "47.59mm",
                  }}
                >
                  <path
                    d='M0,0 L0,41.60'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M148.05,0 L148.05,41.60'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L148.26,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,41.60 L148.26,41.60'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M148.05,0 L148.05,41.60'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M0,0 L0,41.60'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,41.60 L148.26,41.60'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M-0.20,0 L148.26,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "148.05mm",
                    height: "41.6mm",
                  }}
                >
                  <div className='hcD' style={{ left: "0.5mm", top: "0.5mm" }}>
                    <div className='hcI' style={{ top: "1.5mm" }}>
                      <div
                        className='hls ps66'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "2mm",
                          height: "3.53mm",
                          width: "147.05mm",
                        }}
                      >
                        <span className='hrt cs123'>
                          ※ 본 기술과제와 관련하여&nbsp;
                        </span>
                        <span className='hrt cs178'>2022년에</span>
                        <span className='hrt cs123'>
                          &nbsp;발생된 모든 지식재산권을 기입해 주십시오.
                          기입하실&nbsp;
                        </span>
                      </div>
                      <div
                        className='hls ps66'
                        style={{
                          paddingLeft: "4.93mm",
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "7.41mm",
                          height: "3.53mm",
                          width: "147.05mm",
                        }}
                      >
                        <span className='hrt cs123'>때에는&nbsp;</span>
                        <span className='hrt cs26'>
                          출원(등록)번호를 정확하게 기입
                        </span>
                        <span className='hrt cs123'>하여 주십시오.</span>
                      </div>
                      {/* <div
                        className='hls ps93'
                        style={{
                          lineHeight: "2.32mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "9.02mm",
                          height: "3mm",
                          width: "147.05mm",
                        }}
                      >
                        <span className='hrt cs87'>※&nbsp;</span>
                        <span className='hrt cs88'>지식재산권의 유형은 1.</span>
                        <span className='hrt cs89'>국제특허출원(PCT포함)</span>
                        <span className='hrt cs88'>, 2.</span>
                        <span className='hrt cs89'>국제특허등록(PCT포함)</span>
                        <span className='hrt cs88'>, 3.</span>
                        <span className='hrt cs89'>국내특허출원</span>
                        <span className='hrt cs88'>, 4.</span>
                        <span className='hrt cs89'>국내특허등</span>
                        <span className='hrt cs90'>록</span>
                        <span className='hrt cs87'>,&nbsp;</span>
                      </div>
                      <div
                        className='hls ps93'
                        style={{
                          paddingLeft: "4.38mm",
                          lineHeight: "2.32mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "14.11mm",
                          height: "3mm",
                          width: "147.05mm",
                        }}
                      >
                        <span className='hrt cs206'>5.</span>
                        <span className='hrt cs90'>
                          S/W 및 S/W관련 프로그램 등록
                        </span>
                        <span className='hrt cs87'>
                          &nbsp;중에서 하나를 선택하여&nbsp;
                        </span>
                        <span className='hrt cs207'>숫자로 입력</span>
                      </div> */}
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "2.32mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "14.19mm",
                          height: "3mm",
                          width: "147.05mm",
                        }}
                      >
                        <span className='hrt cs87'>
                          ※ 특허 국내 출원번호은 XX-XXXX-XXXXXXX 의 13자리번호를
                          “-”없이 연속하여 입력
                        </span>
                      </div>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "2.32mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "20.28mm",
                          height: "3mm",
                          width: "147.05mm",
                        }}
                      >
                        <span className='hrt cs87'>
                          ※ 특허 국내 등록번호은 XX-XXXXXXX-0000 의 13자리번호를
                          “-”없이 연속하여 입력
                        </span>
                      </div>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "2.32mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "26.87mm",
                          height: "3mm",
                          width: "147.05mm",
                        }}
                      >
                        <span className='hrt cs87'>
                          ※ 기타 해외특허의 경우는 해당국의 출원/등록번호를
                          정확히 입력
                        </span>
                      </div>
                      <div
                        className='hls ps20'
                        style={{
                          lineHeight: "2.32mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "33.46mm",
                          height: "3mm",
                          width: "147.05mm",
                        }}
                      >
                        <span className='hrt cs87'>
                          ※ S/W 국내 등록번호은 XXXX-XX-XXX-XXXXXX 의
                          15자리번호를 “-”없이 연속하여 입력
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "103.26mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "75.03mm",
                height: "103.26mm",
                width: "150mm",
              }}
            >
              <div
                className='htb'
                style={{
                  left: "0.49mm",
                  width: "148.68mm",
                  top: "0.49mm",
                  height: "103.26mm",
                  display: "inline-block",
                  position: "relative",
                  verticalAlign: "middle",
                }}
              >
                <svg
                  className='hs'
                  viewBox='-2.50 -2.50 153.68 108.26'
                  style={{
                    left: "-2.5mm",
                    top: "-2.5mm",
                    width: "153.68mm",
                    height: "108.26mm",
                  }}
                >
                  <defs>
                    <pattern
                      id='w_60'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_60)'
                    d='M0,0L7.98,0L7.98,15.28L0,15.28L0,0Z '
                  />
                  <path
                    fill='url(#w_60)'
                    d='M7.98,0L35.92,0L35.92,15.28L7.98,15.28L7.98,0Z '
                  />
                  <path
                    fill='url(#w_60)'
                    d='M35.92,0L64.86,0L64.86,15.28L35.92,15.28L35.92,0Z '
                  />
                  <defs>
                    <pattern
                      id='w_61'
                      width={10}
                      height={10}
                      patternUnits='userSpaceOnUse'
                    >
                      <rect width={10} height={10} fill='rgb(229,229,229)' />
                    </pattern>
                  </defs>
                  <path
                    fill='url(#w_61)'
                    d='M64.86,0L96.80,0L96.80,15.28L64.86,15.28L64.86,0Z '
                  />
                  <path
                    fill='url(#w_60)'
                    d='M96.80,0L121.75,0L121.75,15.28L96.80,15.28L96.80,0Z '
                  />
                  <path
                    fill='url(#w_60)'
                    d='M121.75,0L147.69,0L147.69,15.28L121.75,15.28L121.75,0Z '
                  />
                  <path
                    d='M0,0 L0,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M7.98,0 L7.98,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M35.92,0 L35.92,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M35.92,15.28 L35.92,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M64.86,0 L64.86,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.4",
                    }}
                  />
                  <path
                    d='M64.86,15.28 L64.86,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M96.80,0 L96.80,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M121.75,0 L121.75,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M121.75,15.28 L121.75,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M147.69,0 L147.69,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L147.95,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,15.28 L35.98,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M7.90,15.28 L36,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M35.86,15.28 L65.07,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M35.85,15.28 L65.12,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M64.66,15.28 L97.05,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.1",
                    }}
                  />
                  <path
                    d='M96.55,15.28 L121.81,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M96.55,15.28 L121.82,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M121.69,15.28 L147.95,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M121.67,15.28 L147.95,15.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,32.99 L8.04,32.99'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M7.90,32.99 L65.12,32.99'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M64.61,32.99 L97.05,32.99'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.1",
                    }}
                  />
                  <path
                    d='M96.55,32.99 L147.95,32.99'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,50.70 L8.04,50.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M7.90,50.70 L65.12,50.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M64.61,50.70 L97.05,50.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.1",
                    }}
                  />
                  <path
                    d='M96.55,50.70 L147.95,50.70'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,68.41 L8.04,68.41'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M7.90,68.41 L65.12,68.41'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M64.61,68.41 L97.05,68.41'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.1",
                    }}
                  />
                  <path
                    d='M96.55,68.41 L147.95,68.41'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,85.34 L8.04,85.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.12",
                    }}
                  />
                  <path
                    d='M7.90,85.34 L65.12,85.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M64.61,85.34 L97.05,85.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.1",
                    }}
                  />
                  <path
                    d='M96.55,85.34 L147.95,85.34'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.15",
                    }}
                  />
                  <path
                    d='M-0.25,102.28 L147.95,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M147.69,0 L147.69,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M0,0 L0,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,102.28 L147.95,102.28'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                  <path
                    d='M-0.25,0 L147.95,0'
                    style={{
                      stroke: "#000000",
                      strokeLinecap: "butt",
                      strokeWidth: "0.5",
                    }}
                  />
                </svg>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "0mm",
                    width: "7.98mm",
                    height: "15.28mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "5.56mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "6.98mm",
                        }}
                      >
                        <span className='hrt cs60'>순번</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "7.98mm",
                    top: "0mm",
                    width: "27.94mm",
                    height: "15.28mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "5.56mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "26.95mm",
                        }}
                      >
                        <span className='hrt cs60'>지식재산권 유형</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "35.92mm",
                    top: "0mm",
                    width: "28.94mm",
                    height: "15.28mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "5.56mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "27.95mm",
                        }}
                      >
                        <span className='hrt cs60'>지식재산권 명칭</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "64.86mm",
                    top: "0mm",
                    width: "31.94mm",
                    height: "15.28mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.49mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "30.95mm",
                        }}
                      >
                        <span className='hrt cs60'>출원(등록)</span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.98mm",
                          height: "3.17mm",
                          width: "30.95mm",
                        }}
                      >
                        <span className='hrt cs60'>번호</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "96.8mm",
                    top: "0mm",
                    width: "24.95mm",
                    height: "15.28mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "5.56mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "23.96mm",
                        }}
                      >
                        <span className='hrt cs60'>출원인</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "121.75mm",
                    top: "0mm",
                    width: "25.95mm",
                    height: "15.28mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "3.49mm" }}>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "24.95mm",
                        }}
                      >
                        <span className='hrt cs60'>출원(등록)</span>
                      </div>
                      <div
                        className='hls ps22'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "3.98mm",
                          height: "3.17mm",
                          width: "24.95mm",
                        }}
                      >
                        <span className='hrt cs60'>국가</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "15.28mm",
                    width: "7.98mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.77mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "6.98mm",
                        }}
                      >
                        <span className='hrt cs49'>1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "7.98mm",
                    top: "15.28mm",
                    width: "27.94mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <select
                          style={{
                            fontSize: "0.72rem",
                            width: "90%",
                            height: "1.3rem",
                          }}
                          value={
                            typeof sevenPageData.one[0].one === "number"
                              ? sevenPageData.one[0].one
                              : ""
                          }
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                let one =
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value);

                                if (index === 0) {
                                  return {
                                    ...item,
                                    one,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                        >
                          <option style={{ textAlign: "center" }} value={""}>
                            == 선택 ==
                          </option>
                          <option value={1}>국제특허출원(PCT포함)</option>
                          <option value={2}>국제특허등록(PCT포함)</option>
                          <option value={3}>국내특허출원</option>
                          <option value={4}>국내특허등록</option>
                          <option value={5}>상표권 출원</option>
                          <option value={6}>상표권 등록</option>
                          <option value={7}>디자인 출원</option>
                          <option value={8}>디자인 등록</option>
                          <option value={9}>
                            S/W 및 S/W관련 프로그램 등록
                          </option>
                          <option value={10}>기타</option>
                        </select>
                        {/* <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                let one =
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value);
                                
                                if (index === 0) {
                                  return {
                                    ...item,
                                    one,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[0].one === "number"
                              ? sevenPageData.one[0].one
                              : ""
                          }
                          type='text'
                          style={{ width: "92%", textAlign: "right" }}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "35.92mm",
                    top: "15.28mm",
                    width: "28.94mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...item,
                                    two:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[0].two === "string"
                              ? sevenPageData.one[0].two
                              : ""
                          }
                          type='text'
                          style={{ width: "92%", fontSize: "0.7rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "64.86mm",
                    top: "15.28mm",
                    width: "31.94mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.77mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "30.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...item,
                                    three:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[0].three === "number"
                              ? sevenPageData.one[0].three
                              : ""
                          }
                          maxLength={15}
                          style={{ width: "92%", fontSize: "0.7rem" }}
                          placeholder='- 없이 연속하여 입력'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "96.8mm",
                    top: "15.28mm",
                    width: "24.95mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.96mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...item,
                                    four:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[0].four === "string"
                              ? sevenPageData.one[0].four
                              : ""
                          }
                          type='text'
                          style={{ width: "91%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "121.75mm",
                    top: "15.28mm",
                    width: "25.95mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 0) {
                                  return {
                                    ...item,
                                    five:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[0].five === "string"
                              ? sevenPageData.one[0].five
                              : ""
                          }
                          type='text'
                          style={{ width: "91%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "32.99mm",
                    width: "7.98mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.77mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "6.98mm",
                        }}
                      >
                        <span className='hrt cs49'>2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "7.98mm",
                    top: "32.99mm",
                    width: "27.94mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <select
                          style={{
                            fontSize: "0.72rem",
                            width: "90%",
                            height: "1.3rem",
                          }}
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                let one =
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value);

                                if (index === 1) {
                                  return {
                                    ...item,
                                    one,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[1].one === "number"
                              ? sevenPageData.one[1].one
                              : ""
                          }
                        >
                          <option style={{ textAlign: "center" }} value={""}>
                            == 선택 ==
                          </option>
                          <option value={1}>국제특허출원(PCT포함)</option>
                          <option value={2}>국제특허등록(PCT포함)</option>
                          <option value={3}>국내특허출원</option>
                          <option value={4}>국내특허등록</option>
                          <option value={5}>상표권 출원</option>
                          <option value={6}>상표권 등록</option>
                          <option value={7}>디자인 출원</option>
                          <option value={8}>디자인 등록</option>
                          <option value={9}>
                            S/W 및 S/W관련 프로그램 등록
                          </option>
                          <option value={10}>기타</option>
                        </select>
                        {/* <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                let one =
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value);
                                
                                if (index === 1) {
                                  return {
                                    ...item,
                                    one,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[1].one === "number"
                              ? sevenPageData.one[1].one
                              : ""
                          }
                          type='text'
                          style={{ width: "92%", textAlign: "right" }}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "35.92mm",
                    top: "32.99mm",
                    width: "28.94mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 1) {
                                  return {
                                    ...item,
                                    two:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[1].two === "string"
                              ? sevenPageData.one[1].two
                              : ""
                          }
                          type='text'
                          style={{ width: "92%", fontSize: "0.7rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "64.86mm",
                    top: "32.99mm",
                    width: "31.94mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.77mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "30.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 1) {
                                  return {
                                    ...item,
                                    three:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[1].three === "number"
                              ? sevenPageData.one[1].three
                              : ""
                          }
                          maxLength={15}
                          placeholder='- 없이 연속하여 입력'
                          style={{ width: "92%", fontSize: "0.7rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "96.8mm",
                    top: "32.99mm",
                    width: "24.95mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.96mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 1) {
                                  return {
                                    ...item,
                                    four:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[1].four === "string"
                              ? sevenPageData.one[1].four
                              : ""
                          }
                          type='text'
                          style={{ width: "91%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "121.75mm",
                    top: "32.99mm",
                    width: "25.95mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 1) {
                                  return {
                                    ...item,
                                    five:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[1].five === "string"
                              ? sevenPageData.one[1].five
                              : ""
                          }
                          type='text'
                          style={{ width: "91%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "50.7mm",
                    width: "7.98mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.77mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "6.98mm",
                        }}
                      >
                        <span className='hrt cs49'>3</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "7.98mm",
                    top: "50.7mm",
                    width: "27.94mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <select
                          style={{
                            fontSize: "0.72rem",
                            width: "90%",
                            height: "1.3rem",
                          }}
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                let one =
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value);

                                if (index === 2) {
                                  return {
                                    ...item,
                                    one,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[2].one === "number"
                              ? sevenPageData.one[2].one
                              : ""
                          }
                        >
                          <option style={{ textAlign: "center" }} value={""}>
                            == 선택 ==
                          </option>
                          <option value={1}>국제특허출원(PCT포함)</option>
                          <option value={2}>국제특허등록(PCT포함)</option>
                          <option value={3}>국내특허출원</option>
                          <option value={4}>국내특허등록</option>
                          <option value={5}>상표권 출원</option>
                          <option value={6}>상표권 등록</option>
                          <option value={7}>디자인 출원</option>
                          <option value={8}>디자인 등록</option>
                          <option value={9}>
                            S/W 및 S/W관련 프로그램 등록
                          </option>
                          <option value={10}>기타</option>
                        </select>
                        {/* <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                let one =
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value);
                                
                                if (index === 2) {
                                  return {
                                    ...item,
                                    one,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[2].one === "number"
                              ? sevenPageData.one[2].one
                              : ""
                          }
                          type='text'
                          style={{ width: "92%", textAlign: "right" }}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "35.92mm",
                    top: "50.7mm",
                    width: "28.94mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 2) {
                                  return {
                                    ...item,
                                    two:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[2].two === "string"
                              ? sevenPageData.one[2].two
                              : ""
                          }
                          type='text'
                          style={{ width: "92%", fontSize: "0.7rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "64.86mm",
                    top: "50.7mm",
                    width: "31.94mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.77mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "30.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 2) {
                                  return {
                                    ...item,
                                    three:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[2].three === "number"
                              ? sevenPageData.one[2].three
                              : ""
                          }
                          maxLength={15}
                          placeholder='- 없이 연속하여 입력'
                          style={{ width: "92%", fontSize: "0.7rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "96.8mm",
                    top: "50.7mm",
                    width: "24.95mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.96mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 2) {
                                  return {
                                    ...item,
                                    four:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[2].four === "string"
                              ? sevenPageData.one[2].four
                              : ""
                          }
                          type='text'
                          style={{ width: "91%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "121.75mm",
                    top: "50.7mm",
                    width: "25.95mm",
                    height: "17.71mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.6mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 2) {
                                  return {
                                    ...item,
                                    five:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[2].five === "string"
                              ? sevenPageData.one[2].five
                              : ""
                          }
                          type='text'
                          style={{ width: "91%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "68.41mm",
                    width: "7.98mm",
                    height: "16.93mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.38mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "6.98mm",
                        }}
                      >
                        <span className='hrt cs49'>4</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "7.98mm",
                    top: "68.41mm",
                    width: "27.94mm",
                    height: "16.93mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.21mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <select
                          style={{
                            fontSize: "0.72rem",
                            width: "90%",
                            height: "1.3rem",
                          }}
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                let one =
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value);

                                if (index === 3) {
                                  return {
                                    ...item,
                                    one,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[3].one === "number"
                              ? sevenPageData.one[3].one
                              : ""
                          }
                        >
                          <option style={{ textAlign: "center" }} value={""}>
                            == 선택 ==
                          </option>
                          <option value={1}>국제특허출원(PCT포함)</option>
                          <option value={2}>국제특허등록(PCT포함)</option>
                          <option value={3}>국내특허출원</option>
                          <option value={4}>국내특허등록</option>
                          <option value={5}>상표권 출원</option>
                          <option value={6}>상표권 등록</option>
                          <option value={7}>디자인 출원</option>
                          <option value={8}>디자인 등록</option>
                          <option value={9}>
                            S/W 및 S/W관련 프로그램 등록
                          </option>
                          <option value={10}>기타</option>
                        </select>
                        {/* <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                let one =
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value);
                                
                                if (index === 3) {
                                  return {
                                    ...item,
                                    one,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[3].one === "number"
                              ? sevenPageData.one[3].one
                              : ""
                          }
                          type='text'
                          style={{ width: "92%", textAlign: "right" }}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "35.92mm",
                    top: "68.41mm",
                    width: "28.94mm",
                    height: "16.93mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.21mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 3) {
                                  return {
                                    ...item,
                                    two:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[3].two === "string"
                              ? sevenPageData.one[3].two
                              : ""
                          }
                          type='text'
                          style={{ width: "92%", fontSize: "0.7rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "64.86mm",
                    top: "68.41mm",
                    width: "31.94mm",
                    height: "16.93mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.38mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "30.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 3) {
                                  return {
                                    ...item,
                                    three:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[3].three === "number"
                              ? sevenPageData.one[3].three
                              : ""
                          }
                          maxLength={15}
                          placeholder='- 없이 연속하여 입력'
                          style={{ width: "92%", fontSize: "0.7rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "96.8mm",
                    top: "68.41mm",
                    width: "24.95mm",
                    height: "16.93mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.21mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.96mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 3) {
                                  return {
                                    ...item,
                                    four:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[3].four === "string"
                              ? sevenPageData.one[3].four
                              : ""
                          }
                          type='text'
                          style={{ width: "91%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "121.75mm",
                    top: "68.41mm",
                    width: "25.95mm",
                    height: "16.93mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.21mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 3) {
                                  return {
                                    ...item,
                                    five:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[3].five === "string"
                              ? sevenPageData.one[3].five
                              : ""
                          }
                          type='text'
                          style={{ width: "91%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "0mm",
                    top: "85.34mm",
                    width: "7.98mm",
                    height: "16.94mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.39mm" }}>
                      <div
                        className='hls ps36'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "6.98mm",
                        }}
                      >
                        <span className='hrt cs49'>5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "7.98mm",
                    top: "85.34mm",
                    width: "27.94mm",
                    height: "16.94mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.21mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "26.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <select
                          style={{
                            fontSize: "0.72rem",
                            width: "90%",
                            height: "1.3rem",
                          }}
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                let one =
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value);

                                if (index === 4) {
                                  return {
                                    ...item,
                                    one,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[4].one === "number"
                              ? sevenPageData.one[4].one
                              : ""
                          }
                        >
                          <option style={{ textAlign: "center" }} value={""}>
                            == 선택 ==
                          </option>
                          <option value={1}>국제특허출원(PCT포함)</option>
                          <option value={2}>국제특허등록(PCT포함)</option>
                          <option value={3}>국내특허출원</option>
                          <option value={4}>국내특허등록</option>
                          <option value={5}>상표권 출원</option>
                          <option value={6}>상표권 등록</option>
                          <option value={7}>디자인 출원</option>
                          <option value={8}>디자인 등록</option>
                          <option value={9}>
                            S/W 및 S/W관련 프로그램 등록
                          </option>
                          <option value={10}>기타</option>
                        </select>
                        {/* <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                let one =
                                  changeNumber(e.target.value) === ""
                                    ? false
                                    : changeNumber(e.target.value);
                                
                                if (index === 4) {
                                  return {
                                    ...item,
                                    one,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[4].one === "number"
                              ? sevenPageData.one[4].one
                              : ""
                          }
                          type='text'
                          style={{ width: "92%", textAlign: "right" }}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "35.92mm",
                    top: "85.34mm",
                    width: "28.94mm",
                    height: "16.94mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.21mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "27.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 4) {
                                  return {
                                    ...item,
                                    two:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[4].two === "string"
                              ? sevenPageData.one[4].two
                              : ""
                          }
                          type='text'
                          style={{ width: "92%", fontSize: "0.7rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "64.86mm",
                    top: "85.34mm",
                    width: "31.94mm",
                    height: "16.94mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.39mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.48mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.16mm",
                          height: "3.17mm",
                          width: "30.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 4) {
                                  return {
                                    ...item,
                                    three:
                                      changeNumber(e.target.value) === ""
                                        ? false
                                        : changeNumber(e.target.value),
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[4].three === "number"
                              ? sevenPageData.one[4].three
                              : ""
                          }
                          maxLength={15}
                          placeholder='- 없이 연속하여 입력'
                          style={{ width: "92%", fontSize: "0.7rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "96.8mm",
                    top: "85.34mm",
                    width: "24.95mm",
                    height: "16.94mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.21mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "23.96mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 4) {
                                  return {
                                    ...item,
                                    four:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[4].four === "string"
                              ? sevenPageData.one[4].four
                              : ""
                          }
                          type='text'
                          style={{ width: "91%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='hce'
                  style={{
                    left: "121.75mm",
                    top: "85.34mm",
                    width: "25.95mm",
                    height: "16.94mm",
                  }}
                >
                  <div
                    className='hcD'
                    style={{ left: "0.49mm", top: "0.49mm" }}
                  >
                    <div className='hcI' style={{ top: "6.21mm" }}>
                      <div
                        className='hls ps1'
                        style={{
                          lineHeight: "2.79mm",
                          whiteSpace: "nowrap",
                          left: "0mm",
                          top: "-0.18mm",
                          height: "3.53mm",
                          width: "24.95mm",
                        }}
                      >
                        {" "}
                        &nbsp;
                        <Input
                          onChange={(e) => {
                            const newData: any = _.cloneDeep(sevenPageData.one);
                            setisUpdate(true);
                            setSevenPageData({
                              one: newData.map((item: any, index: number) => {
                                if (index === 4) {
                                  return {
                                    ...item,
                                    five:
                                      e.target.value === ""
                                        ? false
                                        : e.target.value,
                                  };
                                }
                                return item;
                              }),
                            });
                          }}
                          value={
                            typeof sevenPageData.one[4].five === "string"
                              ? sevenPageData.one[4].five
                              : ""
                          }
                          type='text'
                          style={{ width: "91%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='hls ps20'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "180.73mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps20'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "185.81mm",
                height: "3mm",
                width: "150mm",
              }}
            />
            <div
              className='hls ps20'
              style={{
                lineHeight: "2.32mm",
                whiteSpace: "nowrap",
                left: "0mm",
                top: "190.9mm",
                height: "3mm",
                width: "150mm",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SevenPageComponent;
